/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { EmailAddressSchema, PersonalNameSchema, URLSchema, VerificationCodeSchema } from '.';
import { validateUserPassword } from '../validator';
import { ResidentialAddressSchema } from './address';
import { DoctorPractitionerSchema } from '../constants';

export const LoginRequestSchema = r.Record({
    password: r.String,
    email: EmailAddressSchema,
});
export type LoginRequest = r.Static<typeof LoginRequestSchema>;

export const PatientRegistrationSchema = r.Record({
    email: EmailAddressSchema,
    last_name: PersonalNameSchema,
    first_name: PersonalNameSchema,
    password: r.String.withConstraint(validateUserPassword),
});
export type PatientRegistration = r.Static<typeof PatientRegistrationSchema>;

export const DoctorRegistrationSchema = r.Record({
    phone: r.String,
    password: r.String,
    signature: URLSchema,
    qualification: r.String,
    email: EmailAddressSchema,
    provider_number: r.String,
    prescriber_number: r.String,
    last_name: PersonalNameSchema,
    first_name: PersonalNameSchema,
    address: ResidentialAddressSchema,
    practitioner_type: DoctorPractitionerSchema,
});
export type DoctorRegistration = r.Static<typeof DoctorRegistrationSchema>;

export const RecoverAccountRequestSchema = r.Record({
    email: EmailAddressSchema,
});
export type RecoverAccountRequest = r.Static<typeof RecoverAccountRequestSchema>;

export const VerifyAccountRequestSchema = r.Record({
    code: VerificationCodeSchema,
});
export type VerifyAccountRequest = r.Static<typeof VerifyAccountRequestSchema>;

export const VerifyRecoveryTokenResponseSchema = r.Record({
    email: EmailAddressSchema,
});
export type VerifyRecoveryTokenResponse = r.Static<typeof VerifyRecoveryTokenResponseSchema>;

export const ResetPasswordSchema = r.Record({
    password: r.String.withConstraint(validateUserPassword),
});
export type ResetPassword = r.Static<typeof ResetPasswordSchema>;
