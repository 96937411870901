/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { runtypeFromEnum } from '../../../utils/runtypes';
import { HydrationTherapyServicesSchema, SkincareHydrationCategoriesSchema } from './hydration';
import { AestheticsAndCosmeticsServicesSchema, SkincareAestheticsCategoriesSchema } from './ aesthetics';

export const SKINCARE_DISCOUNT_FLOAT = 100;

export enum SkincareAppointmentStatus {
    Scheduled = 'Scheduled',
    Cancelled = 'Cancelled',
    Completed = 'Completed',
}
export const SkincareAppointmentStatusSchema = runtypeFromEnum(SkincareAppointmentStatus);

export enum SkincareService {
    SkincareClinic = 'Skincare Clinic',
    SkincareAppointment = 'Skincare Appointment',
}
export const SkincareServiceTitleSchema = runtypeFromEnum(SkincareService);

// AussieScripts skincare services.
export enum SkincareCategoriesService {
    HydrationTherapies = 'Hydration Therapies',
    AestheticsAndCosmetics = 'Aesthetics and Cosmetics',
}
export const SkincareCategoriesServiceSchema = runtypeFromEnum(SkincareCategoriesService);

// Skincare subcategories.
export const SkincareSubCategoriesSchema = SkincareHydrationCategoriesSchema.Or(SkincareAestheticsCategoriesSchema);
export type SkincareSubCategories = r.Static<typeof SkincareSubCategoriesSchema>;

// Skincare service titles.
export const AussieSkincareServiceTitlesSchema = HydrationTherapyServicesSchema.Or(
    AestheticsAndCosmeticsServicesSchema,
);
export type AussieSkincareServiceTitles = r.Static<typeof AussieSkincareServiceTitlesSchema>;
