/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { MedicalRequestNoteResourceSchema, MedicalRequestNoteSchema } from '../types/medical/request';
import {
    RescheduleSkincareAppointmentSchema,
    SkincareAppointmentPaymentRequestSchema,
    SkincareAppointmentSchema,
    SkincareAppointmentsSchema,
} from '../types/skincare';
import { EndpointSync, HttpMethod } from '../utils/endpointSync';

export const GetSkincareAppointmentsEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/appointments',
    responseValidator: SkincareAppointmentsSchema,
});

export const CreateSkincareAppointmentNoteEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/appointment/:appointmentId/note',
    requestValidator: MedicalRequestNoteSchema,
    responseValidator: MedicalRequestNoteResourceSchema,
});

export const FinalizeSkincareAppointmentEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/appointment/:appointmentId/finalize',
});

export const RescheduledSkincareAppointmentEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/appointment/reschedule',
    requestValidator: RescheduleSkincareAppointmentSchema,
    responseValidator: SkincareAppointmentSchema,
});

export const CancelSkincareAppointmentEndpoint = new EndpointSync({
    method: HttpMethod.DELETE,
    path: '/appointment/:appointmentId/cancel',
});

export const SendSkincarePaymentRequestEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/appointment/:appointmentId/request/payment',
    responseValidator: SkincareAppointmentPaymentRequestSchema,
});
