/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import {
    AussieSkincareServiceTitlesSchema,
    SkincareAppointmentStatusSchema,
    SkincareCategoriesServiceSchema,
    SkincareService,
    SkincareServiceTitleSchema,
    SkincareSubCategoriesSchema,
} from '../../constants/services/skincare';
import {
    CreatedAtTimestampSchema,
    DateSchema,
    DigitalConsultationSchema,
    EmailAddressSchema,
    IdEntitySchema,
    UnavailableDateSchema,
    URLSchema,
} from '..';
import { ServiceRequestType } from '../../constants/checkout';
import { maybe } from '../../utils/runtypes';
import { MedicalProfileResourceSchema } from '../medical/profile';

export const SkincareRequestNotesSchema = r.Record({
    message: r.String,
    doctor_name: r.String,
    created_at: DateSchema,
});
export type SkincareRequestNotes = r.Static<typeof SkincareRequestNotesSchema>;

export const SkincareServiceRequestSchema = r.Record({
    price: r.Number,
    duration: r.Number,
    description: r.Optional(r.String),
    category: SkincareSubCategoriesSchema,
    title: AussieSkincareServiceTitlesSchema,
    main_category: SkincareCategoriesServiceSchema,
});
export type SkincareServiceRequest = r.Static<typeof SkincareServiceRequestSchema>;

const SkincareServiceManagerSchema = r.Record({
    category: SkincareSubCategoriesSchema,
    services: r.Array(SkincareServiceRequestSchema),
});
export type SkincareServiceManager = r.Static<typeof SkincareServiceManagerSchema>;

export const SkincareClinicRequestSchema = r.Record({
    title: SkincareServiceTitleSchema,
    consultations: r.Array(DigitalConsultationSchema),
    category: r.Literal(ServiceRequestType.SkincareClinic),
});
export type SkincareClinicRequest = r.Static<typeof SkincareClinicRequestSchema>;

export const SkincareScheduleSchema = r.Record({
    date: DateSchema,
    bookedDates: r.Array(DateSchema),
    unavailableDates: r.Array(UnavailableDateSchema),
});
export type SkincareSchedule = r.Static<typeof SkincareScheduleSchema>;

export const AussieSkincareRequestSchema = r.Record({
    discount: maybe(r.Number),
    category: r.Literal(ServiceRequestType.Skincare),
    consultations: r.Array(DigitalConsultationSchema),
    title: r.Literal(SkincareService.SkincareAppointment),
    selected_services: r.Array(SkincareServiceRequestSchema),
});
export type AussieSkincareRequest = r.Static<typeof AussieSkincareRequestSchema>;

export const SkincareAppointmentSchema = r
    .Record({
        appointment_at: DateSchema,
        medical_request_id: r.String,
        service: AussieSkincareRequestSchema,
        patient: MedicalProfileResourceSchema,
        status: SkincareAppointmentStatusSchema,
        notes: r.Array(SkincareRequestNotesSchema),
    })
    .extend(CreatedAtTimestampSchema.fields)
    .extend(IdEntitySchema.fields);
export type SkincareAppointment = r.Static<typeof SkincareAppointmentSchema>;

export const SkincareAppointmentsSchema = r.Array(SkincareAppointmentSchema);

export const RescheduleSkincareAppointmentSchema = r.Record({
    date: DateSchema,
    appointment_id: r.String,
});
export type RescheduleSkincareAppointment = r.Static<typeof RescheduleSkincareAppointmentSchema>;

export const SkincareAppointmentPaymentRequestSchema = r.Record({
    id: r.String,
    payment_link: URLSchema,
    transaction_id: r.String,
    discount: maybe(r.Number),
    selected_services: r.Array(SkincareServiceRequestSchema),
});
export type SkincareAppointmentPaymentRequest = r.Static<typeof SkincareAppointmentPaymentRequestSchema>;

export const SkincarePendingPaymentSchema = r.Record({
    appointment_id: r.String,
    appointment_at: DateSchema,
    patient_last_name: r.String,
    patient_first_name: r.String,
    status: r.Literal('Pending'),
    patient_email_address: EmailAddressSchema,
    selected_services: r.Array(SkincareServiceRequestSchema),
});
export type SkincarePendingPayment = r.Static<typeof SkincarePendingPaymentSchema>;

export const SkincareSettledPaymentSchema = r
    .Record({
        appointment_id: r.String,
        appointment_at: DateSchema,
        status: r.Literal('Settled'),
        selected_services: r.Array(SkincareServiceRequestSchema),
    })
    .extend(CreatedAtTimestampSchema.fields);
export type SkincareSettledPayment = r.Static<typeof SkincareSettledPaymentSchema>;

export const SkincarePaymentRequestSchema = SkincarePendingPaymentSchema.Or(SkincareSettledPaymentSchema);
export type SkincarePaymentRequest = r.Static<typeof SkincarePaymentRequestSchema>;
