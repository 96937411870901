/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    CreatePrescriptionFamily,
    CreatePrescriptionFamilyClass,
    FamilyConsultationQuestionsState,
    PrescriptionFamilyClass,
    PrescriptionRecord,
} from '../../../../api/types/prescription';
import {
    createPrescriptionConsultation,
    createPrescriptionDrug,
    createPrescriptionFamily,
    createPrescriptionFamilyClass,
    deletePrescriptionConsultation,
    deletePrescriptionDrug,
    deletePrescriptionFamily,
    deletePrescriptionFamilyClass,
    getPrescriptionRecords,
    updateFamilyConsultationQuestionsState,
    updatePrescriptionConsultation,
    updatePrescriptionConsultationOrder,
    updatePrescriptionDrug,
    updatePrescriptionFamily,
    updatePrescriptionFamilyClass,
} from '../../fetch/prescription';
import { FetchError, handleError } from '../../tools/fetch';
import { SnackbarTypes } from '../../../../common/types';
import { setSnackbarMessage } from '../snackbar';
import { DigitalConsultationRecord, DigitalConsultationResource } from '../../../../api/types/consultation';
import { PrescriptionDrug, PrescriptionDrugResource } from '../../../../api/types/prescription/escript';
import { RootState } from '../../store';

export const getPrescriptionRecordsThunk = createAsyncThunk<PrescriptionRecord[], void>(
    'prescription/get/records',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            return await getPrescriptionRecords();
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message:
                        response?.reason ??
                        'Something went wrong. We could not get available prescription records. Please try again later',
                    type: SnackbarTypes.ERROR,
                }),
            );
            return rejectWithValue(response);
        }
    },
);

export const createPrescriptionFamilyThunk = createAsyncThunk<
    PrescriptionRecord,
    { eventId: string; body: CreatePrescriptionFamily }
>('prescription/create/family', async ({ body }, { rejectWithValue, dispatch }) => {
    try {
        return await createPrescriptionFamily(body);
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message:
                    response?.reason ??
                    'Something went wrong. We could not complete your request to create prescription family. Please try again later',
                type: SnackbarTypes.ERROR,
            }),
        );
        return rejectWithValue(response?.reason);
    }
});

export const updatePrescriptionFamilyThunk = createAsyncThunk<
    void,
    { referenceId: string; body: CreatePrescriptionFamily }
>('prescription/update/family', async ({ body, referenceId }, { rejectWithValue, dispatch }) => {
    try {
        await updatePrescriptionFamily(referenceId, body);
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message:
                    response?.reason ??
                    'Something went wrong. We could not complete your request to create prescription family. Please try again later',
                type: SnackbarTypes.ERROR,
            }),
        );
        return rejectWithValue(response?.reason);
    }
});

export const deletePrescriptionFamilyThunk = createAsyncThunk<void, string>(
    'prescription/delete/family',
    async (referenceId, { rejectWithValue, dispatch }) => {
        try {
            await deletePrescriptionFamily(referenceId);
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message:
                        response?.reason ??
                        'Something went wrong. We could not complete your request to delete prescription family. Please try again later',
                    type: SnackbarTypes.ERROR,
                }),
            );
            return rejectWithValue(response?.reason);
        }
    },
);

export const createPrescriptionFamilyClassThunk = createAsyncThunk<
    PrescriptionFamilyClass,
    { eventId: string; familyId: string; body: CreatePrescriptionFamilyClass },
    { state: RootState }
>('prescription/create/family/class', async ({ body, familyId }, { rejectWithValue, dispatch, getState }) => {
    try {
        const result = await createPrescriptionFamilyClass(body, familyId);

        const { prescription } = getState();
        const family = prescription.prescriptions.find(({ id }) => id === familyId);
        dispatch(
            setSnackbarMessage({
                message: `You have successfully created another class for the ${family?.name} prescription family.`,
                type: SnackbarTypes.SUCCESS,
            }),
        );

        return result;
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message:
                    response?.reason ??
                    'Something went wrong. We could not complete your request to create prescription family class. Please try again later',
                type: SnackbarTypes.ERROR,
            }),
        );
        return rejectWithValue(response?.reason);
    }
});

export const updatePrescriptionFamilyClassThunk = createAsyncThunk<
    void,
    { referenceId: string; familyId: string; body: CreatePrescriptionFamilyClass }
>('prescription/update/family/class', async ({ body, referenceId }, { rejectWithValue, dispatch }) => {
    try {
        await updatePrescriptionFamilyClass(referenceId, body);
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message:
                    response?.reason ??
                    'Something went wrong. We could not complete your request to create prescription family class. Please try again later',
                type: SnackbarTypes.ERROR,
            }),
        );
        return rejectWithValue(response?.reason);
    }
});

export const deletePrescriptionFamilyClassThunk = createAsyncThunk<void, { referenceId: string; familyId: string }>(
    'prescription/delete/family/class',
    async ({ referenceId }, { rejectWithValue, dispatch }) => {
        try {
            await deletePrescriptionFamilyClass(referenceId);
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message:
                        response?.reason ??
                        'Something went wrong. We could not complete your request to delete prescription family. Please try again later',
                    type: SnackbarTypes.ERROR,
                }),
            );
            return rejectWithValue(response?.reason);
        }
    },
);

export const createPrescriptionDigitalConsultationQuestionThunk = createAsyncThunk<
    DigitalConsultationResource,
    { eventId: string; familyId: string; familyClassId: string; body: DigitalConsultationRecord },
    { state: RootState }
>(
    'prescription/create/consultation/question',
    async ({ body, familyId, familyClassId }, { rejectWithValue, dispatch, getState }) => {
        try {
            const result = await createPrescriptionConsultation(familyClassId, body);

            const { prescription } = getState();

            const family = prescription.prescriptions.find(({ id }) => id === familyId);
            const prescriptionClass = family?.classes?.find(({ id }) => id === familyClassId);

            if (family && prescriptionClass) {
                dispatch(
                    setSnackbarMessage({
                        message: `You have successfully created prescription digital consultation for the Family: "${family.name}" prescription family, Class: "${prescriptionClass.name}" class.`,
                        type: SnackbarTypes.SUCCESS,
                    }),
                );
            }

            return result;
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message:
                        response?.reason ??
                        'Something went wrong. We could not complete your request to create prescription consultation. Please try again later',
                    type: SnackbarTypes.ERROR,
                }),
            );
            return rejectWithValue(response?.reason);
        }
    },
);

export const updatePrescriptionDigitalConsultationQuestionThunk = createAsyncThunk<
    DigitalConsultationResource,
    { referenceId: string; body: DigitalConsultationRecord; familyId: string; familyClassId: string }
>('prescription/update/consultation/question', async ({ body, referenceId }, { rejectWithValue, dispatch }) => {
    try {
        return await updatePrescriptionConsultation(referenceId, body);
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message:
                    response?.reason ??
                    'Something went wrong. We could not complete your request to update prescription consultation. Please try again later',
                type: SnackbarTypes.ERROR,
            }),
        );
        return rejectWithValue(response?.reason);
    }
});

export const deletePrescriptionDigitalConsultationQuestionThunk = createAsyncThunk<
    void,
    { familyId: string; familyClassId: string; digitalConsultationId: string }
>('prescription/delete/consultation/question', async ({ digitalConsultationId }, { rejectWithValue, dispatch }) => {
    try {
        await deletePrescriptionConsultation(digitalConsultationId);
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message:
                    response?.reason ??
                    'Something went wrong. We could not complete your request to delete prescription digital consultation question. Please try again later',
                type: SnackbarTypes.ERROR,
            }),
        );
        return rejectWithValue(response?.reason);
    }
});

export const createPrescriptionDrugThunk = createAsyncThunk<
    PrescriptionDrugResource,
    { eventId: string; familyId: string; familyClassId: string; body: PrescriptionDrug },
    { state: RootState }
>('prescription/create/drug', async ({ body, familyClassId, familyId }, { rejectWithValue, dispatch, getState }) => {
    try {
        const result = await createPrescriptionDrug(familyClassId, body);

        const { prescription } = getState();

        const family = prescription.prescriptions.find(({ id }) => id === familyId);
        const prescriptionClass = family?.classes?.find(({ id }) => id === familyClassId);

        if (family && prescriptionClass) {
            dispatch(
                setSnackbarMessage({
                    message: `You have successfully created the prescription drug for the Family: "${family.name}" prescription family, Class: "${prescriptionClass.name}" class.`,
                    type: SnackbarTypes.SUCCESS,
                }),
            );
        }

        return result;
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message:
                    response?.reason ??
                    'Something went wrong. We could not complete your request to create prescription drug. Please try again later',
                type: SnackbarTypes.ERROR,
            }),
        );
        return rejectWithValue(response?.reason);
    }
});

export const updatePrescriptionDrugThunk = createAsyncThunk<
    PrescriptionDrugResource,
    { referenceId: string; familyId: string; familyClassId: string; body: PrescriptionDrug }
>('prescription/update/drug', async ({ body, referenceId }, { rejectWithValue, dispatch }) => {
    try {
        return await updatePrescriptionDrug(referenceId, body);
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message:
                    response?.reason ??
                    'Something went wrong. We could not complete your request to update prescription drug. Please try again later',
                type: SnackbarTypes.ERROR,
            }),
        );
        return rejectWithValue(response?.reason);
    }
});

export const deletePrescriptionDrugThunk = createAsyncThunk<
    void,
    { referenceId: string; familyId: string; familyClassId: string }
>('prescription/delete/drug', async ({ referenceId }, { rejectWithValue, dispatch }) => {
    try {
        await deletePrescriptionDrug(referenceId);
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message:
                    response?.reason ??
                    'Something went wrong. We could not complete your request to delete prescription drug. Please try again later',
                type: SnackbarTypes.ERROR,
            }),
        );
        return rejectWithValue(response?.reason);
    }
});

export const updatePrescriptionConsultationsOrderThunk = createAsyncThunk<
    void,
    { familyId: string; familyClassId: string; body: string[] },
    { state: RootState }
>(
    'prescription/class/consultations/order',
    async ({ body, familyId, familyClassId }, { rejectWithValue, dispatch, getState }) => {
        try {
            await updatePrescriptionConsultationOrder(familyClassId, body);
            const { prescription } = getState();

            const family = prescription.prescriptions.find(({ id }) => id === familyId);
            const prescriptionClass = family?.classes?.find(({ id }) => id === familyClassId);

            if (family && prescriptionClass) {
                dispatch(
                    setSnackbarMessage({
                        message: `You have successfully update digital consultations order for the Family: "${family.name}" prescription family, Class: "${prescriptionClass.name}" class.`,
                        type: SnackbarTypes.SUCCESS,
                    }),
                );
            }
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message:
                        response?.reason ??
                        'Something went wrong. We could not complete your request to update consultations order. Please try again later',
                    type: SnackbarTypes.ERROR,
                }),
            );
            return rejectWithValue(response?.reason);
        }
    },
);

export const updateFamilyConsultationQuestionsStateThunk = createAsyncThunk<FamilyConsultationQuestionsState, string>(
    'prescription/family/consultation/questions/state',
    async (familyReferenceId, { rejectWithValue, dispatch }) => {
        try {
            return await updateFamilyConsultationQuestionsState(familyReferenceId);
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message:
                        response?.reason ??
                        'Something went wrong. We could not complete your request to update consultations order. Please try again later',
                    type: SnackbarTypes.ERROR,
                }),
            );
            return rejectWithValue(response?.reason);
        }
    },
);
