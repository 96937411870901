/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

export enum SnackbarTypes {
    INFO = 'INFO',
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
}

export enum ResourceSessionStatus {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN PROGRESS',
}

export const CONSULTATION_NOTE_TEMPLATE = `History of presenting complaint:

Relevant PMHx:

Impression:

Plan:
`;

export const CANNABIS_CONSULTATION_NOTE_TEMPLATE = `Initial Appointment Consult Notes Template

Telehealth consult
ID Confirmed

- Attending/referred for consultation and consideration of treatment with medicinal
cannabis.

*** You may copy the triage note for PMH/DH etc and then add an any missing details***

Primary presenting complaint:
Chronic pain - which has not been relieved by conventional medicines or therapies.

Other medical conditions include:
Chronic pain due to osteoarthritis
Anxiety
Depression
Chronic Insomnia
Diabetes
- No history of unstable CVS, Renal or Liver disease

Current medications (including those not relieving symptoms):
Panadol Osteo
Ibuprofen

Treatments that have been tried in the past and have been ineffective or had intolerable
side effects include:
Endone (Opiates)
Sertraline
SSRIs
Diazepam (Benzodiazepines)
Mindfulness app
Psychologist
Psychiatry
Yoga
Chiro
Exercise

Drug Allergies: Nil Known

Psychological/Psychiatric History:
Nil hx of psychosis/schizophrenia

Cannabis Status:

Has never used cannabis in the past.
or
Has used cannabis (either prescribed or non-prescribed) - This was to treat symptoms, and
the patient found it effective for this.

Previous drug dependence:
Nil stated

SHx-
Occupation – Machine Operator
Not Pregnant or Breastfeeding or planning (discussed will need to cease if
planning/becomes pregnant)

Explanation of Medicinal Cannabis with patient:
o THC/CBD what each element does
o Usually oral oils, also flower, vaporised medicines etc
o Personalized medicine. TGA process, begin with 4-12 week trial, approx. pricing.
o Psychoactive/high, THC element. To monitor and be aware.
o Limited current high-quality evidence (short and long term) regarding efficacy/safety of
MC at present, positives vs negatives, patient aware of this
o Common and rare side effects fully discussed (including dizziness, sedation, lethargy, dry
mouth, cognitive impairment, hallucinations, dec BP and inc HR), often dose dependant
o Driving/operating machinery and THC, legal status at present, can be detected and risk of
loss of licence and legal consequences for patient. Not able to drive if on THC containing
medication – acknowledged and understood by patient

What are the side effects of medicinal cannabis?
The known side effects from medicinal cannabis treatment include, but are not limited to;
fatigue and sedation, dizziness, confusion, nausea and vomiting, fever, decreased or increased
appetite, dry mouth, and diarrhoea.

Products high in tetrahydrocannabinol (THC) has been associated with feeling ‘high’ or feeling
dissatisfied, depression, confusion, hallucinations, paranoid delusions, psychosis, and cognitive
distortion (having thoughts that are not true).

Medicinal cannabis side effects are commonly dose-dependent, so it’s important to follow
the dosing recommendations.

Discussed ECS and importance of diet and exercise on general health and support of ECS.
Consider Supplements (to reduce inflammation and support ECS):
- Fish oil
- Monday/Sunday Terpenes

All patient questions answered. Nil concerns raised. Would like to proceed to medicinal
cannabis application, for a trial and possible treatment with the medication. Discussed
options of medications, split vs single protocol, possible costs etc and plan made in
conjunction with pt.

Plan:
1. Info on MC given and run through with pt
2. Single / Split Protocol – treatment regime: oils +/- flower

Application submission to (TGA if applicable)
3. To continue on current medication regime at present, not to adjust medications etc
without medical input/GP
4. Continue with regular GP reviews of medical conditions , pt to also
update GP R/V in 4-8 weeks for

When should medicinal cannabis not be used?
Medicinal cannabis is not appropriate for:
• people with an active or previous psychotic disorder, or active mood or anxiety disorder
• women who are pregnant, planning to become pregnant or breastfeeding
• people with unstable heart disease.
There is no information available on the most effective or safe dose (amount) of medicinal
cannabis for various conditions. For this reason, starting doses should be low and increased slowly
over time.

Driving and Working?
• You should not drive or operate machinery while taking medicinal cannabis. Measurable
concentrations of THC (tetrahydrocannabinol – the main psychoactive substance in cannabis) can
be detected in urine, blood and saliva many days after the last dose. Drug-driving is dangerous. It
is also a criminal offence. You should discuss the implications for safe and legal driving with your
doctor.
• Depending on the laws in your state or territory, driving while using medicinal cannabis could be
illegal. Check with your state/territory health department.
• Some workplaces may have a ‘drug-free policy’ and enforce regular or random drug screening. If
you have been prescribed medicinal cannabis, it is important to investigate your workplace policy
before commencing treatment. Speak to your prescribing doctor who may wish to refer the
matter to an occupational health specialist.

INTENDED MONITORING (for TGA application):
• Follow up consults at 1 and 3 months. Ongoing consults 3 monthly. Phone and
email support during medication initiation period as required. Patient to keep
“medication diary” (noting dose, time, effect, side effects/adverse effects, pain
score, mobility etc).
• Patient informed to cease medication immediately if they experience any adverse
effects, and to contact doctors rooms immediately.
• Advised to also continue with regular GP/specialist reviews.

FOLLOW-UP: Appointment Consult Notes Template

Consultation for repeat script and review
Via Telehealth
ID confirmed
State Prescription checking service -- checked

Currently doing well, nil concerns from patient.
Has found overall improvement and good effect of MC, keen to continue on it for symptom
relief.
GP is up to date from pt and is aware of MC treatment.
Reiterated driving rules, current general data on MC etc

Sleep – Stable and improved
Pain – Improved with MC
Mental health – Ok and Stable
Side Effects – Nil side effects from MC

Dose -
To continue with no change
Rotate chemovar for best therapeutic effect

Plan-
1. Script written --- eScript sent to CuratD team (scripts@curatdhealth.com.au) to
send to pharmacy
2. Pt to attend for rpt script appt when 2-3 weeks medication remaining (around 4-12
weeks time)
3. Pt to continue to keep GP/specialist’s up to date with
treatment
4. Pt happy with plan,
5. nil concerns
`;

export interface FrequentlyAskedQuestion {
    question: string;
    answer: string;
}

export enum InputFieldError {
    Email = 'Email',
    Phone = 'Phone',
    Required = 'Required',
    MinLength = 'Min Length',
    MaxLength = 'Max Length',
    WhitespaceOnly = 'Whitespace Only',
    NoWhitespaceAllowed = 'No Whitespace Allowed',
}
