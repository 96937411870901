/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InfirmaryPendingRequest, InfirmaryRequest, InfirmaryStatistics } from '../../../../api/types/infirmary';
import {
    createInfirmaryRequestFlagThunk,
    getPendingConsultationRequestThunk,
    getInfirmaryRequestsThunk,
    getInfirmaryHistoryThunk,
    exportPatientERxPadProfilesThunk,
} from './thunk';
import { MedicalRequestStatus } from '../../../../api/constants';

export interface IInfirmaryState {
    pageTitle: string;
    pageSubTitle: string;
    historyRecords: InfirmaryRequest[];
    statistics?: InfirmaryStatistics;
    isFetchingMedicalRequests: boolean;
    isFetchingHistoryRecords: boolean;
    isExportingERxPadProfile: boolean;
    waitingRoomRecords: InfirmaryRequest[];
    pendingRequest?: InfirmaryPendingRequest;
}

const initialState: IInfirmaryState = {
    pageTitle: '',
    pageSubTitle: '',
    historyRecords: [],
    waitingRoomRecords: [],
    isExportingERxPadProfile: false,
    isFetchingHistoryRecords: false,
    isFetchingMedicalRequests: false,
};

const infirmarySlice = createSlice({
    name: 'infirmary',
    initialState,
    reducers: {
        setActivePageTitle(draft, { payload }: PayloadAction<string>) {
            draft.pageTitle = payload;
        },
        setActivePageSubTitle(draft, { payload }: PayloadAction<string>) {
            draft.pageSubTitle = payload;
        },
    },
    extraReducers(reducers) {
        reducers
            .addCase(getInfirmaryRequestsThunk.pending, (draft) => {
                draft.isFetchingMedicalRequests = true;
            })
            .addCase(getInfirmaryRequestsThunk.fulfilled, (draft, { payload }) => {
                if (payload != null) {
                    draft.waitingRoomRecords = payload.requests;
                    draft.statistics = payload.statistics;
                }
                draft.isFetchingMedicalRequests = false;
            })
            .addCase(getInfirmaryRequestsThunk.rejected, (draft) => {
                draft.isFetchingMedicalRequests = false;
            })
            .addCase(getInfirmaryHistoryThunk.pending, (draft) => {
                draft.isFetchingHistoryRecords = true;
            })
            .addCase(getInfirmaryHistoryThunk.fulfilled, (draft, { payload }) => {
                draft.historyRecords = payload;
                draft.isFetchingHistoryRecords = false;
            })
            .addCase(getInfirmaryHistoryThunk.rejected, (draft) => {
                draft.isFetchingHistoryRecords = false;
            })
            .addCase(createInfirmaryRequestFlagThunk.fulfilled, (draft, { payload }) => {
                // If the request is cancelled or completed, we remove it from the waiting list records.
                if (
                    payload.status === MedicalRequestStatus.Cancelled ||
                    payload.status === MedicalRequestStatus.Completed
                ) {
                    draft.waitingRoomRecords = draft.waitingRoomRecords.filter(({ id }) => id !== payload.id);
                    return;
                }

                const recordIndex = draft.waitingRoomRecords.findIndex(({ id }) => id === payload.id);
                if (recordIndex == -1) {
                    return;
                }

                draft.waitingRoomRecords[recordIndex] = payload;
            })
            .addCase(getPendingConsultationRequestThunk.pending, (draft) => {
                delete draft.pendingRequest;
            })
            .addCase(getPendingConsultationRequestThunk.fulfilled, (draft, { payload }) => {
                if (payload.request == null) {
                    delete draft.pendingRequest;
                    return;
                }

                draft.pendingRequest = payload.request;
            })
            // eRxPad medical profile export state handlers.
            .addCase(exportPatientERxPadProfilesThunk.pending, (draft) => {
                draft.isExportingERxPadProfile = true;
            })
            .addCase(exportPatientERxPadProfilesThunk.fulfilled, (draft) => {
                draft.isExportingERxPadProfile = false;
            })
            .addCase(exportPatientERxPadProfilesThunk.rejected, (draft) => {
                draft.isExportingERxPadProfile = false;
            });
    },
});

export const infirmaryReducer = infirmarySlice.reducer;

export const { setActivePageSubTitle, setActivePageTitle } = infirmarySlice.actions;
