/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../utils/runtypes';

export const ADDRESS_CITY_LIMIT = 120;

export const ADDRESS_STREET_LIMIT = 200;

export const ADDRESS_POST_CODE_LIMIT = 10;

export enum State {
    VIC = 'Victoria (VIC)',
    NSW = 'New South Wales (NSW)',
    ACT = 'Australian Capital Territory (ACT)',
    QLD = 'Queensland (QLD)',
    NT = 'Northern Territory (NT)',
    WA = 'Western Australia (WA)',
    SA = 'South Australia (SA)',
    TAS = 'Tasmania (TAS)',
}
export const StateSchema = runtypeFromEnum(State);
