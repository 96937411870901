/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createSlice } from '@reduxjs/toolkit';
import {
    cancelSkincareAppointmentThunk,
    createSkincareAppointmentNoteThunk,
    finalizeSkincareAppointmentThunk,
    getSkincareAppointmentsThunk,
    rescheduleSkincareAppointmentThunk,
} from './thunk';
import { SkincareAppointment } from '../../../../api/types/skincare';

interface ISkincareState {
    isFetchingAppointments: boolean;
    hasFetchedAppointments: boolean;
    appointments: SkincareAppointment[];
    requestEvents: Record<string, boolean>;
}

const initialState: ISkincareState = {
    appointments: [],
    requestEvents: {},
    isFetchingAppointments: false,
    hasFetchedAppointments: false,
};

const skincareSlice = createSlice({
    name: 'skincare',
    initialState,
    reducers: {},
    extraReducers: (reducers) => {
        reducers
            // Get skincare appointments thunk handler.
            .addCase(getSkincareAppointmentsThunk.pending, (draft) => {
                draft.isFetchingAppointments = true;
            })
            .addCase(getSkincareAppointmentsThunk.fulfilled, (draft, { payload }) => {
                draft.appointments = payload;
                draft.hasFetchedAppointments = true;
                draft.isFetchingAppointments = false;
            })
            .addCase(getSkincareAppointmentsThunk.rejected, (draft) => {
                draft.isFetchingAppointments = false;
            })
            // Reschedule skincare appointment thunk handler.
            .addCase(rescheduleSkincareAppointmentThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.appointment_id] = true;
            })
            .addCase(rescheduleSkincareAppointmentThunk.fulfilled, (draft, { payload, meta }) => {
                const appointmentIndex = draft.appointments.findIndex(({ id }) => id === payload.id);

                draft.appointments[appointmentIndex] = payload;
                delete draft.requestEvents[meta.arg.appointment_id];
            })
            .addCase(rescheduleSkincareAppointmentThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg.appointment_id];
            })
            // Finalize skincare appointment thunk handler.
            .addCase(finalizeSkincareAppointmentThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg] = true;
            })
            .addCase(finalizeSkincareAppointmentThunk.fulfilled, (draft, { meta }) => {
                const appointmentIndex = draft.appointments.findIndex(({ id }) => id === meta.arg);

                draft.appointments.splice(appointmentIndex, 1);
                delete draft.requestEvents[meta.arg];
            })
            .addCase(finalizeSkincareAppointmentThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg];
            })
            // Cancel skincare appointment thunk handler.
            .addCase(cancelSkincareAppointmentThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg] = true;
            })
            .addCase(cancelSkincareAppointmentThunk.fulfilled, (draft, { meta }) => {
                draft.appointments = draft.appointments.filter(({ id }) => id !== meta.arg);
                delete draft.requestEvents[meta.arg];
            })
            .addCase(cancelSkincareAppointmentThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg];
            })
            // Create prescription family class handlers
            .addCase(createSkincareAppointmentNoteThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.appointmentId] = true;
            })
            .addCase(createSkincareAppointmentNoteThunk.fulfilled, (draft, { payload, meta }) => {
                delete draft.requestEvents[meta.arg.appointmentId];
            })
            .addCase(createSkincareAppointmentNoteThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg.appointmentId];
            });
    },
});

export const skincareReducer = skincareSlice.reducer;
