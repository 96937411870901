/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { DateSchema, EmailAddressSchema, IdEntitySchema, PersonalNameSchema, TimestampSchema, URLSchema } from '..';
import { ResidentialAddressSchema } from '../address';
import { maybe } from '../../utils/runtypes';
import { DoctorPractitionerSchema, UserAccountAccessLevelTypeSchema, UserAccountTypeSchema } from '../../constants';

const DoctorSchema = r.Record({
    avatar: maybe(URLSchema),
    email: EmailAddressSchema,
    last_name: PersonalNameSchema,
    first_name: PersonalNameSchema,
    is_administrator: r.Boolean,
});
export type Patient = r.Static<typeof DoctorSchema>;

export const DoctorResourceSchema = DoctorSchema.extend(IdEntitySchema.fields);
export type DoctorResource = r.Static<typeof DoctorResourceSchema>;

export const DoctorDatabaseRecordSchema = r
    .Record({
        id: r.Number,
        active: r.Boolean,
        password: r.String,
        verified: r.Boolean,
        signature: r.String,
        reference_id: r.String,
        qualification: r.String,
        password_salt: r.String,
        recovery_token: r.String,
        provider_number: r.String,
        prescriber_number: r.String,
        avatar_key: maybe(r.String),
        storage_directory: r.String,
        verification_token: r.String,
        address: ResidentialAddressSchema,
        account_type: UserAccountTypeSchema,
        practitioner_type: DoctorPractitionerSchema,
        recovery_token_expiration: maybe(DateSchema),
        access_level: UserAccountAccessLevelTypeSchema,
    })
    .extend(DoctorSchema.omit('is_administrator').fields)
    .extend(TimestampSchema.fields);
export type DoctorDatabaseRecord = r.Static<typeof DoctorDatabaseRecordSchema>;
