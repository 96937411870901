/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { MedicineAdministrationSchema, MedicineFormSchema, MedicineScheduleNumberSchema } from '../../constants';
import { IdEntitySchema } from '..';
import { maybe } from '../../utils/runtypes';

export const PrescriptionDrugSchema = r.Record({
    trade_name: maybe(r.String),
    generic_name: r.String,
    msl_enabled: r.Boolean,
    medicine_quantity: r.Number,
    medicine_strength: r.String,
    medicine_form: MedicineFormSchema,
    maximum_quantity_authorized: maybe(r.String),
    streamlined_authority: maybe(r.String),
    number_of_repeats: r.Number,
    repeat_intervals: maybe(r.Number),
    route_of_administration: MedicineAdministrationSchema,
    patient_instructions: r.String,
    medicine_quantity_extended: maybe(r.String),
    schedule_number: MedicineScheduleNumberSchema,
    pbs_code: maybe(r.String),
    extra_notes: maybe(r.String),
    private_prescription: r.Boolean,
    regulation: r.Boolean,
});
export type PrescriptionDrug = r.Static<typeof PrescriptionDrugSchema>;

export const PrescriptionDrugResourceSchema = r
    .Record({
        generated_authority_code: maybe(r.String),
    })
    .extend(PrescriptionDrugSchema.fields)
    .extend(IdEntitySchema.fields);
export type PrescriptionDrugResource = r.Static<typeof PrescriptionDrugResourceSchema>;
