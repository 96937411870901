/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 *
 * Description:
 * Prescription families, consultations and drugs.
 */

import {
    DigitalConsultationRecordSchema,
    DigitalConsultationResourceSchema,
    DigitalConsultationsCollectionSchema,
} from '../types/consultation';
import {
    InfirmaryPrescriptionRecordsSchema,
    CreatePrescriptionFamilySchema,
    PrescriptionRecordSchema,
    CreatePrescriptionFamilyClassSchema,
    PrescriptionFamilyClassSchema,
    PrescriptionConsultationOrderSchema,
    FamilyConsultationQuestionsStateSchema,
    ConsultPrescriptionFamiliesSchema,
    ConsultPrescriptionDrugsSchema,
    MedicineConsultationSchema,
} from '../types/prescription';
import { PrescriptionDrugSchema, PrescriptionDrugResourceSchema } from '../types/prescription/escript';
import { EndpointSync, HttpMethod } from '../utils/endpointSync';

export const GetPrescriptionRecordsEndpoint = new EndpointSync({
    path: '/',
    method: HttpMethod.GET,
    responseValidator: InfirmaryPrescriptionRecordsSchema,
});

export const CreatePrescriptionFamilyEndpoint = new EndpointSync({
    path: '/family',
    method: HttpMethod.POST,
    requestValidator: CreatePrescriptionFamilySchema,
    responseValidator: PrescriptionRecordSchema,
});

export const UpdateFamilyConsultationQuestionsStateEndpoint = new EndpointSync({
    path: '/family/:referenceId/consultation/questions',
    method: HttpMethod.POST,
    responseValidator: FamilyConsultationQuestionsStateSchema,
});

export const UpdatePrescriptionFamilyEndpoint = new EndpointSync({
    path: '/family/:referenceId',
    method: HttpMethod.PATCH,
    requestValidator: CreatePrescriptionFamilySchema,
});

export const DeletePrescriptionFamilyEndpoint = new EndpointSync({
    path: '/family/:referenceId',
    method: HttpMethod.DELETE,
});

export const CreatePrescriptionFamilyClassEndpoint = new EndpointSync({
    path: '/family/class/:referenceId',
    method: HttpMethod.POST,
    requestValidator: CreatePrescriptionFamilyClassSchema,
    responseValidator: PrescriptionFamilyClassSchema,
});

export const UpdatePrescriptionFamilyClassEndpoint = new EndpointSync({
    path: '/family/class/:referenceId',
    method: HttpMethod.PATCH,
    requestValidator: CreatePrescriptionFamilyClassSchema,
});

export const UpdatePrescriptionConsultationOrderEndpoint = new EndpointSync({
    path: '/family/consultation/order/:referenceId',
    method: HttpMethod.PATCH,
    requestValidator: PrescriptionConsultationOrderSchema,
});

export const DeletePrescriptionFamilyClassEndpoint = new EndpointSync({
    path: '/family/class/:referenceId',
    method: HttpMethod.DELETE,
});

export const CreatePrescriptionConsultationEndpoint = new EndpointSync({
    path: '/family/consultation/:referenceId',
    method: HttpMethod.POST,
    requestValidator: DigitalConsultationRecordSchema,
    responseValidator: DigitalConsultationResourceSchema,
});

export const UpdatePrescriptionConsultationEndpoint = new EndpointSync({
    path: '/family/consultation/:referenceId',
    method: HttpMethod.PATCH,
    requestValidator: DigitalConsultationRecordSchema,
    responseValidator: DigitalConsultationResourceSchema,
});

export const DeletePrescriptionConsultationEndpoint = new EndpointSync({
    path: '/family/consultation/:referenceId',
    method: HttpMethod.DELETE,
});

export const CreatePrescriptionDrugEndpoint = new EndpointSync({
    path: '/family/drug/:referenceId',
    method: HttpMethod.POST,
    requestValidator: PrescriptionDrugSchema,
    responseValidator: PrescriptionDrugResourceSchema,
});

export const UpdatePrescriptionDrugEndpoint = new EndpointSync({
    path: '/family/drug/:referenceId',
    method: HttpMethod.PATCH,
    requestValidator: PrescriptionDrugSchema,
    responseValidator: PrescriptionDrugResourceSchema,
});

export const DeletePrescriptionDrugEndpoint = new EndpointSync({
    path: '/family/drug/:referenceId',
    method: HttpMethod.DELETE,
});

// Consultation prescription endpoints
export const GetConsultPrescriptionFamiliesEndpoint = new EndpointSync({
    path: '/consult/families',
    method: HttpMethod.GET,
    responseValidator: ConsultPrescriptionFamiliesSchema,
});

export const SearchPrescriptionMedicineEndpoint = new EndpointSync({
    path: '/consult/medicine/search',
    method: HttpMethod.GET,
    responseValidator: ConsultPrescriptionDrugsSchema,
});

export const GetConsultPrescriptionFamilyMedicineEndpoint = new EndpointSync({
    path: '/consult/family/:prescriptionFamilyId/medicine',
    method: HttpMethod.GET,
    responseValidator: ConsultPrescriptionDrugsSchema,
});

export const GetPrescriptionMedicineConsultationEndpoint = new EndpointSync({
    path: '/consult/medicine/:prescriptionMedicineId/consultation',
    method: HttpMethod.GET,
    responseValidator: MedicineConsultationSchema,
});
