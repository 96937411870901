/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createSlice } from '@reduxjs/toolkit';
import { DoctorResource } from '../../../../api/types/user/doctor';
import { accountLogoutThunk, signInThunk, verifyUserSessionThunk } from './thunk';

interface IDoctorState {
    initials: string;
    account?: DoctorResource;
    hasVerificationEvent: boolean;
    hasAuthenticationEvent: boolean;
}

const initialState: IDoctorState = {
    initials: '',
    hasVerificationEvent: true,
    hasAuthenticationEvent: false,
};

const doctorSlice = createSlice({
    name: 'doctor',
    initialState,
    reducers: {
        resetAuthEvent(draft) {
            draft.hasAuthenticationEvent = false;
        },
    },
    extraReducers: (reducers) => {
        reducers
            .addCase(signInThunk.pending, (draft) => {
                draft.hasAuthenticationEvent = true;
            })
            .addCase(signInThunk.fulfilled, (draft, { payload }) => {
                if (payload != null) {
                    draft.account = payload;
                    draft.initials = `${payload.first_name[0]}${payload.last_name[0]}`.toUpperCase();
                }

                draft.hasAuthenticationEvent = false;
            })
            .addCase(signInThunk.rejected, (draft) => {
                draft.hasAuthenticationEvent = false;
            })
            .addCase(verifyUserSessionThunk.fulfilled, (draft, { payload }) => {
                draft.hasVerificationEvent = false;
                if (payload == null) {
                    return;
                }

                draft.account = payload;
                draft.initials = `${payload.first_name[0]}${payload.last_name[0]}`.toUpperCase();
            })
            .addCase(verifyUserSessionThunk.rejected, (draft) => {
                draft.hasVerificationEvent = false;
            })
            .addCase(accountLogoutThunk.pending, (draft) => {
                draft.hasAuthenticationEvent = true;
            })

            .addCase(accountLogoutThunk.fulfilled, (draft) => {
                delete draft.account;
            });
    },
});

export const doctorReducer = doctorSlice.reducer;

export const { resetAuthEvent } = doctorSlice.actions;
