/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import styled from 'styled-components';

export const StyledHeadingOne = styled.h1`
    font-size: 44px;
    font-weight: 700;

    @media (max-width: 960px) {
        font-size: 38px;
    }

    @media (max-width: 560px) {
        font-size: 34px;
    }
`;

export const StyledHeadingTwo = styled.h2`
    font-size: 40px;
    line-height: 1.5;
    font-weight: 700;

    @media (max-width: 960px) {
        font-size: 36px;
    }

    @media (max-width: 560px) {
        font-size: 30px;
    }
`;

export const StyledHeadingThree = styled.h3`
    font-size: 36px;
    font-weight: 700;

    @media (max-width: 960px) {
        font-size: 32px;
    }

    @media (max-width: 560px) {
        font-size: 27px;
    }
`;

export const StyledHeadingFour = styled.h4`
    font-size: 27px;
    font-weight: 700;

    @media (max-width: 560px) {
        font-size: 24px;
    }
`;

export const StyledHeadingFive = styled.h5`
    font-size: 24px;
    font-weight: 700;

    @media (max-width: 560px) {
        font-size: 20px;
    }
`;

export const StyledHeadingSix = styled.h6`
    font-size: 18px;
    font-weight: 700;

    @media (max-width: 560px) {
        font-size: 18px;
    }
`;
