/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { validateAddressPostcode, validateAddressStreet, validateAddressCity } from '../validator';
import { StateSchema } from '../constants';

export const ResidentialAddressSchema = r.Record({
    state: StateSchema,
    city: r.String.withConstraint(validateAddressCity),
    street: r.String.withConstraint(validateAddressStreet),
    post_code: r.String.withConstraint(validateAddressPostcode),
});

export type ResidentialAddress = r.Static<typeof ResidentialAddressSchema>;
