/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { infirmaryDoctorLogout, infirmaryDoctorSessionVerification, infirmaryDoctorSignIn } from '../../fetch/auth';
import { setSnackbarMessage } from '../snackbar';
import { SnackbarTypes } from '../../../../common/types';
import { FetchError, handleError } from '../../tools/fetch';
import { DoctorResource } from '../../../../api/types/user/doctor';
import { LoginRequest } from '../../../../api/types/auth';

export const signInThunk = createAsyncThunk<
    DoctorResource,
    {
        body: LoginRequest;
        redirectUrl?: string | null;
    },
    { state: RootState }
>('doctor/sign-in', async ({ body, redirectUrl }, { rejectWithValue, dispatch }) => {
    try {
        const user = await infirmaryDoctorSignIn(body);
        // Redirect the user.
        if (redirectUrl != null) {
            window.location.href = decodeURIComponent(redirectUrl);
        }
        return user;
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message:
                    response?.reason ??
                    'Something went wrong. We could not complete your login request. Please try again later',
                type: SnackbarTypes.ERROR,
            }),
        );
        return rejectWithValue(response?.reason);
    }
});

export const verifyUserSessionThunk = createAsyncThunk<DoctorResource | undefined, void>(
    'doctor/verify/session',
    async (_, { rejectWithValue }) => {
        try {
            const user = await infirmaryDoctorSessionVerification();
            return user;
        } catch (error) {
            const response = await handleError(error as FetchError);
            return rejectWithValue(response);
        }
    },
);

export const accountLogoutThunk = createAsyncThunk<void, void>(
    'doctor/logout',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            await infirmaryDoctorLogout();
            localStorage.clear();
            sessionStorage.clear();
            window.location.href = process.env.PLATFORM_APP_HOST ?? 'https://aussiescripts.com.au';
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message:
                        response?.reason ??
                        'Something went wrong. We could not complete your logout request. Please try again later',
                    type: SnackbarTypes.ERROR,
                }),
            );
            rejectWithValue(response);
        }
    },
);
