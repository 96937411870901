/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { StyledHeadingFour, StyledHeadingTwo } from '../components/shared/Heading/style';
import { StyledButton } from '../components/shared/Buttons/Button/style';
import { StyledGroupCell } from '../components/Dashboard/style';
import { SnackbarTypes } from '../../../common/types';

// Shared view max-width for containers.
export const VIEW_CONTAINER_MAX_WIDTH = 1240;

export const NAVIGATION_BAR_HEIGHT = 80;

export const ASIDE_NAVIGATION_WIDTH = 280;

const StyledOverlay = styled.div`
    background: rgba(0, 0, 0, 0.6);
`;

/**
 * Returns css styling for a flex container.
 *
 * @param direction set the direction of the flexible items inside the container
 * @param justify property to align the items horizontally
 * @param align property to align the items vertically
 * @returns flex css styling properties
 */
export const flexContainerStyle = (
    direction: 'row' | 'column' | 'row-reverse' | 'column-reverse' | 'initial' | 'inherit',
    justify:
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'
        | 'initial'
        | 'inherit',
    align:
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'stretch'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'
        | 'initial'
        | 'inherit',
) => css`
    display: flex;
    flex-direction: ${direction};
    justify-content: ${justify};
    align-items: ${align};
`;

export const StyledButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        width: calc(50% - 12px);

        &:not(:last-child) {
            margin-right: auto;
        }
    }
`;

export const StyledMainContainer = styled.main`
    min-height: 100vh;
    position: relative;
    padding-left: ${ASIDE_NAVIGATION_WIDTH}px;

    @media (max-width: 1340px) {
        padding-left: 260px;
    }

    @media (max-width: 1140px) {
        padding-left: 96px;
    }
`;

export const StyledContentWrapper = styled.div`
    width: 100%;
    margin: auto;
    padding: 0 24px;
    position: relative;
    max-width: ${VIEW_CONTAINER_MAX_WIDTH}px;

    @media (max-width: 480px) {
        padding: 0 16px;
    }
`;

export const StyledTableContentWrapper = styled.div`
    width: 100%;
    margin: auto;
    padding: 0 24px;
    position: relative;
    max-width: ${VIEW_CONTAINER_MAX_WIDTH}px;

    div:first-child {
        width: 100% !important;
        max-width: 100% !important;
    }
`;

export const StyledReaderContentWrapper = styled(StyledContentWrapper)`
    max-width: 800px;
    padding: 0;
`;

export const StyledSectionContainer = styled.section<{ highlight?: boolean; minified?: boolean }>`
    padding: 100px 0;
    position: relative;

    ${StyledContentWrapper}:not(:last-child) {
        margin-bottom: 60px;
    }

    ${({ highlight }) =>
        highlight &&
        css`
            background: ${({ theme }) => theme.BackgroundSecondary};
        `}

    ${({ minified }) =>
        minified &&
        css`
            padding: 64px 0;
        `}
`;

export const StyledNavLink = styled(NavLink)`
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.TextPrimary};
    transition: all 0.25s;
    text-transform: uppercase;

    &:hover {
        color: ${({ theme }) => theme.BrandPrimary};
    }
`;

export const StyledProductsListLoader = styled.div<{ isLoadingAll: boolean }>`
    height: ${({ isLoadingAll }) => (isLoadingAll ? '40vh' : '100px')};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledFullScreenOverlay = styled(StyledOverlay)`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    z-index: 1000;
    align-items: center;
    justify-content: center;
`;

export const StyledLongCopy = styled.p`
    font-size: 16px;
    max-width: 760px;
    line-height: 24px;
    font-weight: 400px;
`;

export const StyledSectionHeader = styled.div`
    text-align: left;
    margin-bottom: 64px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    ${StyledLongCopy} {
        margin-top: 24px;
        color: ${({ theme }) => theme.BackgroundPrimary};
    }

    ${StyledHeadingTwo} {
        max-width: 920px;
    }

    ${StyledHeadingFour} {
        max-width: 920px;
        line-height: 40px;
    }
`;

export const StyledSectionContent = styled.div`
    position: relative;
`;

export const StyledServiceCardsGrid = styled.div`
    display: grid;
    grid-gap: 24px;
    position: relative;
    grid-template-columns: repeat(3, minmax(70px, 1fr));

    @media (max-width: 1200px) {
        grid-template-columns: repeat(2, minmax(70px, 1fr));
    }

    @media (max-width: 760px) {
        grid-template-columns: repeat(1, minmax(70px, 1fr));
    }
`;

export const StyledSnackbarIcon = styled.div`
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 4px;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 24px;
        height: 24px;
    }
`;

export const StyledSnackbarButton = styled.button`
    width: 24px;
    border: none;
    height: 24px;
    outline: none;
    flex-shrink: 0;
    margin-left: auto;
    background: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledSnackbarWrapper = styled.div<{ status: SnackbarTypes }>`
    width: 100%;
    padding: 12px;
    position: relative;
    border-radius: 8px;
    background: ${({ theme }) => theme.BackgroundPrimary};
    border: 4px solid ${({ theme }) => theme.BorderPrimary};

    display: flex;
    align-items: flex-start;
    justify-content: center;

    color: ${({ theme }) => theme.TextPrimary};

    span {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        margin-right: 16px;
        display: inline-block;
    }

    ${({ status, theme }) => {
        switch (status) {
            case SnackbarTypes.INFO: {
                return css`
                    svg {
                        color: ${theme.BluePrimary};
                    }
                    border-color: ${theme.BlueTertiary};
                `;
            }
            case SnackbarTypes.SUCCESS: {
                return css`
                    svg {
                        color: ${theme.SuccessPrimary};
                    }
                    border-color: ${theme.SuccessTertiary};
                `;
            }
            case SnackbarTypes.ERROR: {
                return css`
                    svg {
                        color: ${theme.ErrorPrimary};
                    }
                    border-color: ${theme.ErrorTertiary};
                `;
            }
            case SnackbarTypes.WARNING: {
                return css`
                    svg {
                        color: ${theme.ErrorPrimary};
                    }
                    border-color: ${theme.ErrorTertiary};
                `;
            }
        }
    }}
`;

export const StyledFormGroupHeader = styled.div`
    gap: 8px;
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.TextPrimary};
    line-height: 26px;
    margin-bottom: 24px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
        height: 26px;
        padding: 6px;
        font-size: 12px;
        color: ${({ theme }) => theme.BrandPrimary};
        line-height: 22px;
        border-radius: 20px;
        display: inline-block;
        text-transform: uppercase;
        border: 1px solid ${({ theme }) => theme.BrandPrimary};

        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const StyledBadge = styled.span`
    padding: 10px 13px;
    position: relative;
    text-align: center;
    border-radius: 4px;
    white-space: nowrap;
    background: ${({ theme }) => theme.WarningPrimary};
    vertical-align: baseline;

    font-size: 14px;
    font-weight: 700;
    display: inline-block;
    text-transform: uppercase;
`;

export const StyledPortalGroupCell = styled.div`
    margin-top: 0;
    position: relative;

    &:not(:last-child):not(.no-margin-bottom) {
        margin-bottom: 30px;
    }
`;

export const StyledPortalGroupWrapper = styled.div`
    position: relative;

    &:not(:last-child) {
        margin-bottom: 46px;
    }
`;

export const StyledPortalGroupTitle = styled.div`
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    color: ${({ theme }) => theme.BrandPrimary};
`;

export const StyledPortalGroupHeader = styled.div`
    margin-bottom: 24px;
    position: relative;
`;

export const StyledPortalServicesGrid = styled.div`
    display: grid;
    grid-gap: 30px;
    overflow-y: auto;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(2, minmax(70px, 1fr));

    @media (max-width: 680px) {
        grid-template-columns: repeat(1, minmax(70px, 1fr));
    }
`;

export const StyledPortalGroupContent = styled.div`
    position: relative;
`;

export const StyledServiceActionCardContainer = styled(NavLink)`
    height: 100%;
    cursor: pointer;
    position: relative;
    border-radius: 8px;
    background: ${({ theme }) => theme.BrandWhite};
    transition: border 0.25s;
    border: 1px solid ${({ theme }) => theme.BorderPrimary};

    &:hover {
        border-color: ${({ theme }) => theme.BrandPrimary};
        .service-action-card-title {
            color: ${({ theme }) => theme.BrandPrimary};
        }
    }
`;

export const StyledGroupedContentWrapper = styled.div<{ disabled?: boolean }>`
    padding: 24px;
    position: relative;
    border-radius: 12px;
    background: ${({ theme }) => theme.BrandWhite};
    border: 1px solid ${({ theme }) => theme.BorderPrimary};

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.7;
            pointer-events: none;
            filter: grayscale(100%);
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        `}
`;

export const StyledFormGroup = styled.div`
    width: 100%;
    position: relative;

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    ${StyledButton} {
        width: 100%;
    }
`;

export const StyledFormGroupRow = styled(StyledFormGroup)`
    gap: 24px;
    position: relative;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    ${StyledGroupCell} {
        width: 50%;
        position: relative;

        &:not(:last-child) {
            margin-bottom: 0;
        }
    }
`;

export const StyledConsultationLabel = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: ${({ theme }) => theme.TextSecondary};
`;

export const StyledConsultationValue = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: ${({ theme }) => theme.TextPrimary};
`;

export const StyledConsultationGroupHeader = styled(StyledFormGroupHeader)`
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.BorderPrimary};
`;

export const StyledContentSectionDivider = styled.div`
    width: 100%;
    height: 1px;
    margin: 24px 0;
    background: ${({ theme }) => theme.BorderSecondary};
`;
