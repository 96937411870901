/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../../utils/runtypes';

export const MENTAL_HEALTH_TREATMENT_PLAN_PRICE = 100;
export const MENTAL_HEALTH_TREATMENT_PLAN_REVIEW_PRICE = 100;

export const MEDICAL_PLAN_MEDICATE_REBATE_VALUE = 65.35;
export const MEDICAL_REVIEW_MEDICATE_REBATE_VALUE = 65.35;

export const MEDICAL_PLAN_MEDICATE_REBATE_CODE = '92118';
export const MEDICAL_REVIEW_MEDICATE_REBATE_CODE = '92120 | 92132';

export enum MedicalHealthService {
    MentalHealthTreatmentPlan = 'Mental Health Treatment Plan',
    MentalHealthTreatmentReview = 'Mental Health Treatment Review',
}
export const MedicalHealthServiceSchema = runtypeFromEnum(MedicalHealthService);

export interface MedicalHealthConsultationType {
    urlSlug: string;
    priceMeta?: string;
    description: string;
    title: MedicalHealthService;
}

export type MedicalHealthType = {
    [key in MedicalHealthService]: MedicalHealthConsultationType;
};

export const MentalStateExaminationTemplate = `Appearance and General Behavior: Normal/Abnormal

Mood: Normal / Disinterested/flat?

Affect: Reactive/No reactive

Thinking: Normal/Abnormal

Abnormal/intrusive thoughts: No/Yes

Cognition: Normal/Abnormal

Orientation (time/place/person): Normal/Disoriented

Attention/Concentration: Normal/ Poor, easily distracted, hard to finish tasks

Memory: Normal /Forgetful

Insight: Normal/ Abnormal

Appetite: Normal /Abnormal

Motivation/Energy: Normal / Low

Sleep: Normal/ Variable

Judgement: Normal/Abnormal`;
