/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { RootState } from '../../store';

export const selectSnackbarEvent = (state: RootState) => state.snackbar.snackbar;
