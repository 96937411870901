/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../../utils/runtypes';

export enum PrescriptionServiceTitle {
    OnlinePrescription = 'Online Prescription',
    OnlinePrescriptionWithConsultation = 'Online Prescription With Consultation',
}
export const PrescriptionServiceTitleSchema = runtypeFromEnum(PrescriptionServiceTitle);

export enum ScriptType {
    ElectronicScript = 'Electronic Script',
    PrintedScript = 'Printed Script',
}
export const ScriptTypeSchema = runtypeFromEnum(ScriptType);

export enum PsychologicalEducationAnswer {
    Yes = 'Yes, I have',
    No = 'No',
}
export const PsychologicalEducationAnswerSchema = runtypeFromEnum(ScriptType);
