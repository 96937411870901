/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../utils/runtypes';

export const CONSULTATION_NOTE_LENGTH = 320;

export const MEDICAL_CERTIFICATE_MAX_DAYS = 3;

export const PATIENT_NOTIFICATION_MINUTES_LIMIT = 15;

export enum PatientsSortCategory {
    Ascending = 'Ascending',
    Descending = 'Descending',
}

export enum PatientNotificationType {
    Call = 'Call',
    Video = 'Video',
}
export const PatientNotificationTypeSchema = runtypeFromEnum(PatientNotificationType);

export enum ConsultationStatus {
    Finalizing = 'Finalizing',
    WrongPhoneNumber = 'WrongPhoneNumber',
    CancellingNoNote = 'Cancelling No Note',
    NoAnswer = 'No Answer Finalizing',
    CancellingWithNote = 'Cancelling With Note',
}

export enum CancelConsultationReason {
    UnableToContactPatient = 'Unable To Contact Patient',
    InappropriateMedication = 'Inappropriate Medication',
    InappropriateConsultation = 'Inappropriate Consultation',
    InappropriateBehavior = 'Inappropriate Behavior',
    IncorrectConsultationType = 'Incorrect Consultation Type',
}
