/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { RootState } from '../../store';

export const selectThemeType = (state: RootState) => state.platform.theme;

export const selectIsInMobileView = (state: RootState) => state.platform.isInMobileView;
