/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { DigitalConsultationSchema, PhoneNumberSchema } from '.';
import { WeightManagementServiceTitleSchema } from '../constants';
import { ServiceRequestType } from '../constants/checkout';

const WeightManagementConsultationDetailsSchema = r.Record({
    phone: PhoneNumberSchema,
    patient_height: r.Number,
    patient_weight: r.Number,
});

export const WeightManagementRequestSchema = r.Record({
    title: WeightManagementServiceTitleSchema,
    consultations: r.Array(DigitalConsultationSchema),
    category: r.Literal(ServiceRequestType.WeightManagement),
    consultationDetails: WeightManagementConsultationDetailsSchema,
});
export type WeightManagementRequest = r.Static<typeof WeightManagementRequestSchema>;
