/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { PATIENT_FLAG_MESSAGE_LIMIT } from '../../constants';

export function validateMedicalRequestFlagMessage(message: string) {
    return (
        (message.length > 0 && message.length <= PATIENT_FLAG_MESSAGE_LIMIT) || 'Invalid medical request flag message.'
    );
}
