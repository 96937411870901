/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { RootState } from '../../store';

export const selectUserState = (state: RootState) => state.doctor;

export const selectHasVerificationEventEvent = (state: RootState) => state.doctor.hasVerificationEvent;

export const selectHasAuthenticationEvent = (state: RootState) => state.doctor.hasAuthenticationEvent;

export const selectDoctorAvatar = ({ doctor }: RootState) => doctor.account?.avatar ?? undefined;

export const selectIsDoctorAuthorized = (state: RootState) => state.doctor.account != null;

export const selectIsAccountReady = ({ doctor }: RootState) => doctor?.account != null;

export const selectDoctorEmail = (state: RootState) => state.doctor.account?.email ?? '';

export const selectDoctorFullName = (state: RootState) =>
    state.doctor.account != null ? `${state.doctor.account.first_name} ${state.doctor.account.last_name}` : '';

export const selectDoctorNameInitials = (state: RootState) => state.doctor.initials;

export const selectDoctorId = (state: RootState) => state.doctor?.account?.id;

export const selectDoctorInitials = (state: RootState) =>
    `${state.doctor?.account?.first_name[0] ?? 'E'}${state.doctor?.account?.last_name[0] ?? 'S'}`.toUpperCase();
