/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { ARTICLE_MANAGER_PATH } from '../../../api/endpoints';
import {
    CreateArticleEndpoint,
    DeleteArticleEndpoint,
    GetDashboardArticlesEndpoint,
    UpdateArticleEndpoint,
    UpdateArticlePublicationEndpoint,
} from '../../../api/endpoints/article';
import { CreateArticle, UpdateArticle } from '../../../api/types/articles';
import { endpointFetch } from '../tools/fetch';

export function getDashboardArticles() {
    return endpointFetch(GetDashboardArticlesEndpoint, { scope: ARTICLE_MANAGER_PATH, redirect: false });
}

export function createArticle(body: CreateArticle) {
    return endpointFetch(CreateArticleEndpoint, { scope: ARTICLE_MANAGER_PATH, body, redirect: false });
}

export function updateArticle(body: UpdateArticle, articleId: string) {
    return endpointFetch(UpdateArticleEndpoint, {
        scope: ARTICLE_MANAGER_PATH,
        body,
        params: { articleId },
        redirect: false,
    });
}

export function updateArticlePublication(articleId: string) {
    return endpointFetch(UpdateArticlePublicationEndpoint, {
        scope: ARTICLE_MANAGER_PATH,
        params: { articleId },
        redirect: false,
    });
}

export function deleteArticle(articleId: string) {
    return endpointFetch(DeleteArticleEndpoint, {
        scope: ARTICLE_MANAGER_PATH,
        params: { articleId },
        redirect: false,
    });
}
