/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../../utils/runtypes';

export enum Specialist {
    Pediatrics = 'Pediatrics',
    PainManagement = 'Pain Management',
    PsychiatryGeneralAdult = 'Psychiatry - General Adult',
    PsychiatryChildAndAdolescent = 'Psychiatry - Child and Adolescent',
    PsychiatryGeriatric = 'Psychiatry - Geriatric',
    Psychotherapy = 'Psychotherapy',
    EndocrinologyAndDiabetes = 'Endocrinology And Diabetes',
    Urology = 'Urology',
    RespiratoryAndSleep = 'Respiratory And Sleep',
    Cardiology = 'Cardiology',
    Rheumatology = 'Rheumatology',
    Neurology = 'Neurology',
    Gastroenterology = 'Gastroenterology',
    Geriatrician = 'Geriatrician',
    RenalPhysician = 'Renal Physician',
    AllergyAndImmunology = 'Allergy And Immunology',
    BloodPressurePhysician = 'Blood Pressure Physician',
    SpeechPathology = 'Speech Pathology',
    GeneralPhysician = 'General RenalPhysician',
    RadiationOncology = 'Radiation Oncology',
    InfectiousDiseases = 'Infectious Diseases',
    PalliativeCare = 'Palliative Care',
    Dermatology = 'Dermatology',
    EarNoseAndThroat = 'Ear Nose and Throat (ENT)',
    OrthopaedicSurgeon = 'Orthopaedic Surgeon',
    Gynecologist = 'Gynecologist',
    Obstetrician = 'Obstetrician',
    Ophthalmology = 'Ophthalmology',
    SportsMedicine = 'Sports Medicine',
}
export const SpecialistSchema = runtypeFromEnum(Specialist);
