/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../../utils/runtypes';

export enum NicotineVapingService {
    NicotineVaping = 'Nicotine Vaping',
}
export const NicotineVapingServiceTitleSchema = runtypeFromEnum(NicotineVapingService);
