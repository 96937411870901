/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { PRESCRIPTION_MANAGER_PATH } from '../../../api/endpoints';
import {
    CreatePrescriptionConsultationEndpoint,
    CreatePrescriptionDrugEndpoint,
    CreatePrescriptionFamilyClassEndpoint,
    CreatePrescriptionFamilyEndpoint,
    DeletePrescriptionConsultationEndpoint,
    DeletePrescriptionDrugEndpoint,
    DeletePrescriptionFamilyClassEndpoint,
    DeletePrescriptionFamilyEndpoint,
    GetPrescriptionRecordsEndpoint,
    UpdateFamilyConsultationQuestionsStateEndpoint,
    UpdatePrescriptionConsultationEndpoint,
    UpdatePrescriptionConsultationOrderEndpoint,
    UpdatePrescriptionDrugEndpoint,
    UpdatePrescriptionFamilyClassEndpoint,
    UpdatePrescriptionFamilyEndpoint,
} from '../../../api/endpoints/prescription';
import { DigitalConsultationRecord } from '../../../api/types/consultation';
import { CreatePrescriptionFamily, CreatePrescriptionFamilyClass } from '../../../api/types/prescription';
import { PrescriptionDrug } from '../../../api/types/prescription/escript';
import { endpointFetch } from '../tools/fetch';

export function getPrescriptionRecords() {
    return endpointFetch(GetPrescriptionRecordsEndpoint, { scope: PRESCRIPTION_MANAGER_PATH });
}

export function createPrescriptionFamily(body: CreatePrescriptionFamily) {
    return endpointFetch(CreatePrescriptionFamilyEndpoint, { scope: PRESCRIPTION_MANAGER_PATH, body });
}

export function updatePrescriptionFamily(referenceId: string, body: CreatePrescriptionFamily) {
    return endpointFetch(UpdatePrescriptionFamilyEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        body,
        params: {
            referenceId,
        },
    });
}

export function deletePrescriptionFamily(referenceId: string) {
    return endpointFetch(DeletePrescriptionFamilyEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        params: { referenceId },
    });
}

export function createPrescriptionFamilyClass(body: CreatePrescriptionFamilyClass, familyReferenceId: string) {
    return endpointFetch(CreatePrescriptionFamilyClassEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        body,
        params: { referenceId: familyReferenceId },
    });
}

export function updatePrescriptionFamilyClass(referenceId: string, body: CreatePrescriptionFamilyClass) {
    return endpointFetch(UpdatePrescriptionFamilyClassEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        body,
        params: {
            referenceId,
        },
    });
}

export function deletePrescriptionFamilyClass(referenceId: string) {
    return endpointFetch(DeletePrescriptionFamilyClassEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        params: { referenceId },
    });
}

export function createPrescriptionConsultation(prescriptionFamilyClassId: string, body: DigitalConsultationRecord) {
    return endpointFetch(CreatePrescriptionConsultationEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        body,
        params: {
            referenceId: prescriptionFamilyClassId,
        },
    });
}

export function updatePrescriptionConsultation(referenceId: string, body: DigitalConsultationRecord) {
    return endpointFetch(UpdatePrescriptionConsultationEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        body,
        params: {
            referenceId,
        },
    });
}

export function deletePrescriptionConsultation(referenceId: string) {
    return endpointFetch(DeletePrescriptionConsultationEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        params: { referenceId },
    });
}

export function createPrescriptionDrug(prescriptionFamilyClassId: string, body: PrescriptionDrug) {
    return endpointFetch(CreatePrescriptionDrugEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        body,
        params: {
            referenceId: prescriptionFamilyClassId,
        },
    });
}

export function updatePrescriptionDrug(referenceId: string, body: PrescriptionDrug) {
    return endpointFetch(UpdatePrescriptionDrugEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        body,
        params: {
            referenceId,
        },
    });
}

export function deletePrescriptionDrug(referenceId: string) {
    return endpointFetch(DeletePrescriptionDrugEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        params: { referenceId },
    });
}

export function updatePrescriptionConsultationOrder(referenceId: string, body: string[]) {
    return endpointFetch(UpdatePrescriptionConsultationOrderEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        params: { referenceId },
        body,
    });
}

export function updateFamilyConsultationQuestionsState(referenceId: string) {
    return endpointFetch(UpdateFamilyConsultationQuestionsStateEndpoint, {
        scope: PRESCRIPTION_MANAGER_PATH,
        params: { referenceId },
    });
}
