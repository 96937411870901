/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { ConsultationMessageSchema, DigitalConsultationSchema, PhoneNumberSchema } from '.';
import { SpecialistReferralServiceTitleSchema } from '../constants';
import { ServiceRequestType } from '../constants/checkout';

const ReferralConsultationDetailsSchema = r.Record({
    phone: PhoneNumberSchema,
    medicalHistory: ConsultationMessageSchema,
    currentMedication: ConsultationMessageSchema,
});

export const SpecialistReferralRequestSchema = r.Record({
    title: SpecialistReferralServiceTitleSchema,
    consultations: r.Array(DigitalConsultationSchema),
    consultationDetails: ReferralConsultationDetailsSchema,
    category: r.Literal(ServiceRequestType.SpecialistReferral),
});
export type SpecialistReferralRequest = r.Static<typeof SpecialistReferralRequestSchema>;
