/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createSlice } from '@reduxjs/toolkit';
import { ArticleDashboardResource } from '../../../../api/types/articles';
import {
    createArticleThunk,
    deleteArticleThunk,
    getArticlesThunk,
    updateArticlePublicationThunk,
    updateArticleThunk,
} from './thunk';

interface IArticleState {
    isFetchingArticles: boolean;
    articles: ArticleDashboardResource[];
    requestEvents: Record<string, boolean>;
}

const initialState: IArticleState = {
    articles: [],
    requestEvents: {},
    isFetchingArticles: false,
};

const articlesSlice = createSlice({
    name: 'articles',
    initialState,
    reducers: {},
    extraReducers(reducers) {
        reducers
            // Get articles thunk handlers.
            .addCase(getArticlesThunk.pending, (draft) => {
                draft.isFetchingArticles = true;
            })
            .addCase(getArticlesThunk.fulfilled, (draft, { payload }) => {
                draft.articles = payload;
                draft.isFetchingArticles = false;
            })
            .addCase(getArticlesThunk.rejected, (draft) => {
                draft.isFetchingArticles = false;
            })
            // Create article thunk handlers.
            .addCase(createArticleThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.eventId] = true;
            })
            .addCase(createArticleThunk.fulfilled, (draft, { payload, meta }) => {
                draft.articles.push(payload);
                delete draft.requestEvents[meta.arg.eventId];
            })
            .addCase(createArticleThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg.eventId];
            })
            // Update article thunk handlers.
            .addCase(updateArticleThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.articleId] = true;
            })
            .addCase(updateArticleThunk.fulfilled, (draft, { payload, meta }) => {
                const articleIndex = draft.articles.findIndex(({ id }) => id === meta.arg.articleId);

                if (articleIndex !== -1) {
                    draft.articles[articleIndex] = payload;
                }

                delete draft.requestEvents[meta.arg.articleId];
            })
            .addCase(updateArticleThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg.articleId];
            })
            // Update article publication thunk handlers.
            .addCase(updateArticlePublicationThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg] = true;
            })
            .addCase(updateArticlePublicationThunk.fulfilled, (draft, { payload, meta }) => {
                const articleIndex = draft.articles.findIndex(({ id }) => id === meta.arg);

                if (articleIndex !== -1) {
                    draft.articles[articleIndex] = payload;
                }

                delete draft.requestEvents[meta.arg];
            })
            .addCase(updateArticlePublicationThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg];
            })
            // Delete article thunk handlers.
            .addCase(deleteArticleThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.articleId] = true;
            })
            .addCase(deleteArticleThunk.fulfilled, (draft, { meta }) => {
                draft.articles = draft.articles.filter(({ id }) => id !== meta.arg.articleId);
                delete draft.requestEvents[meta.arg.articleId];
            })
            .addCase(deleteArticleThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg.articleId];
            });
    },
});

export const articlesReducer = articlesSlice.reducer;
