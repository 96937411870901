/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../../utils/runtypes';

export enum WeightManagementService {
    WeightManagement = 'Weight Management',
    WeightManagementFollowUp = 'Weight Management Follow Up',
}
export const WeightManagementServiceTitleSchema = runtypeFromEnum(WeightManagementService);
