/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { runtypeFromEnum } from '../../../utils/runtypes';

// Categories for skincare aesthetics.
export enum SkincareAestheticsCategories {
    AcneAndCongestionTreatments = 'Acne and Congestion Treatments',
    FacialTreatments = 'Facial Treatments',
    ExpressFacials = 'Express Facials',
    AddOns = 'Add ons',
    // CarbonLaserFacial = 'Carbon Laser Facial',
    AntiAgingScaringTreatments = 'Anti-Aging / Scaring Treatments',
    FocusDual = 'Focus Dual (HIFU/RFMN)',
    DermaplaningTreatments = 'Dermaplaning Treatments',
    IPLSkinTreatments = 'IPL Skin Treatments',
    PigmentationMelasmaTreatments = 'Pigmentation / Melasma Treatments',
    VascularRednessRosaceaSensitiveSkinTreatments = 'Vascular/Redness/Rosacea/Sensitive Skin Treatments',
    SkinTagMoleRemoval = 'Skin Tag / Mole Removal / Cherry Angioma / Sebaceous Hyperplasia Removal',
    BodyFatReductionTreatments = 'Body Fat Reduction Treatments',
    BodySkinTighteningTreatments = 'Body Skin Tightening Treatments',
    IntimateLighteningTreatments = 'Intimate Lightening Treatments',
    ScalpHairGrowthTreatments = 'Scalp Hair Growth Treatments',
    IPLFaceBodyHairRemovalFemale = 'IPL Face/Body Hair Removal Treatments - Female',
    IPLFaceBodyHairRemovalMale = 'IPL Face/Body Hair Removal Treatments - Male',
    Review = 'Review',
}
export const SkincareAestheticsCategoriesSchema = runtypeFromEnum(SkincareAestheticsCategories);

// Hydration services for skincare aesthetics.
export enum AcneAndCongestionTreatmentsServices {
    ActiveAcneTreatmentOneArea = 'Active Acne Treatment One Area (Choose from face, neck or chest)',
    ActiveAcneTreatmentTwoArea = 'Active Acne Treatment Two Areas (Choose from face, neck or chest)',
    ActiveAcneTreatmentThreeArea = 'Active Acne Treatment Three areas (Face, neck and chest)',
    ActiveAcneTreatmentFaceOnly = 'Advanced Acne Treatment (Face Only)',
    ActiveAcneTreatmentHalfBack = 'Active Acne Treatment - (Half Back)',
    ActiveAcneTreatmentFullBack = 'Active Acne Treatment - (Full Back)',
    MiliaBlackheadExtractionsAlonePlusTen = 'Milia / Blackhead Extractions Alone (10+ lesions)',
    MiliaBlackheadExtractionsAloneMinusTen = 'Milia / Blackhead Extractions Alone (-10 lesions)',
}
export const AcneAndCongestionTreatmentsServicesSchema = runtypeFromEnum(AcneAndCongestionTreatmentsServices);

export enum FacialTreatmentsServices {
    OneHourCustomFacial = '1hr custom facial',
    BlackheadMedifacial = 'Blackhead Medifacial',
    AntiagingMedifacial = 'Antiaging Medifacial',
    CalmingMedifacialTreatment = 'Calming Medifacial Treatment',
    TheManFacial = 'The Man Facial',
    MicrodermabrasionFacial = 'Microdermabrasion Facial',
    BrighteningFacial = 'Brightening Facial',
    SynergieS2SMedifacial = 'Synergie S2S Medifacial',
    PregnancyMedifacial = 'Pregnancy Medifacial',
    MirraSignatureDermafracFacial = 'Signature Dermaroller Facial',
}
export const FacialTreatmentsServicesSchema = runtypeFromEnum(FacialTreatmentsServices);

export enum ExpressFacialsServices {
    BrighteningFacialExpress = 'Brightening Facial Express',
    BlackheadFacialExpress = 'Blackhead Facial Express',
    AntiagingMedifacialExpress = 'Antiaging Medifacial Express',
    TheManFacialExpress = 'The Man Facial Express',
}
export const ExpressFacialsServicesSchema = runtypeFromEnum(ExpressFacialsServices);

export enum AddOnsServices {
    DermaplaningAddOn = 'Dermaplaning Add On',
    BlackheadExtractionsExtendedAddOn = 'Blackhead Extractions Extended Add On',
    MicrodermabrasionAddOn = 'Microdermabrasion Add on',
    LEDAddOn = 'LED Add On',
}
export const AddOnsServicesSchema = runtypeFromEnum(AddOnsServices);

export enum CarbonLaserFacialServices {
    CarbonFacialAlone = 'Carbon Facial (Alone)',
    CarbonFacialPlusLED = 'Carbon Facial + LED',
    CarbonFacialPlusChemicalPeel = 'Carbon Facial + Chemical Peel',
    CarbonFacialPlusIPLRejuvenation = 'Carbon Facial + IPL Rejuvenation',
    CarbonFacialPlusChemicalPeelPlusLED = 'Carbon Facial + Chemical Peel + LED',
}
export const CarbonLaserFacialServicesSchema = runtypeFromEnum(CarbonLaserFacialServices);

export enum AntiAgingScaringTreatmentsServices {
    ExpressSkinNeedling = 'Express Skin Needling (30 mins)',
    Dermapen4SkinNeedling45Mins = 'Dermaroller 4 Skin Needling (45 mins)',
    Dermapen4SkinNeedlingUberProPeel = 'Dermaroller 4 Skin Needling + Uber Pro peel',
    Dermapen4WithCustomSerum = 'Dermaroller 4 with Custom Serum and Take Home Aftercare',
    DermafracAlone = 'Dermaroller (Alone)',
    Factor4SkinNeedling = 'Factor 4 Skin Needling',
    PlasmaSkinTighteningEyes = 'Plasma Skin Tightening - Eyes (Upper or Lower including Crowsfeet)',
    PlasmaSkinTighteningFrownLines = 'Plasma Skin Tightening - Frown Lines',
    PlasmaSkinTighteningForeheadLines = 'Plasma Skin Tightening - Forehead Lines',
    PlasmaSkinTighteningBrowLift = 'Plasma Skin Tightening - Brow Lift',
    PlasmaSkinTighteningPerioralLines = 'Plasma Skin Tightening - Perioral lines (Smokers lines or Lip flip)',
    PlasmaSkinTighteningEarFolds = 'Plasma Skin Tightening - Ear Folds',
    PlasmaSkinTighteningMinimumSingleLine = 'Plasma Skin Tightening - Minimum single line',
    RFSkinTighteningOneArea = 'RF Skin Tightening - One area (Face, neck or chest)',
    RFSkinTighteningTwoAreas = 'RF Skin Tightening - Two Areas (Face, neck or chest)',
    RFSkinTighteningThreeAreas = 'RF Skin Tightening - Three Areas (Face, neck and chest)',
    SkinNeedlingEyeAreaOnly = 'Skin Needling - Eye area only',
    SkinNeedlingFullBackOrAbdomen = 'Skin Needling - Full Back or Full Abdomen',
    SkinNeedlingHalfBackOrAbdomen = 'Skin Needling - Half Back or Half Abdomen',
    SingleSurgicalScarUnder10cm = 'Single Surgical Scar under 10cm in length',
    SurgicalScarOver10cm = 'Surgical Scar over 10cm in length',
}
export const AntiAgingScaringTreatmentsServicesSchema = runtypeFromEnum(AntiAgingScaringTreatmentsServices);

export enum FocusDualServices {
    FractionatedRFMicroneedlingFullFaceOrNeck45 = 'Fractionated RF Microneedling Full Face or Full neck (45 Minutes)',
    FractionatedRFMicroneedlingFullFaceAndNeck60 = 'Fractionated RF Microneedling Full face and Full neck (60 Minutes)',
    FractionatedRFMicroneedlingAbdomen = 'Fractionated RF Microneedling Abdomen',
    FractionatedRFMicroneedlingBackOfHands = 'Fractionated RF Microneedling Back of hands',
    FractionatedRFMicroneedlingStretchmarkSmallArea = 'Fractionated RF Microneedling Stretchmark treatment (Small area A5 size)',
    FractionatedRFMicroneedlingStretchmarkLargeArea = 'Fractionated RF Microneedling Stretchmark treatment (Large area +A5 size)',
    HIFULowerFaceUpperNeckLift = 'HIFU Lower Face/upper neck Lift (Jowl)',
    HIFUChinSculpting = 'HIFU Chin Sculpting',
    HIFUCheekLift = 'HIFU Cheek lift (Nasolabial)',
    HIFUFullFace = 'HIFU Full face',
    HIFUFullNeck = 'HIFU Full Neck',
    HIFUFullFaceAndNeck = 'HIFU Full Face + Full Neck',
    HIFUUpperFaceLift = 'HIFU Upper Face Lift (Forehead + Eyes)',
    HIFUBrowLift = 'HIFU Brow Lift',
    HIFUBackOfHands = 'HIFU Back of Hands',
    HIFUAbdomen = 'HIFU Abodomen',
    HollywoodFaceOrNeckLift = 'HOLLYWOOD Face or Neck Lift (RF Microneedling & HIFU)',
    FractionatedRFMicroneedlingFullFaceWithHAMask = 'Fractionated RF Microneedling Full Face with HA mask',
    HIFUHalfAbdomen = 'HIFU half abdomen',
}
export const FocusDualServicesSchema = runtypeFromEnum(FocusDualServices);

export enum DermaplaningTreatmentsServices {
    DermaplaningAlone = 'Dermaplaning (Alone)',
    DermaplaningWithLEDAndHydratingMask = 'Dermaplaning with LED and Hydrating Mask',
    DermaplaningWithChemicalPeel = 'Dermaplaning with Chemical Peel',
    DermaplaningWithIPLHairRemovalToFaceNeck = 'Dermaplaning with IPL Hair Removal to Face/Neck',
}
export const DermaplaningTreatmentsServicesSchema = runtypeFromEnum(DermaplaningTreatmentsServices);

export enum IPLSkinTreatmentsServices {
    BrokenCapillaryRemovalFullFace = 'Broken Capillary Removal (Full Face) (One area)',
    BrokenCapillaryRemovalMediumArea = 'Broken Capillary Removal (Medium area size)',
    BrokenCapillaryRemovalSpotTreatment = 'Broken Capillary Removal (Spot treatment)',
    IPLPigmentRemovalOneArea = 'IPL Pigment Removal (One area) (Choose from the face, neck or chest)',
    IPLPigmentRemovalTwoAreas = 'IPL Pigment Removal two areas (Choose from face, neck or chest)',
    IPLPigmentRemovalThreeAreas = 'IPL Pigment Removal three areas (Face, neck and chest)',
    IPLPigmentRemovalSpotTreatment = 'IPL pigment removal (Spot treatment)',
    IPLPhotoRejuvenationOneArea = 'IPL Photo Rejuvenation - One area (Choose from face, neck or chest)',
    IPLPhotoRejuvenationTwoAreas = 'IPL Photo Rejuvenation - Two Areas (choose from face, neck and chest)',
    IPLPhotoRejuvenationThreeAreas = 'IPL Photo Rejuvenation - Three areas (Face, Neck and Chest)',
    IPLPigmentAndBrokenCapillaryCombinedOneArea = 'IPL Pigment and Broken Capillary (combined one area)',
    PigmentAndRejuvenationCombinedOneArea = 'Pigment & Rejuvenation (combined one area)',
    BrokenCapillaryAndRejuvenationCombinedOneArea = 'Broken Capillary & Rejuvenation (combined one area)',
    IPLRejuvenationPigmentAndBrokenCapillariesCombinedOneArea = 'IPL Rejuvenation, Pigment & Broken Capillaries (combined one area)',
}
export const IPLSkinTreatmentsServicesSchema = runtypeFromEnum(IPLSkinTreatmentsServices);

export enum PigmentationMelasmaTreatmentsServices {
    RetinolPeel = 'Retinol Peel',
    IPLPigmentRemovalHalfArms = 'IPL Pigment Removal - Half Arms',
    IPLPigmentRemovalFullArms = 'IPL Pigment Removal - Full Arms',
    IPLPigmentRemovalUpperBack = 'IPL Pigment Removal - Upper Back',
    IPLPigmentRemovalBackOfHands = 'IPL Pigment Removal - Back of Hands',
    IPLPigmentRemovalShoulders = 'IPL Pigment Removal - Shoulders',
    DermafracLighteningSerum = 'Dermaroller - Lightening serum',
    RetinolPeelWithGreenLEDAddOn = 'Retinol Peel with Green LED add on',
}
export const PigmentationMelasmaTreatmentsSchema = runtypeFromEnum(PigmentationMelasmaTreatmentsServices);

export enum VascularRednessRosaceaSensitiveSkinTreatmentsServices {
    BarrierRepairingHydratingLEDFaceAndNeckFaceOnly = 'Barrier Repairing Hydrating LED - Face only',
    BarrierRepairingHydratingLEDFaceAndNeck = 'Barrier repairing hydrating LED - Face and Neck',
    AcneRosaceaLEDAndPeelTreatment = 'Acne/Rosacea LED and Peel treatment',
    LEDAlone = 'LED (alone)',
    LumixaLED = 'Lumixa LED',
}
export const VascularRednessRosaceaSensitiveSkinTreatmentsSchema = runtypeFromEnum(
    VascularRednessRosaceaSensitiveSkinTreatmentsServices,
);

export enum SkinTagMoleRemovalServices {
    LesionRemovalSingleLesion = 'Lesion removal (Single Lesion)',
}
export const SkinTagMoleRemovalServicesSchema = runtypeFromEnum(SkinTagMoleRemovalServices);

export enum BodyFatReductionTreatmentsServices {
    BodyFatRemovalFullAbdomen = 'Body Fat Removal - Full Abdomen',
    BodyFatRemovalFullAbdomenAndHips = 'Body Fat Removal - Full Abdomen and Hips/Love Handles',
    BodyFatRemovalHipsOnly = 'Body Fat Removal - Hips/Love Handles Only',
    BodyFatRemovalBackFat = 'Body Fat Removal - Back Fat (Upper or Lower back)',
    BodyFatRemovalUpperArms = 'Body Fat Removal - Upper Arms',
    BodyFatRemovalFullThighs = 'Body Fat Removal - Full Thighs (Inner and Outer)',
    BodyFatRemovalHalfThighs = 'Body Fat Removal - 1/2 Thighs (either inner or outer)',
    BodyFatRemovalKnees = 'Body Fat Removal - Knees',
    BBL = 'BBL (Brazilian Butt Lift)',
    CelluliteReductionTreatment = 'Cellulite Reduction Treatment',
    MummyMakeoverRFAbdomenOnly = 'Mummy Makeover RF/Fat Cav abdomen only',
    MummyMakeoverRFAbdomenAndSides = 'Mummy Makeover RF/Fat Cav abdomen abdomen and sides',
}
export const BodyFatReductionTreatmentsServicesSchema = runtypeFromEnum(BodyFatReductionTreatmentsServices);

export enum BodySkinTighteningTreatmentsServices {
    RFSkinTighteningFullAbdomen = 'RF Skin Tightening - Full Abdomen',
    RFSkinTighteningBellyButtonRegion = 'RF Skin Tightening - Belly Button Region Only',
    RFSkinTighteningInnerThighs = 'RF Skin Tightening - Inner Thighs',
    RFSkinTighteningKnees = 'RF Skin Tightening - Knees',
    RFSkinTighteningInnerArms = 'RF Skin Tightening - Inner Arms',
    RFSkinTighteningBottom = 'RF Skin Tightening Bottom',
    PlasmaSkinTighteningFullNeck = 'Plasma Skin Tightening - Full Neck',
    PlasmaSkinTighteningHalfCentreNeck = 'Plasma Skin Tightening - 1/2 Centre Neck / Double Chin Lift',
    PlasmaSkinTighteningBackOfHands = 'Plasma Skin Tightening - Back of Hands',
    PlasmaSkinTighteningBellyButton = 'Plasma Skin Tightening - Belly Button',
    PlasmaSkinTighteningInnerArms = 'Plasma Skin Tightening - Inner arms',
    IPLPhotoRejuvenationBackOfHands = 'IPL Photo Rejuvenation - Back of Hands',
    SkinNeedlingFullAbdomenTightening = 'Skin Needling - Full Abdomen Tightening',
    SkinNeedlingHalfAbdomenTightening = 'Skin Needling - Half Abdomen Tightening',
    PlasmaSkinTighteningAbdomen = 'Plasma Skin Tightening - Abdomen (medium sized area)',
}
export const BodySkinTighteningTreatmentsServicesSchema = runtypeFromEnum(BodySkinTighteningTreatmentsServices);

export enum IntimateLighteningTreatmentsServices {
    UnderarmLightening = 'Underarm Lightening',
    AreolaLightening = 'Areola',
    BikiniLightening = 'Bikini Lightening',
    BrazilianLighteningAndTightening = 'Brazilian Lightening and Tightening',
    AnusLightening = 'Anus Lightening',
    KneeLightening = 'Knee Lightening',
    FullBottomLightening = 'Full Bottom Lightening',
    ElbowLightening = 'Elbow Lightening',
    NeckOneArea = 'Neck (one area of front or back only)',
    NeckLighteningFullNeck = 'Neck Lightening (full neck)',
    MouthAreaLightening = 'Mouth Area Lightening',
    UnderarmLighteningWithLED = 'Underarm lightening + LED (green)',
    AreolaLighteningWithLED = 'Areola lightening + LED (green)',
    BikiniLighteningWithLED = 'Bikini Lightening + LED (green)',
    BrazilianLighteningAndTighteningWithLED = 'Brazilian Lightening and Tightening + LED',
    AnusLighteningWithLED = 'Anus Lightening + LED (green)',
    KneeLighteningWithLED = 'Knee lightening + LED',
    FullBottomLighteningWithLED = 'Full Bottom Lightening + LED',
    ElbowLighteningWithLED = 'Elbow lightening + LED',
    NeckOneAreaWithLED = 'Neck (one area of front or back only) + LED',
    NeckLighteningFullNeckWithLED = 'Neck Lightening (full neck) + LED',
    MouthAreaLighteningWithLED = 'Mouth Area Lightening + LED',
}
export const IntimateLighteningTreatmentsServicesSchema = runtypeFromEnum(IntimateLighteningTreatmentsServices);

export enum ScalpHairGrowthTreatmentsServices {
    ScalpHairGrowthTreatmentHeadBaldPatch = 'Scalp Hair Growth Treatment - 1/4 head (Bald Patch)',
    ScalpHairGrowthTreatmentHeadTopOfScalpOnly = 'Scalp Hair Growth Treatment - 1/2 Head (Top of scalp only)',
    ScalpHairGrowthTreatmentFullHead = 'Scalp Hair Growth Treatment - Full Head',
}
export const ScalpHairGrowthTreatmentsServicesSchema = runtypeFromEnum(ScalpHairGrowthTreatmentsServices);

export enum IPLFaceBodyHairRemovalFemaleServices {
    TopLipOnly = 'Top Lip only',
    ChinOnly = 'Chin only',
    SideBurnsOnly = 'Side Burns only',
    LowerHalfFace = 'Lower half face',
    LowerHalfFaceUpperHalfNeck = 'Lower half face + upper half neck',
    HalfLowerFaceFullNeck = 'Half Lower Face + Full Neck',
    FullFace = 'Full Face',
    Underarms = 'Underarms',
    HalfLegUnderKneeOrHalfArm = 'Half Leg (under knee) or Half arm',
    FullLegOrFullArm = 'Full leg or full arm',
    ThreeQuarterLegOverKnee = '3/4 leg (over knee)',
    Brazilian = 'Brazillian',
    Bikini = 'Bikini',
    FullLegAndBikini = 'Full Leg and Bikini',
    FullLegAndBrazilian = 'Full Leg and Brazilian',
    HalfLegAndBikini = 'Half leg and Bikini',
    HalfLegAndBrazilian = 'Half Leg and Brazillian',
    ThreeQuarterLegAndBikini = '3/4 Leg and Bikini',
    ThreeQuarterLegAndBrazilian = '3/4 leg and Brazilian',
    BetweenEyebrows = 'Between eyebrows (monobrow)',
    TopToToeNotIncludingBrazilian = 'Top to Toe (not including Brazilian)',
    TopToToeIncludingBrazilian = 'Top to Toe (including Brazilian)',
    BrazilianAndUnderarms = 'Brazilian & Underarms',
    BikiniAndUnderarms = 'Bikini & Underarms',
    ChestAndFullAbdomen = 'Chest & Full Abdomen',
}
export const IPLFaceBodyHairRemovalFemaleServicesSchema = runtypeFromEnum(IPLFaceBodyHairRemovalFemaleServices);

export enum IPLFaceBodyHairRemovalMaleServices {
    CheeksOnly = 'Cheeks only',
    BackOfNeck = 'Back of Neck',
    SnailTrail = 'Snail Trail',
    Chest = 'Chest',
    ChestAndFullStomach = 'Chest and Full Stomach',
    ChestAndSnailTrail = 'Chest and Snail Trail',
    Shoulders = 'Shoulders',
    HalfBack = 'Half Back',
    FullBack = 'Full Back',
    FullLegOrFullArm = 'Full Leg or Full Arm',
    HalfLegOrHalfArm = 'Half Leg or Half Arm',
    Buttocks = 'Buttocks',
    AnusCrackOnly = 'Anus / Crack only',
    Manzilian = 'Manzilian',
    BetweenEyebrows = 'Between eyebrows (monobrow) for male',
    BeardSculpting = 'Beard Sculpting',
    TopToToeIncludingManzilian = 'Top to Toe (including manzilian)',
    TopToToeNotIncludingManzilian = 'Top to Toe (not including manzilian)',
    IPLBeardShaping = 'IPL Beard shaping (cheeks and neck)',
}
export const IPLFaceBodyHairRemovalMaleServicesSchema = runtypeFromEnum(IPLFaceBodyHairRemovalMaleServices);

export enum ReviewServices {
    SkinReviewObserveScanExpress = 'Skin Review Observe Scan (Express)',
    SkinReviewObserveScanLong = 'Skin Review Observe Scan (Long)',
    CameraPhotosMeasurements = 'Camera Photos/measurements',
}
export const ReviewServicesSchema = runtypeFromEnum(ReviewServices);

// Services type
export const AestheticsAndCosmeticsServicesSchema = AcneAndCongestionTreatmentsServicesSchema.Or(
    FacialTreatmentsServicesSchema,
)
    .Or(ExpressFacialsServicesSchema)
    .Or(AddOnsServicesSchema)
    // .Or(CarbonLaserFacialServicesSchema)
    .Or(AntiAgingScaringTreatmentsServicesSchema)
    .Or(FocusDualServicesSchema)
    .Or(DermaplaningTreatmentsServicesSchema)
    .Or(IPLSkinTreatmentsServicesSchema)
    .Or(PigmentationMelasmaTreatmentsSchema)
    .Or(VascularRednessRosaceaSensitiveSkinTreatmentsSchema)
    .Or(SkinTagMoleRemovalServicesSchema)
    .Or(BodyFatReductionTreatmentsServicesSchema)
    .Or(BodySkinTighteningTreatmentsServicesSchema)
    .Or(IntimateLighteningTreatmentsServicesSchema)
    .Or(ScalpHairGrowthTreatmentsServicesSchema)
    .Or(IPLFaceBodyHairRemovalFemaleServicesSchema)
    .Or(IPLFaceBodyHairRemovalMaleServicesSchema)
    .Or(ReviewServicesSchema);
export type AestheticsAndCosmeticsServices = r.Static<typeof AestheticsAndCosmeticsServicesSchema>;
