/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { MedicalProfileResourceSchema } from './medical/profile';
import {
    MedicalCommunicationHistorySchema,
    MedicalRequestFlagResourceSchema,
    MedicalRequestNoteResourceSchema,
    MedicalRequestResourceSchema,
    PatientPrescriptionDocumentSchema,
} from './medical/request';
import {
    ConsultationStatus,
    InvoicePaymentStatusSchema,
    MedicalCertificateClassificationSchema,
    MedicalRequestSeverityLevelSchema,
    MedicalRequestStatusSchema,
    MedicalRequestTypeSchema,
    SpecialistSchema,
} from '../constants';
import {
    CreatedAtTimestampSchema,
    DateSchema,
    DigitalConsultationSchema,
    EmailAddressSchema,
    IdEntitySchema,
    ConsultationNotificationSchema,
    URLSchema,
} from '.';
import { maybe } from '../utils/runtypes';
import { validateConsultationNote, validateMedicalCertificateDateString } from '../validator';
import { ConsultationFileResourceSchema } from './file';

const InfirmaryRequestServiceSchema = r.Record({
    title: r.String,
    category: r.String,
    created_at: DateSchema,
    consultations: r.Array(DigitalConsultationSchema),
});

const InfirmaryDoctorSchema = r.Record({
    id: r.String,
    name: r.String,
    doctor_pickup_time: DateSchema,
});

export const InfirmaryConsultationRedirectSchema = r.Record({
    title: r.String,
    category: r.String,
    request_id: r.String,
    picked_at: DateSchema,
    patient: MedicalProfileResourceSchema,
});
export type InfirmaryConsultationRedirect = r.Static<typeof InfirmaryConsultationRedirectSchema>;

export const InfirmaryConsultationSchema = r.Record({
    profile: MedicalProfileResourceSchema,
    request: MedicalRequestResourceSchema,
    email: EmailAddressSchema,
    payment_amount: r.String,
    payment_currency: r.String,
    patient_id: r.String,
    payment_status: InvoicePaymentStatusSchema,
});
export type InfirmaryConsultation = r.Static<typeof InfirmaryConsultationSchema>;

export const InfirmaryTakeConsultationResponseSchema = InfirmaryConsultationSchema.Or(
    InfirmaryConsultationRedirectSchema,
);
export type InfirmaryTakeConsultationResponse = r.Static<typeof InfirmaryTakeConsultationResponseSchema>;

export const InfirmaryRequestSchema = r
    .Record({
        client_id: r.String,
        request_reason: r.String,
        email: EmailAddressSchema,
        scheduled_for: maybe(DateSchema),
        has_wrong_phone_number: r.Boolean,
        status: MedicalRequestStatusSchema,
        doctor: maybe(InfirmaryDoctorSchema),
        patient: MedicalProfileResourceSchema,
        request_type: MedicalRequestTypeSchema,
        service: InfirmaryRequestServiceSchema,
        flags: r.Array(MedicalRequestFlagResourceSchema),
        notes: r.Array(MedicalRequestNoteResourceSchema),
        prescriptions: r.Array(PatientPrescriptionDocumentSchema),
        communication_history: r.Array(MedicalCommunicationHistorySchema),
        documents: r.Array(ConsultationFileResourceSchema),
        consultation_notifications: r.Array(ConsultationNotificationSchema),
    })
    .extend(IdEntitySchema.fields)
    .extend(CreatedAtTimestampSchema.fields);
export type InfirmaryRequest = r.Static<typeof InfirmaryRequestSchema>;

export const GetInfirmaryHistoryResponseSchema = r.Array(InfirmaryRequestSchema);

export const InfirmaryPendingRequestSchema = r
    .Record({
        title: r.String,
        patient: MedicalProfileResourceSchema,
    })
    .extend(IdEntitySchema.fields);
export type InfirmaryPendingRequest = r.Static<typeof InfirmaryPendingRequestSchema>;

export const GetInfirmaryPendingRequestSchema = r.Record({
    request: maybe(InfirmaryPendingRequestSchema),
});
export type GetInfirmaryPendingRequest = r.Static<typeof GetInfirmaryPendingRequestSchema>;

export const InfirmaryStatisticsSchema = r.Record({
    total: r.Number,
    waiting: r.Number,
});
export type InfirmaryStatistics = r.Static<typeof InfirmaryStatisticsSchema>;

export const GetInfirmaryRecordsResponseSchema = r.Record({
    requests: r.Array(InfirmaryRequestSchema),
    statistics: InfirmaryStatisticsSchema,
});
export type GetInfirmaryRecordsResponse = r.Static<typeof GetInfirmaryRecordsResponseSchema>;

export const CancelConsultationRequestSchema = r.Record({
    severity: MedicalRequestSeverityLevelSchema,
    type: r
        .Literal(ConsultationStatus.CancellingWithNote)
        .Or(r.Literal(ConsultationStatus.NoAnswer))
        .Or(r.Literal(ConsultationStatus.CancellingNoNote))
        .Or(r.Literal(ConsultationStatus.WrongPhoneNumber)),
    reason: r.Optional(r.String.withConstraint(validateConsultationNote)),
});
export type CancelConsultationRequest = r.Static<typeof CancelConsultationRequestSchema>;

export const RejectConsultationSchema = r.Record({
    rejectWithRefund: r.Boolean,
    note: r.String.withConstraint(validateConsultationNote),
});
export type RejectConsultation = r.Static<typeof RejectConsultationSchema>;

export const ConsultationScriptResponseSchema = r.Record({
    link: URLSchema,
});
export type ConsultationScriptResponse = r.Static<typeof ConsultationScriptResponseSchema>;

export const PathologyTestConsultationRequestSchema = r.Record({
    clinical_notes: r.String,
    turnover_days: r.Number,
});
export type PathologyTestConsultationRequest = r.Static<typeof PathologyTestConsultationRequestSchema>;

export const MedicalImagingOrRadiologyConsultationRequestSchema = r.Record({
    clinical_notes: r.String,
    turnover_days: r.Number,
});
export type MedicalImagingOrRadiologyConsultationRequest = r.Static<
    typeof MedicalImagingOrRadiologyConsultationRequestSchema
>;

export const SleepStudyRequestConsultationSchema = r.Record({
    clinical_history: r.String,
});
export type SleepStudyRequestConsultation = r.Static<typeof SleepStudyRequestConsultationSchema>;

export const MentalHealthCareConsultationRequestSchema = r.Record({
    diagnosis: r.String,
    crisis_relapse_plan: r.String,
    recommended_treatment: r.String,
    text_referral: r.String,
    current_concerns: r.String,
    relevant_medications: r.String,
    family_mental_illness_history: r.String,
    treatment_history: r.String,
    personal_history: r.String,
    k10_other_outcome: r.String,
    mental_state_examination: r.String,
    risk_assessment: r.String,
    have_provided_psychological_education: r.Boolean,
    date_plan_completed: DateSchema,
    review_date: DateSchema,
    psychologist_name: maybe(r.String),
    psychologist_email: maybe(EmailAddressSchema),
});
export type MentalHealthCareConsultationRequest = r.Static<typeof MentalHealthCareConsultationRequestSchema>;

export const DoctorLetterConsultationRequestSchema = r.Record({
    message: r.String,
});
export type DoctorLetterConsultationRequest = r.Static<typeof DoctorLetterConsultationRequestSchema>;

export const SpecialistReferralConsultationRequestSchema = r.Record({
    condition: r.String,
    doctor_name: r.String.optional(),
    specialist_type: SpecialistSchema,
    doctor_email: EmailAddressSchema.optional(),
});
export type SpecialistReferralConsultationRequest = r.Static<typeof SpecialistReferralConsultationRequestSchema>;

export const MedicalCertificateConsultationRequestSchema = r.Record({
    end_date: r.String.withConstraint(validateMedicalCertificateDateString),
    start_date: r.String.withConstraint(validateMedicalCertificateDateString),
    classification: MedicalCertificateClassificationSchema,
});
export type MedicalCertificateConsultationRequest = r.Static<typeof MedicalCertificateConsultationRequestSchema>;

export const PrescribeHairConsultationRequestSchema = r.Record({
    medication_instructions: r.String,
});
export type PrescribeHairConsultationRequest = r.Static<typeof PrescribeHairConsultationRequestSchema>;

export const FinalizeConsultationRequestSchema = r.Record({
    note: r.String,
});
export type FinalizeConsultationRequest = r.Static<typeof FinalizeConsultationRequestSchema>;
