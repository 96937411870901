/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { cloneDeep } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { ConsultationStatus, ConsultationUploadCategory, ResourceSessionStatus } from '../../../../api/constants';
import { InfirmaryConsultationSchema, InfirmaryTakeConsultationResponse } from '../../../../api/types/infirmary';
import { PrescriptionDrug } from '../../../../api/types/prescription/escript';
import {
    cancelConsultationRequestThunk,
    changeConsultationPatientMedicalProfileThunk,
    createMedicalRequestNoteThunk,
    finalizeConsultationRequestThunk,
    getHistoryConsultationRequestThunk,
    getPatientMedicalProfilesThunk,
    sendConsultationDoctorLetterThunk,
    sendConsultationMedicalCertificateThunk,
    sendConsultationMedicalImagingOrRadiologyThunk,
    sendConsultationMentalHealthCareThunk,
    sendConsultationPathologyTestThunk,
    sendConsultationSleepStudyThunk,
    sendConsultationSpecialistReferralThunk,
    sendPatientConsultationNotificationThunk,
    takeConsultationRequestThunk,
    updatePatientMedicalProfileThunk,
    uploadConsultationDocumentsThunk,
    uploadConsultationDocumentsViewStateThunk,
} from './thunk';
import { MedicalProfileInfirmary } from '../../../../api/types/medical/profile';

interface ConsultationManagementResource {
    resource?: string;
    isSendingResource: boolean;
}

interface ConsultationManagementState {
    doctorLetter: ConsultationManagementResource;
    pathologyTest: ConsultationManagementResource;
    medicalCertificate: ConsultationManagementResource;
    medicalImagingOrRadiology: ConsultationManagementResource;
    sleepStudy: ConsultationManagementResource;
    mentalHealthCare: ConsultationManagementResource;
    specialistReferral: ConsultationManagementResource;
    hairConsultation: ConsultationManagementResource;
}

interface IConsultationState {
    status?: ConsultationStatus;
    medication: PrescriptionDrug[];
    isFetchingConsultation: boolean;
    isSavingConsultationNote: boolean;
    isSendingPatientNotification: boolean;
    patientMedicalProfile: ResourceSessionStatus;
    requests: Record<string, ResourceSessionStatus>;
    currentRequest?: InfirmaryTakeConsultationResponse;
    consultationManagement: ConsultationManagementState;
    documentsUploadEvent: Record<ConsultationUploadCategory, boolean>;
    // Patient Medical Profiles.
    isLoadingMedicalProfiles: boolean;
    patientMedicalProfiles: MedicalProfileInfirmary[];
    changeConsultationMedicalProfile: ResourceSessionStatus;
}

const consultationManagementInitialState: ConsultationManagementState = {
    medicalCertificate: {
        isSendingResource: false,
    },
    doctorLetter: {
        isSendingResource: false,
    },
    hairConsultation: {
        isSendingResource: false,
    },
    medicalImagingOrRadiology: {
        isSendingResource: false,
    },
    mentalHealthCare: {
        isSendingResource: false,
    },
    pathologyTest: {
        isSendingResource: false,
    },
    sleepStudy: {
        isSendingResource: false,
    },
    specialistReferral: {
        isSendingResource: false,
    },
};

const initialState: IConsultationState = {
    requests: {},
    medication: [],
    patientMedicalProfiles: [],
    documentsUploadEvent: {
        'Specialist Correspondent Or Letter': false,
        'General': false,
        'Patient': false,
        'Results': false,
    },
    isFetchingConsultation: false,
    isSavingConsultationNote: false,
    isLoadingMedicalProfiles: false,
    isSendingPatientNotification: false,
    patientMedicalProfile: ResourceSessionStatus.PENDING,
    changeConsultationMedicalProfile: ResourceSessionStatus.PENDING,
    consultationManagement: cloneDeep(consultationManagementInitialState),
};

const consultationSlice = createSlice({
    name: 'consultation',
    initialState,
    reducers: {
        setConsultationProfileChangeState(draft) {
            draft.patientMedicalProfile = ResourceSessionStatus.PENDING;
        },
    },
    extraReducers(reducers) {
        reducers
            .addCase(takeConsultationRequestThunk.pending, (draft) => {
                delete draft.status;
                delete draft.currentRequest;
                draft.consultationManagement = cloneDeep(consultationManagementInitialState);
                draft.patientMedicalProfile = ResourceSessionStatus.PENDING;
                draft.isFetchingConsultation = true;
            })
            .addCase(takeConsultationRequestThunk.fulfilled, (draft, { payload }) => {
                draft.currentRequest = payload;
                draft.isFetchingConsultation = false;
                draft.consultationManagement = cloneDeep(consultationManagementInitialState);
            })
            .addCase(getHistoryConsultationRequestThunk.pending, (draft) => {
                delete draft.status;
                delete draft.currentRequest;
                draft.consultationManagement = cloneDeep(consultationManagementInitialState);
                draft.patientMedicalProfile = ResourceSessionStatus.PENDING;
                draft.isFetchingConsultation = true;
            })
            .addCase(getHistoryConsultationRequestThunk.fulfilled, (draft, { payload }) => {
                draft.currentRequest = payload;
                draft.isFetchingConsultation = false;
                draft.consultationManagement = cloneDeep(consultationManagementInitialState);
            })
            .addCase(finalizeConsultationRequestThunk.pending, (draft) => {
                draft.status = ConsultationStatus.Finalizing;
            })
            .addCase(finalizeConsultationRequestThunk.fulfilled, (draft) => {
                delete draft.status;
                delete draft.currentRequest;
                draft.consultationManagement = cloneDeep(consultationManagementInitialState);
            })
            .addCase(finalizeConsultationRequestThunk.rejected, (draft) => {
                delete draft.status;
            })
            .addCase(cancelConsultationRequestThunk.pending, (draft, { meta }) => {
                draft.status = meta.arg.request.type;
            })
            .addCase(cancelConsultationRequestThunk.fulfilled, (draft) => {
                delete draft.status;
                delete draft.currentRequest;
                draft.consultationManagement = cloneDeep(consultationManagementInitialState);
            })
            .addCase(updatePatientMedicalProfileThunk.pending, (draft) => {
                draft.patientMedicalProfile = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(updatePatientMedicalProfileThunk.fulfilled, (draft, { payload }) => {
                draft.patientMedicalProfile = ResourceSessionStatus.COMPLETED;

                if (draft.currentRequest == null || !InfirmaryConsultationSchema.guard(draft.currentRequest)) {
                    return;
                }

                console.log('Update payload: ', payload);

                draft.currentRequest.profile = payload;
            })
            .addCase(updatePatientMedicalProfileThunk.rejected, (draft) => {
                draft.patientMedicalProfile = ResourceSessionStatus.PENDING;
            })
            // Medical certificate consultation thunk
            .addCase(sendConsultationMedicalCertificateThunk.pending, (draft) => {
                draft.consultationManagement.medicalCertificate.isSendingResource = true;
            })
            .addCase(sendConsultationMedicalCertificateThunk.fulfilled, (draft, { payload }) => {
                draft.consultationManagement.medicalCertificate.isSendingResource = false;
                draft.consultationManagement.medicalCertificate.resource = payload.link;
            })
            .addCase(sendConsultationMedicalCertificateThunk.rejected, (draft) => {
                draft.consultationManagement.medicalCertificate.isSendingResource = false;
            })
            // Doctor letter consultation thunk
            .addCase(sendConsultationDoctorLetterThunk.pending, (draft) => {
                draft.consultationManagement.doctorLetter.isSendingResource = true;
            })
            .addCase(sendConsultationDoctorLetterThunk.fulfilled, (draft, { payload }) => {
                draft.consultationManagement.doctorLetter.isSendingResource = false;
                draft.consultationManagement.doctorLetter.resource = payload.link;
            })
            .addCase(sendConsultationDoctorLetterThunk.rejected, (draft) => {
                draft.consultationManagement.doctorLetter.isSendingResource = false;
            })
            // Pathology test consultation thunk
            .addCase(sendConsultationPathologyTestThunk.pending, (draft) => {
                draft.consultationManagement.pathologyTest.isSendingResource = true;
            })
            .addCase(sendConsultationPathologyTestThunk.fulfilled, (draft, { payload }) => {
                draft.consultationManagement.pathologyTest.isSendingResource = false;
                draft.consultationManagement.pathologyTest.resource = payload.link;
            })
            .addCase(sendConsultationPathologyTestThunk.rejected, (draft) => {
                draft.consultationManagement.pathologyTest.isSendingResource = false;
            })
            // Sleep study consultation thunk
            .addCase(sendConsultationSleepStudyThunk.pending, (draft) => {
                draft.consultationManagement.sleepStudy.isSendingResource = true;
            })
            .addCase(sendConsultationSleepStudyThunk.fulfilled, (draft, { payload }) => {
                draft.consultationManagement.sleepStudy.isSendingResource = false;
                draft.consultationManagement.sleepStudy.resource = payload.link;
            })
            .addCase(sendConsultationSleepStudyThunk.rejected, (draft) => {
                draft.consultationManagement.sleepStudy.isSendingResource = false;
            })
            // Mental health consultation thunk
            .addCase(sendConsultationMentalHealthCareThunk.pending, (draft) => {
                draft.consultationManagement.mentalHealthCare.isSendingResource = true;
            })
            .addCase(sendConsultationMentalHealthCareThunk.fulfilled, (draft, { payload }) => {
                draft.consultationManagement.mentalHealthCare.isSendingResource = false;
                draft.consultationManagement.mentalHealthCare.resource = payload.link;
            })
            .addCase(sendConsultationMentalHealthCareThunk.rejected, (draft) => {
                draft.consultationManagement.mentalHealthCare.isSendingResource = false;
            })
            // Specialist referral consultation thunk
            .addCase(sendConsultationSpecialistReferralThunk.pending, (draft) => {
                draft.consultationManagement.specialistReferral.isSendingResource = true;
            })
            .addCase(sendConsultationSpecialistReferralThunk.fulfilled, (draft, { payload }) => {
                draft.consultationManagement.specialistReferral.isSendingResource = false;
                draft.consultationManagement.specialistReferral.resource = payload.link;
            })
            .addCase(sendConsultationSpecialistReferralThunk.rejected, (draft) => {
                draft.consultationManagement.specialistReferral.isSendingResource = false;
            })
            // Medical imaging or radiology consultation thunk
            .addCase(sendConsultationMedicalImagingOrRadiologyThunk.pending, (draft) => {
                draft.consultationManagement.medicalImagingOrRadiology.isSendingResource = true;
            })
            .addCase(sendConsultationMedicalImagingOrRadiologyThunk.fulfilled, (draft, { payload }) => {
                draft.consultationManagement.medicalImagingOrRadiology.isSendingResource = false;
                draft.consultationManagement.medicalImagingOrRadiology.resource = payload.link;
            })
            .addCase(sendConsultationMedicalImagingOrRadiologyThunk.rejected, (draft) => {
                draft.consultationManagement.medicalImagingOrRadiology.isSendingResource = false;
            })
            // Consultation clinical notes thunk
            .addCase(createMedicalRequestNoteThunk.pending, (draft) => {
                draft.isSavingConsultationNote = true;
            })
            .addCase(createMedicalRequestNoteThunk.fulfilled, (draft, { payload }) => {
                draft.isSavingConsultationNote = false;
                if (InfirmaryConsultationSchema.guard(draft.currentRequest)) {
                    draft.currentRequest.request.notes.push(payload);
                }
            })
            .addCase(createMedicalRequestNoteThunk.rejected, (draft) => {
                draft.isSavingConsultationNote = false;
            })
            // Documents upload handlers
            .addCase(uploadConsultationDocumentsThunk.pending, (draft, { meta }) => {
                draft.documentsUploadEvent[meta.arg.category] = true;
            })
            .addCase(uploadConsultationDocumentsThunk.fulfilled, (draft, { payload, meta }) => {
                draft.documentsUploadEvent[meta.arg.category] = false;
                if (InfirmaryConsultationSchema.guard(draft.currentRequest)) {
                    draft.currentRequest.request.files = draft.currentRequest.request.files.concat(payload);
                }
            })
            .addCase(uploadConsultationDocumentsThunk.rejected, (draft, { meta }) => {
                draft.documentsUploadEvent[meta.arg.category] = false;
            })
            // Documents view state update handlers
            .addCase(uploadConsultationDocumentsViewStateThunk.pending, (draft, { meta }) => {
                draft.requests[meta.arg] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(uploadConsultationDocumentsViewStateThunk.fulfilled, (draft, { payload, meta }) => {
                if (InfirmaryConsultationSchema.guard(draft.currentRequest)) {
                    const consultationIndex = draft.currentRequest.request.files.findIndex(
                        ({ id }) => id === payload.id,
                    );
                    draft.currentRequest.request.files[consultationIndex] = payload;
                }

                delete draft.requests[meta.arg];
            })
            .addCase(uploadConsultationDocumentsViewStateThunk.rejected, (draft, { meta }) => {
                delete draft.requests[meta.arg];
            })
            // Patient consultation notification state update handlers
            .addCase(sendPatientConsultationNotificationThunk.pending, (draft) => {
                draft.isSendingPatientNotification = true;
            })
            .addCase(sendPatientConsultationNotificationThunk.fulfilled, (draft, { payload }) => {
                if (InfirmaryConsultationSchema.guard(draft.currentRequest)) {
                    draft.currentRequest.request.consultation_notifications.push(payload);
                }

                draft.isSendingPatientNotification = false;
            })
            .addCase(sendPatientConsultationNotificationThunk.rejected, (draft) => {
                draft.isSendingPatientNotification = false;
            })
            // Patient medical profile state management.
            .addCase(getPatientMedicalProfilesThunk.pending, (draft) => {
                draft.isLoadingMedicalProfiles = true;
            })
            .addCase(getPatientMedicalProfilesThunk.fulfilled, (draft, { payload }) => {
                draft.patientMedicalProfiles = payload;
                draft.isLoadingMedicalProfiles = false;
            })
            .addCase(getPatientMedicalProfilesThunk.rejected, (draft) => {
                draft.isLoadingMedicalProfiles = false;
            })
            // Consultation patient change state management.
            .addCase(changeConsultationPatientMedicalProfileThunk.pending, (draft) => {
                draft.changeConsultationMedicalProfile = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(changeConsultationPatientMedicalProfileThunk.fulfilled, (draft, { payload }) => {
                if (InfirmaryConsultationSchema.guard(draft.currentRequest)) {
                    draft.currentRequest.profile = payload;
                }
                draft.changeConsultationMedicalProfile = ResourceSessionStatus.COMPLETED;
            })
            .addCase(changeConsultationPatientMedicalProfileThunk.rejected, (draft) => {
                draft.changeConsultationMedicalProfile = ResourceSessionStatus.PENDING;
            });
    },
});

export const consultationReducer = consultationSlice.reducer;

export const { setConsultationProfileChangeState } = consultationSlice.actions;
