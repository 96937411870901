/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { ArticleCategoriesSchema } from '../constants';
import { CreatedAtTimestampSchema, IdEntitySchema, URLSchema } from '.';
import { maybe } from '../utils/runtypes';

export const ArticleSchema = r.Record({
    title: r.String,
    story: r.String,
    description: r.String,
    publication: r.Boolean,
    read_minutes: r.Number,
    category: ArticleCategoriesSchema,
});
export type Article = r.Static<typeof ArticleSchema>;

export const CreateArticleSchema = r
    .Record({
        thumbnail_reference_id: r.String,
    })
    .extend(ArticleSchema.fields);
export type CreateArticle = r.Static<typeof CreateArticleSchema>;

export const UpdateArticleSchema = r
    .Record({
        thumbnail_reference_id: maybe(r.String),
    })
    .extend(ArticleSchema.fields);
export type UpdateArticle = r.Static<typeof UpdateArticleSchema>;

export const ArticleResourceSchema = r
    .Record({
        slug: r.String,
        thumbnail: URLSchema,
    })
    .extend(ArticleSchema.omit('publication').fields)
    .extend(CreatedAtTimestampSchema.fields);
export type ArticleResource = r.Static<typeof ArticleResourceSchema>;

export const GetArticleResponseSchema = maybe(ArticleResourceSchema);

export const ArticleListingSchema = ArticleResourceSchema.omit('story');
export type ArticleListing = r.Static<typeof ArticleListingSchema>;

export const GetArticlesSchema = r.Array(ArticleListingSchema);

export const ArticleDashboardResourceSchema = r
    .Record({
        publication: r.Boolean,
        thumbnail_key: r.String,
    })
    .extend(IdEntitySchema.fields)
    .extend(ArticleResourceSchema.fields);
export type ArticleDashboardResource = r.Static<typeof ArticleDashboardResourceSchema>;

export const GetDashboardArticlesSchema = r.Array(ArticleDashboardResourceSchema);
