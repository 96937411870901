/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { URLSchema } from '../types';
import { ReferencedFilesResponseSchema } from '../types/file';
import { EndpointSync, HttpMethod } from '../utils/endpointSync';

export const UploadAvatarEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/avatar',
    responseValidator: URLSchema,
});

export const DeleteAvatarEndpoint = new EndpointSync({
    method: HttpMethod.DELETE,
    path: '/avatar',
});

export const UploadReferencedFileEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/reference/resource',
    responseValidator: ReferencedFilesResponseSchema,
});

export const UploadInfirmaryReferencedFileEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/infirmary/reference/resource',
    responseValidator: ReferencedFilesResponseSchema,
});
