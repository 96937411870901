/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { DigitalConsultationSchema, MedicalProfilePractitionerSchema, PhoneNumberSchema } from '.';
import { ServiceRequestType } from '../constants/checkout';
import { MedicalHealthServiceSchema } from '../constants';
import { maybe } from '../utils/runtypes';

const MentalHealthConsultationDetailsSchema = r.Record({
    k10: r.Number,
    phone: PhoneNumberSchema,
    sendResultsToRegularDoctor: r.Boolean,
    regular_gp_practitioner: maybe(MedicalProfilePractitionerSchema),
    regular_psychologist_practitioner: maybe(MedicalProfilePractitionerSchema),
});

export const MentalHealthRequestSchema = r.Record({
    title: MedicalHealthServiceSchema,
    consultations: r.Array(DigitalConsultationSchema),
    category: r.Literal(ServiceRequestType.MentalHealth),
    consultationDetails: MentalHealthConsultationDetailsSchema,
});
export type MentalHealthRequest = r.Static<typeof MentalHealthRequestSchema>;
