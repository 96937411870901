/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    Article,
    ArticleDashboardResource,
    CreateArticle,
    CreateArticleSchema,
    UpdateArticle,
} from '../../../../api/types/articles';
import {
    createArticle,
    deleteArticle,
    getDashboardArticles,
    updateArticle,
    updateArticlePublication,
} from '../../fetch/article';
import { captureMessage } from '@sentry/react';
import { push } from 'redux-first-history';
import { COMMON_RESTFUL_ERROR_MESSAGE } from '../../../../api/constants';
import { SnackbarTypes } from '../../../../common/types';
import { InfirmaryDashboardRoutes } from '../../routes';
import { handleError, FetchError } from '../../tools/fetch';
import { uploadReferenceFile } from '../../fetch/file';
import { setSnackbarMessage } from '../snackbar';

export const getArticlesThunk = createAsyncThunk<ArticleDashboardResource[]>(
    'articles/resources',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            return await getDashboardArticles();
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                    type: SnackbarTypes.ERROR,
                }),
            );

            captureMessage(`[ARTICLES INFIRMARY] Failed to fetch articles from the server. Error: ${error}`);
            return rejectWithValue(response?.status);
        }
    },
);

export const createArticleThunk = createAsyncThunk<
    ArticleDashboardResource,
    { body: Article; thumbnail: File; eventId: string }
>('article/create', async ({ body, thumbnail }, { dispatch, rejectWithValue }) => {
    try {
        const thumbnailUpload = await uploadReferenceFile([thumbnail]);

        const articleBody: CreateArticle = {
            ...body,
            thumbnail_reference_id: thumbnailUpload[0],
        };

        console.log('Error: ', CreateArticleSchema.validate(articleBody));

        const record = await createArticle(articleBody);
        dispatch(push(`/${InfirmaryDashboardRoutes.Articles}`));

        dispatch(
            setSnackbarMessage({
                message: `"${record.title}" article was created successfully.`,
                type: SnackbarTypes.SUCCESS,
            }),
        );

        return record;
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                type: SnackbarTypes.ERROR,
            }),
        );

        captureMessage(`[ARTICLES INFIRMARY] Failed to create article. Error: ${error}`);
        return rejectWithValue(response?.status);
    }
});

export const updateArticleThunk = createAsyncThunk<
    ArticleDashboardResource,
    { body: Article; articleId: string; thumbnail?: File }
>('article/update', async ({ body, articleId, thumbnail }, { dispatch, rejectWithValue }) => {
    try {
        const articleBody: UpdateArticle = {
            ...body,
        };

        // Upload thumbnail if a new one is uploaded.
        if (thumbnail != null) {
            const thumbnailUpload = await uploadReferenceFile([thumbnail]);
            articleBody.thumbnail_reference_id = thumbnailUpload[0];
        }

        const record = await updateArticle(articleBody, articleId);
        dispatch(push(`/${InfirmaryDashboardRoutes.Articles}`));

        dispatch(
            setSnackbarMessage({
                message: `"${record.title}" article was updated successfully.`,
                type: SnackbarTypes.SUCCESS,
            }),
        );

        return record;
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                type: SnackbarTypes.ERROR,
            }),
        );

        captureMessage(`[ARTICLES INFIRMARY] Failed to update article. Error: ${error}`);
        return rejectWithValue(response?.status);
    }
});

export const updateArticlePublicationThunk = createAsyncThunk<ArticleDashboardResource, string>(
    'article/update/publication',
    async (articleId, { dispatch, rejectWithValue }) => {
        try {
            const record = await updateArticlePublication(articleId);

            dispatch(
                setSnackbarMessage({
                    message: `"${record.title}" article publication was updated successfully.`,
                    type: SnackbarTypes.SUCCESS,
                }),
            );

            return record;
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                    type: SnackbarTypes.ERROR,
                }),
            );

            captureMessage(`[ARTICLES INFIRMARY] Failed to update article publication. Error: ${error}`);
            return rejectWithValue(response?.status);
        }
    },
);

export const deleteArticleThunk = createAsyncThunk<void, { articleId: string; articleTitle: string }>(
    'article/delete',
    async ({ articleId, articleTitle }, { dispatch, rejectWithValue }) => {
        try {
            await deleteArticle(articleId);

            dispatch(
                setSnackbarMessage({
                    message: `"${articleTitle}" article was deleted successfully.`,
                    type: SnackbarTypes.SUCCESS,
                }),
            );
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                    type: SnackbarTypes.ERROR,
                }),
            );

            captureMessage(`[ARTICLES INFIRMARY] Failed to delete article. Error: ${error}`);
            return rejectWithValue(response?.status);
        }
    },
);
