/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SnackbarTypes } from '../../../../common/types';

interface ISnackbarState {
    snackbar?: {
        message: string;
        type: SnackbarTypes;
    };
}

const initialState: ISnackbarState = {};

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        setSnackbarMessage(draft, { payload }: PayloadAction<{ message: string; type: SnackbarTypes }>) {
            draft.snackbar = payload;
        },
        resetSnackbarMessage(draft) {
            delete draft.snackbar;
        },
    },
});

export const snackbarReducer = snackbarSlice.reducer;

export const { resetSnackbarMessage, setSnackbarMessage } = snackbarSlice.actions;
