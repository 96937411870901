/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { FC } from 'react';
import { IconAlertTriangleFilled, IconCircleCheckFilled, IconInfoCircleFilled } from '@tabler/icons-react';
import { SnackbarTypes } from '../../../../../common/types';
import Snackbar from '@mui/material/Snackbar';
import { IconX } from '@tabler/icons-react';
import { StyledSnackbarWrapper, StyledSnackbarIcon, StyledSnackbarButton } from '../../../style';
import { useDispatch, useSelector } from 'react-redux';
import { resetSnackbarMessage } from '../../../redux/snackbar';
import { selectSnackbarEvent } from '../../../redux/snackbar/selector';
import { AppDispatch } from '../../../store';

export const SnackbarRenderer: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const snackbar = useSelector(selectSnackbarEvent);
    const handleCloseSnackbar = () => {
        dispatch(resetSnackbarMessage());
    };

    return (
        <>
            {snackbar && (
                <Snackbar
                    open={snackbar.message != null}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={60000}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <StyledSnackbarWrapper status={snackbar.type}>
                        {snackbar.message && (
                            <>
                                <StyledSnackbarIcon>
                                    {snackbar.type === SnackbarTypes.INFO && <IconInfoCircleFilled />}
                                    {snackbar.type === SnackbarTypes.SUCCESS && <IconCircleCheckFilled />}
                                    {snackbar.type === SnackbarTypes.ERROR && <IconAlertTriangleFilled />}
                                    {snackbar.type === SnackbarTypes.WARNING && <IconAlertTriangleFilled />}
                                </StyledSnackbarIcon>
                                <span>{snackbar.message}</span>
                                <StyledSnackbarButton onClick={handleCloseSnackbar}>
                                    <IconX size={20} />
                                </StyledSnackbarButton>
                            </>
                        )}
                    </StyledSnackbarWrapper>
                </Snackbar>
            )}
        </>
    );
};
