/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

export const API_PATH = '/api';

export const AUTH_MANAGER_PATH = `${API_PATH}/auth`;

// User dashboard endpoint path managers.
export const FILE_MANAGER_PATH = `${API_PATH}/file`;
export const PATIENT_MANAGER_PATH = `${API_PATH}/patient`;
export const ARTICLE_MANAGER_PATH = `${API_PATH}/article`;
export const CHECKOUT_MANAGER_PATH = `${API_PATH}/checkout`;
export const PLATFORM_MANAGER_PATH = `${API_PATH}/platform`;
export const SKINCARE_MANAGER_PATH = `${API_PATH}/skincare`;
export const INFIRMARY_MANAGER_PATH = `${API_PATH}/infirmary`;
export const PRESCRIPTION_MANAGER_PATH = `${API_PATH}/prescription`;
export const MEDICAL_PROFILE_MANAGER_PATH = `${API_PATH}/medical-profile`;
export const MEDICAL_REQUEST_MANAGER_PATH = `${API_PATH}/medical-request`;

// Offshore application endpoint path managers.
export const OPIS_MANAGER_PATH = `${API_PATH}/opis`;
