/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { Component, ErrorInfo, PropsWithChildren, ReactNode } from 'react';
import { ErrorBoundaryContainer, ErrorBoundaryWrapper } from './style';
import { Heading } from '../components/shared/Heading';

interface ErrorBoundaryState {
    error?: Error;
    errorInfo?: ErrorInfo;
}

export class ErrorBoundary extends Component<PropsWithChildren<ErrorBoundaryState>> {
    public state: any = {};

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({ error, errorInfo });
    }

    // TODO: Update error email when the domain is secured.
    render(): ReactNode {
        if (this.state.errorInfo != null) {
            return (
                <ErrorBoundaryContainer>
                    <ErrorBoundaryWrapper>
                        <Heading level={2}>Something went wrong</Heading>
                        <p>
                            This error caused the application to stop working. Please&nbsp;
                            <a href="javascript:void(0)" onClick={() => window.location.reload()}>
                                reload
                            </a>
                            &nbsp;to try again or&nbsp; contact the developer at{' '}
                            <a href={`mailto:support@expressscripts.com?subject=Error report for Aussie Scripts`}>
                                support@expressscripts.com
                            </a>
                            &nbsp;if the error persists.
                        </p>
                    </ErrorBoundaryWrapper>
                </ErrorBoundaryContainer>
            );
        }

        // Normally, just render children
        return this.props.children;
    }
}
