/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../utils/runtypes';

export const FILE_SIZE_LIMIT = 6291456;
export const MULTIPLE_FILES_COUNT_LIMIT = 5;

export const FILE_UPLOAD_REFERENCE = 'EXPRESS_SCRIPTS_FILE_UPLOAD_REFERENCE';

export const imageFileExtension = ['.jpg', '.jpeg', '.png', 'image/jpeg', 'image/png'];
export const docFileExtension = ['.doc', '.docx', '.pdf'];
export const documentsMimeTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const validFileExtension = imageFileExtension.concat(docFileExtension);

export enum UploadFileType {
    Image = 'Image',
    Document = 'Document',
}
export const UploadFileTypeSchema = runtypeFromEnum(UploadFileType);

export enum ConsultationUploadCategory {
    Results = 'Results',
    General = 'General',
    Patient = 'Patient',
    SpecialistCorrespondentOrLetter = 'Specialist Correspondent Or Letter',
}
export const ConsultationUploadCategorySchema = runtypeFromEnum(ConsultationUploadCategory);

export interface ServiceRequestUpload {
    file: File;
    filename: string;
}
