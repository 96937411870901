/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { SKINCARE_MANAGER_PATH } from '../../../api/endpoints';
import {
    CancelSkincareAppointmentEndpoint,
    CreateSkincareAppointmentNoteEndpoint,
    FinalizeSkincareAppointmentEndpoint,
    GetSkincareAppointmentsEndpoint,
    RescheduledSkincareAppointmentEndpoint,
    SendSkincarePaymentRequestEndpoint,
} from '../../../api/endpoints/skincare';
import { MedicalRequestNote } from '../../../api/types/medical/request';
import { RescheduleSkincareAppointment } from '../../../api/types/skincare';
import { endpointFetch } from '../tools/fetch';

export function getSkincareAppointments() {
    return endpointFetch(GetSkincareAppointmentsEndpoint, { scope: SKINCARE_MANAGER_PATH, redirect: false });
}

export function finalizeSkincareAppointment(appointmentId: string) {
    return endpointFetch(FinalizeSkincareAppointmentEndpoint, {
        scope: SKINCARE_MANAGER_PATH,
        redirect: false,
        params: {
            appointmentId,
        },
    });
}

export function createSkincareAppointmentNote(appointmentId: string, body: MedicalRequestNote) {
    return endpointFetch(CreateSkincareAppointmentNoteEndpoint, {
        scope: SKINCARE_MANAGER_PATH,
        body,
        redirect: false,
        params: {
            appointmentId,
        },
    });
}

export function rescheduleSkincareAppointment(body: RescheduleSkincareAppointment) {
    return endpointFetch(RescheduledSkincareAppointmentEndpoint, {
        scope: SKINCARE_MANAGER_PATH,
        body,
        redirect: false,
    });
}

export function cancelSkincareAppointment(appointmentId: string) {
    return endpointFetch(CancelSkincareAppointmentEndpoint, {
        scope: SKINCARE_MANAGER_PATH,
        redirect: false,
        params: {
            appointmentId,
        },
    });
}

export function sendSkincareAppointmentPaymentRequest(appointmentId: string) {
    return endpointFetch(SendSkincarePaymentRequestEndpoint, {
        scope: SKINCARE_MANAGER_PATH,
        redirect: false,
        params: {
            appointmentId,
        },
    });
}
