/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { captureMessage } from '@sentry/react';
import { stringify } from 'qs';
import { EndpointSync } from '../../../api/utils/endpointSync';
import { ValidationError } from '../../../api/utils/error';
import { HttpStatus } from '../../../api/utils/https';
import { wait } from './async';
import { InfirmaryRoutes } from '../routes';

/**
 * Extended Error which can also contain the response triggering the error
 * (usually because of a faulty HTTP status code).
 *
 * This response can then be used to get more information about the error
 * by using the response body.
 */
export class FetchError extends Error {
    public response: Response;

    constructor(response: Response) {
        super(response.statusText);
        this.response = response;
        this.logError();
    }

    public async logError(): Promise<void> {
        try {
            const result = await this.response.clone().json();
            captureMessage('FetchError (JSON)', { extra: { result } });
        } catch {
            const result = await this.response.clone().text();
            captureMessage('FetchError (Text)', { extra: { result } });
        }
    }
}

/**
 * Default handler for http request errors.
 *
 * @param error The captured error
 */
export async function handleError(error: FetchError) {
    try {
        const errorResponseBody = await error.response.json();
        return {
            reason: errorResponseBody
                ? errorResponseBody.reason
                : 'We could not complete your request, please try again.',
            status: error.response.status,
        };
    } catch (err) {
        return;
    }
}

export class UnauthorizedError extends FetchError {}

/**
 * Helper method to check for a error HTTP response.
 * By default, if the request succeeds, the promise will
 * also succeed. However, we would like to have the request also fail
 * if the HTTP status core is below 200 or 300 and higher.
 *
 * @param response The requests response
 */
function checkHttpStatus(response: Response): Response {
    if (response.status >= HttpStatus.OK && response.status < HttpStatus.BAD_REQUEST) {
        return response;
    } else if (response.status === HttpStatus.UNAUTHORIZED) {
        throw new UnauthorizedError(response);
    }
    throw new FetchError(response);
}

/**
 * Extends the original RequestInit so it allows everything as body
 * (since we're going to serialize it)
 */
interface JsonRequestInit extends Omit<RequestInit, 'body'> {
    body?: any;
    headers?: Record<string, string>;
}

function backendJsonFetch(url: string, init: JsonRequestInit, hasResponseValidator: boolean) {
    if (init.body) {
        init.body = JSON.stringify(init.body);

        if (!init.headers) {
            init.headers = {};
        }

        init.headers['Content-Type'] = 'application/json';
    }

    init.credentials = 'include';
    const serverUrl = `${process.env.SERVER_HOST ?? 'http://localhost:8080'}${url}`;

    if (hasResponseValidator) {
        return (
            fetch(serverUrl, init)
                // Check if HTTP Status code is >= 200 && < 300
                .then(checkHttpStatus)
                // Return JSON response body
                .then((r) => r.text())
                .then((c) => (c ? JSON.parse(c) : undefined))
        );
    }

    return (
        fetch(serverUrl, init)
            // Check if HTTP Status code is >= 200 && < 300
            .then(checkHttpStatus)
    );
}

async function backendFilesFetch(url: string, init: JsonRequestInit) {
    init.credentials = 'include';
    const serverUrl = `${process.env.SERVER_HOST ?? 'http://localhost:8080'}${url}`;

    return (
        fetch(serverUrl, init)
            // Check if HTTP Status code is >= 200 && < 300
            .then(checkHttpStatus)
            // Return JSON response body
            .then((r) => r.text())
            .then((c) => (c ? JSON.parse(c) : undefined))
    );
}

export async function endpointFetchWithRetries<Query, Req, Res>(
    endpoint: EndpointSync<Query, Req, Res>,
    {
        query,
        body,
        params,
        init = {},
        scope = '',
        maxRetries = 4,
        redirect = true,
    }: {
        query?: Query;
        body?: Req;
        params?: { [key: string]: any };
        init?: Omit<JsonRequestInit, 'body'>;
        scope: string;
        maxRetries?: number;
        redirect?: boolean;
    },
    retries = 0,
): Promise<Res> {
    try {
        return await endpointFetch(endpoint, { query, body, params, init, scope, redirect });
    } catch (err: unknown) {
        if (err instanceof FetchError) {
            if (err.response.status === 404) {
                // Ugly hack so we can return an empty object otherwise apps cannot work with app data entries that do not exist
                return ({} as unknown) as Res;
            } else if (retries < maxRetries) {
                await wait(Math.pow(2, retries) * 1e3);
                return endpointFetchWithRetries(
                    endpoint,
                    { query, body, params, init, scope, maxRetries },
                    retries + 1,
                );
            }
        }
        throw err;
    }
}

export async function endpointFetch<Query, Req, Res>(
    { method, path, requestValidator, responseValidator }: EndpointSync<Query, Req, Res>,
    {
        query,
        body,
        params,
        init = {},
        scope = '',
        redirect = true,
    }: {
        query?: Query;
        body?: Req;
        params?: { [key: string]: any };
        init?: Omit<JsonRequestInit, 'body'>;
        scope: string;
        redirect?: boolean;
    },
): Promise<Res> {
    // Create init that is allowed to have a body
    const bodyInit: JsonRequestInit = init;
    // Set HTTP method
    bodyInit.method = method.toUpperCase();
    // If a query is provided, validate request
    let queryString = '';
    if (query) {
        queryString = '?' + stringify(query, { encodeValuesOnly: true });
    }
    // If a body is provided, validate request
    if (body != null && requestValidator != null) {
        const validationResult = requestValidator.validate(body);

        if (validationResult.success) {
            bodyInit.body = validationResult.value;
        } else {
            const message = 'Request body is invalid';
            const extra = { body, validationResult };

            captureMessage(message, { extra });
            throw new ValidationError(message, extra);
        }
    } else {
        bodyInit.body = body;
    }
    // Create a parameterized path
    const parameterizedPath = params ? endpointPathReplace(path, params) : path;

    // Request!
    let result;
    try {
        result = await backendJsonFetch(
            `${scope}${parameterizedPath}${queryString}`,
            bodyInit,
            responseValidator != null,
        );
    } catch (e) {
        if (e instanceof UnauthorizedError && redirect) {
            const currentUrl = window.location.href;

            if (currentUrl !== `${window.location.origin}/` && !currentUrl.includes(InfirmaryRoutes.Login)) {
                window.location.href = `/auth?redirect=${encodeURIComponent(currentUrl)}`;
            }
        } else {
            throw e;
        }
    }
    if (responseValidator != null) {
        const validationResult = responseValidator.validate(result);

        if (validationResult.success) {
            return validationResult.value as Res;
        } else {
            const message = 'Response is invalid';
            const extra = { result, validationResult };

            captureMessage(message, { extra });
            throw new ValidationError(message, extra);
        }
    }

    return result;
}

export async function endpointFileFetch<Query, Req, Res>(
    { method, path, responseValidator }: EndpointSync<Query, Req, Res>,
    {
        init = {},
        scope = '',
        params,
        formData = undefined,
        redirect = true,
    }: {
        scope: string;
        params?: { [key: string]: any };
        init?: Omit<JsonRequestInit, 'body'>;
        formData?: FormData;
        redirect?: boolean;
    },
): Promise<Res> {
    // Create init that is allowed to have a body
    const bodyInit: JsonRequestInit = init;
    // Set HTTP method
    bodyInit.method = method.toUpperCase();
    bodyInit.body = formData;

    // Create a parameterized path
    const parameterizedPath = params ? endpointPathReplace(path, params) : path;

    let result;
    try {
        result = await backendFilesFetch(`${scope}${parameterizedPath}`, bodyInit);
    } catch (e) {
        if (e instanceof UnauthorizedError && redirect) {
            const currentUrl = window.location.href;

            if (currentUrl !== `${window.location.origin}/` && !currentUrl.includes(InfirmaryRoutes.Login)) {
                window.location.href = `/auth?redirect=${encodeURIComponent(currentUrl)}`;
            }
        } else {
            throw e;
        }
    }

    if (responseValidator != null) {
        const validationResult = responseValidator.validate(result);

        if (validationResult.success) {
            return validationResult.value as Res;
        } else {
            const message = 'Response is invalid';
            const extra = { result, validationResult };

            captureMessage(message, { extra });
            throw new ValidationError(message, extra);
        }
    } else {
        return result;
    }
}

function endpointPathReplace(path: string, params: { [key: string]: string }): any {
    let copy = String(path);
    let tag: string[] | null;
    // Loop over the ':name' tags in the path string and replace them by corresponding values from params.
    // Regex for name: should start with an alphabetical character, rest can also be number, _ and -
    const tagRegex = new RegExp(/:[a-zA-Z][\w-]*/, 'g');
    // eslint-disable-next-line no-cond-assign
    while ((tag = tagRegex.exec(path)) != null) {
        const name = tag[0].substring(1); // Drop ':'
        if (params.hasOwnProperty(name)) {
            copy = copy.replace(tag[0], params[name]);
        } else {
            throw new Error(`Missing parameter value '${name}' for substitution in endpoint: '${copy}'`);
        }
    }
    return copy;
}
