/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import 'animate.css';
import { FC, Suspense, lazy, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { ErrorBoundary } from './error';
import { AppDispatch, history, persistor, store } from './store';
import { GlobalStyle } from './style/global';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SnackbarRenderer } from './components/shared/SnackbarRenderer';
import { DarkTheme, LightTheme, ThemeType } from '../../api/constants';
import { FullScreenLoadingPlaceholder } from './components/shared/FullScreenPlaceholder';
import { ThemeProvider } from 'styled-components';
import { selectThemeType } from './redux/platform/selector';
import { selectHasVerificationEventEvent, selectIsDoctorAuthorized } from './redux/doctor/selectors';
import { InfirmaryDashboardRoutes, InfirmaryRoutes } from './routes';
import { setPlatformTheme } from './redux/platform';
import { verifyUserSessionThunk } from './redux/doctor/thunk';
import { PersistGate } from 'redux-persist/integration/react';

const Auth = lazy(() => import('./components/Auth'));
const Dashboard = lazy(() => import('./components/Dashboard'));

interface ProtectedRouteProps {
    element: JSX.Element;
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const StoreApp: FC = () => {
    const themeType = useSelector(selectThemeType);

    // Check if the doctor is authenticated.
    const hasVerificationEventEvent = useSelector(selectHasVerificationEventEvent);
    const isAuthenticated = useSelector(selectIsDoctorAuthorized);

    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();

    useEffect(() => {
        dispatch(verifyUserSessionThunk());
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    // Handles requests to change application theme.
    const handleChangeTheme = () => {
        dispatch(setPlatformTheme(themeType === ThemeType.LightTheme ? ThemeType.DarkTheme : ThemeType.LightTheme));
    };

    const ProtectedDashboardRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
        return isAuthenticated ? element : <Navigate to={`${InfirmaryRoutes.Login}`} />;
    };

    const ProtectedAuthRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
        return !isAuthenticated ? element : <Navigate to={InfirmaryDashboardRoutes.Patients} />;
    };

    return (
        <ThemeProvider theme={themeType === ThemeType.LightTheme ? LightTheme : DarkTheme}>
            <GlobalStyle />

            <Suspense fallback={<FullScreenLoadingPlaceholder />}>
                {hasVerificationEventEvent ? (
                    <FullScreenLoadingPlaceholder />
                ) : (
                    <>
                        <Routes>
                            <Route
                                path={`${InfirmaryRoutes.Login}`}
                                element={<ProtectedAuthRoute element={<Auth />} />}
                            />

                            <Route
                                path={'*'}
                                element={
                                    <ProtectedDashboardRoute
                                        element={<Dashboard handleChangeTheme={handleChangeTheme} theme={themeType} />}
                                    />
                                }
                            />

                            <Route index={true} element={<Navigate to={InfirmaryDashboardRoutes.Patients} />} />
                        </Routes>

                        <SnackbarRenderer />
                    </>
                )}
            </Suspense>
        </ThemeProvider>
    );
};

const App: FC = () => {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Provider store={store}>
                <Router history={history}>
                    <PersistGate loading={null} persistor={persistor}></PersistGate>
                    <StoreApp />
                </Router>
            </Provider>
        </LocalizationProvider>
    );
};

root.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>,
);
