/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ThemeType } from '../../../../api/constants';

interface IPlatformState {
    theme: ThemeType;
    isInMobileView: boolean;
}
const initialState: IPlatformState = {
    isInMobileView: false,
    theme: ThemeType.LightTheme,
};

const platformSlice = createSlice({
    name: 'platform',
    initialState,
    reducers: {
        setMobileViewStatus(draft, { payload }: PayloadAction<boolean>) {
            draft.isInMobileView = payload;
        },
        setPlatformTheme(draft, { payload }: PayloadAction<ThemeType>) {
            draft.theme = payload;
        },
    },
});

export const platformReducer = platformSlice.reducer;

export const { setMobileViewStatus, setPlatformTheme } = platformSlice.actions;
