/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import styled from 'styled-components';

export const ErrorBoundaryContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.BackgroundPrimary};
`;

export const ErrorBoundaryWrapper = styled.div`
    display: flex;
    padding: 0 20px;
    max-width: 600px;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    a {
        color: ${({ theme }) => theme.BrandPrimary};
    }
`;
