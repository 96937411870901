/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { CONSULTATION_MESSAGE_LIMIT } from '../constants';

export function validateConsultationMessage(message: string) {
    const count = message.trim().length;
    return count <= CONSULTATION_MESSAGE_LIMIT || 'Invalid consultation message body.';
}
