/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

export enum InfirmaryRoutes {
    Login = '/auth',
    Recovery = '/auth/recovery',
}

export enum InfirmaryDashboardRoutes {
    History = 'history',
    Patients = 'patients',
    Articles = 'articles',
    Skincare = 'skincare',
    Consultation = 'consultation',
    Prescriptions = 'prescriptions',
    PatientSearch = 'patient-search',
    InfirmaryRequests = 'infirmary-requests',
}
