/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PrescriptionRecord } from '../../../../api/types/prescription';
import {
    createPrescriptionDigitalConsultationQuestionThunk,
    createPrescriptionDrugThunk,
    createPrescriptionFamilyClassThunk,
    createPrescriptionFamilyThunk,
    deletePrescriptionDigitalConsultationQuestionThunk,
    deletePrescriptionDrugThunk,
    deletePrescriptionFamilyClassThunk,
    deletePrescriptionFamilyThunk,
    getPrescriptionRecordsThunk,
    updateFamilyConsultationQuestionsStateThunk,
    updatePrescriptionDigitalConsultationQuestionThunk,
    updatePrescriptionDrugThunk,
    updatePrescriptionFamilyClassThunk,
    updatePrescriptionFamilyThunk,
} from './thunk';
import { ResourceSessionStatus } from '../../../../api/constants';

interface IPrescriptionState {
    prescriptions: PrescriptionRecord[];
    selectedPrescriptionFamilyId?: string;
    selectedPrescriptionFamilyClassId?: string;
    isFetchingPrescriptionsEvent: boolean;
    isCreatingPrescriptionsFamily: boolean;
    requestEvents: Record<string, ResourceSessionStatus>;
    familyConsultationQuestionsEvents: Record<string, ResourceSessionStatus>;
}

const initialState: IPrescriptionState = {
    prescriptions: [],
    requestEvents: {},
    isFetchingPrescriptionsEvent: false,
    isCreatingPrescriptionsFamily: false,
    familyConsultationQuestionsEvents: {},
};

const prescriptionSlice = createSlice({
    name: 'prescription',
    initialState,
    reducers: {
        setSelectedPrescriptionFamilyId(draft, { payload }: PayloadAction<string | undefined>) {
            draft.selectedPrescriptionFamilyId = payload;
        },
        setSelectedPrescriptionFamilyClassId(draft, { payload }: PayloadAction<string | undefined>) {
            draft.selectedPrescriptionFamilyClassId = payload;
        },
        removePrescriptionEventRequest(draft, { payload }: PayloadAction<string>) {
            delete draft.requestEvents[payload];
        },
    },
    extraReducers: (reducers) => {
        reducers
            .addCase(getPrescriptionRecordsThunk.pending, (draft) => {
                draft.isFetchingPrescriptionsEvent = true;
            })
            .addCase(getPrescriptionRecordsThunk.fulfilled, (draft, { payload }) => {
                if (payload != null) {
                    draft.prescriptions = payload.sort((prescriptionA, prescriptionB) =>
                        prescriptionA.name.localeCompare(prescriptionB.name),
                    );
                    draft.selectedPrescriptionFamilyId = !draft.selectedPrescriptionFamilyId
                        ? draft.prescriptions[0].id
                        : draft.selectedPrescriptionFamilyId;
                }
                draft.isFetchingPrescriptionsEvent = false;
            })
            .addCase(getPrescriptionRecordsThunk.rejected, (draft) => {
                draft.isFetchingPrescriptionsEvent = false;
            })
            // Create prescription family handlers
            .addCase(createPrescriptionFamilyThunk.pending, (draft, { meta }) => {
                draft.isCreatingPrescriptionsFamily = true;
                draft.requestEvents[meta.arg.eventId] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(createPrescriptionFamilyThunk.fulfilled, (draft, { payload, meta }) => {
                if (payload != null) {
                    draft.prescriptions.push(payload);
                    draft.prescriptions = draft.prescriptions.sort((prescriptionA, prescriptionB) =>
                        prescriptionA.name.localeCompare(prescriptionB.name),
                    );
                }
                draft.isCreatingPrescriptionsFamily = false;
                draft.requestEvents[meta.arg.eventId] = ResourceSessionStatus.COMPLETED;
            })
            .addCase(createPrescriptionFamilyThunk.rejected, (draft, { meta }) => {
                draft.isCreatingPrescriptionsFamily = false;
                draft.requestEvents[meta.arg.eventId] = ResourceSessionStatus.PENDING;
            })
            // Update prescription family handlers.
            .addCase(updatePrescriptionFamilyThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.referenceId] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(updatePrescriptionFamilyThunk.fulfilled, (draft, { meta }) => {
                const index = draft.prescriptions.findIndex(({ id }) => id === meta.arg.referenceId);
                if (index !== -1) {
                    draft.prescriptions[index].name = meta.arg.body.name;
                }

                draft.requestEvents[meta.arg.referenceId] = ResourceSessionStatus.COMPLETED;
            })
            .addCase(updatePrescriptionFamilyThunk.rejected, (draft, { meta }) => {
                draft.requestEvents[meta.arg.referenceId] = ResourceSessionStatus.PENDING;
            })
            // Delete prescription family handlers.
            .addCase(deletePrescriptionFamilyThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg] = ResourceSessionStatus.IN_PROGRESS;
                console.log('Request updated: ', draft.requestEvents[meta.arg]);
            })
            .addCase(deletePrescriptionFamilyThunk.fulfilled, (draft, { meta }) => {
                draft.prescriptions = draft.prescriptions.filter(({ id }) => id !== meta.arg);
                delete draft.requestEvents[meta.arg];
            })
            .addCase(deletePrescriptionFamilyThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg];
            })
            // Create prescription family class handlers
            .addCase(createPrescriptionFamilyClassThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.eventId] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(createPrescriptionFamilyClassThunk.fulfilled, (draft, { payload, meta }) => {
                if (payload != null) {
                    const familyIndex = draft.prescriptions.findIndex(({ id }) => id === meta.arg.familyId);
                    draft.prescriptions[familyIndex].classes.push(payload);
                }
                draft.requestEvents[meta.arg.eventId] = ResourceSessionStatus.COMPLETED;
            })
            .addCase(createPrescriptionFamilyClassThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg.eventId];
            })
            // Update prescription family class handlers.
            .addCase(updatePrescriptionFamilyClassThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.referenceId] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(updatePrescriptionFamilyClassThunk.fulfilled, (draft, { meta }) => {
                const familyIndex = draft.prescriptions.findIndex(({ id }) => id === meta.arg.familyId);
                if (familyIndex !== -1) {
                    const classIndex = draft.prescriptions[familyIndex].classes.findIndex(
                        ({ id }) => id === meta.arg.referenceId,
                    );
                    draft.prescriptions[familyIndex].classes[classIndex].name = meta.arg.body.name;
                }

                draft.requestEvents[meta.arg.referenceId] = ResourceSessionStatus.COMPLETED;
            })
            .addCase(updatePrescriptionFamilyClassThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg.referenceId];
            })
            // Delete prescription family class handlers.
            .addCase(deletePrescriptionFamilyClassThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.referenceId] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(deletePrescriptionFamilyClassThunk.fulfilled, (draft, { meta }) => {
                const familyIndex = draft.prescriptions.findIndex(({ id }) => id === meta.arg.familyId);
                draft.prescriptions[familyIndex].classes = draft.prescriptions[familyIndex].classes.filter(
                    ({ id }) => id !== meta.arg.referenceId,
                );
                delete draft.requestEvents[meta.arg.referenceId];
            })
            .addCase(deletePrescriptionFamilyClassThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg.referenceId];
            })
            // Prescription consultation.
            .addCase(createPrescriptionDigitalConsultationQuestionThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.eventId] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(createPrescriptionDigitalConsultationQuestionThunk.fulfilled, (draft, { payload, meta }) => {
                const familyIndex = draft.prescriptions.findIndex(({ id }) => id === meta.arg.familyId);

                if (familyIndex !== -1) {
                    const familyClassIndex = draft.prescriptions[familyIndex].classes.findIndex(
                        ({ id }) => id === meta.arg.familyClassId,
                    );
                    draft.prescriptions[familyIndex].classes[familyClassIndex].digital_consultations.push(payload);
                }
                draft.requestEvents[meta.arg.eventId] = ResourceSessionStatus.COMPLETED;
            })
            .addCase(createPrescriptionDigitalConsultationQuestionThunk.rejected, (draft, { meta }) => {
                draft.requestEvents[meta.arg.eventId] = ResourceSessionStatus.PENDING;
            })
            .addCase(updatePrescriptionDigitalConsultationQuestionThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.referenceId] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(updatePrescriptionDigitalConsultationQuestionThunk.fulfilled, (draft, { payload, meta }) => {
                // Find the index of the prescription
                const prescriptionIndex = draft.prescriptions.findIndex(({ id }) => id === meta.arg.familyId);

                // If prescription is found, find the consultation index
                if (prescriptionIndex !== -1) {
                    const familyClassIndex = draft.prescriptions[prescriptionIndex].classes.findIndex(
                        ({ id }) => id === meta.arg.familyClassId,
                    );
                    const prescription = draft.prescriptions[prescriptionIndex];
                    const consultationIndex = prescription.classes[familyClassIndex].digital_consultations.findIndex(
                        ({ id }) => payload.id === id,
                    );

                    // If consultation is found, update it
                    if (consultationIndex !== -1) {
                        prescription.classes[familyClassIndex].digital_consultations[consultationIndex] = payload;
                    }
                }

                draft.requestEvents[payload.id] = ResourceSessionStatus.COMPLETED;
            })
            .addCase(updatePrescriptionDigitalConsultationQuestionThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg.referenceId];
            })
            .addCase(deletePrescriptionDigitalConsultationQuestionThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.digitalConsultationId] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(deletePrescriptionDigitalConsultationQuestionThunk.fulfilled, (draft, { meta }) => {
                // Find the index of the prescription family.
                const prescriptionIndex = draft.prescriptions.findIndex(({ id }) => id === meta.arg.familyId);

                // If prescription is found, filter the digital consultation question.
                if (prescriptionIndex !== -1) {
                    const prescription = draft.prescriptions[prescriptionIndex];
                    const familyClassIndex = draft.prescriptions[prescriptionIndex].classes.findIndex(
                        ({ id }) => id === meta.arg.familyClassId,
                    );
                    draft.prescriptions[prescriptionIndex].classes[
                        familyClassIndex
                    ].digital_consultations = prescription.classes[familyClassIndex].digital_consultations.filter(
                        ({ id }) => id !== meta.arg.digitalConsultationId,
                    );
                }
                draft.prescriptions = draft.prescriptions;
                delete draft.requestEvents[meta.arg.digitalConsultationId];
            })
            .addCase(deletePrescriptionDigitalConsultationQuestionThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg.digitalConsultationId];
            })
            .addCase(createPrescriptionDrugThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.eventId] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(createPrescriptionDrugThunk.fulfilled, (draft, { payload, meta }) => {
                // Find the index of the prescription family.
                const prescriptionIndex = draft.prescriptions.findIndex(({ id }) => id === meta.arg.familyId);

                // Add created drug to the prescription family.
                if (prescriptionIndex !== -1) {
                    const familyClassIndex = draft.prescriptions[prescriptionIndex].classes.findIndex(
                        ({ id }) => id === meta.arg.familyClassId,
                    );

                    draft.prescriptions[prescriptionIndex].classes[familyClassIndex].drugs.push(payload);
                }

                draft.requestEvents[meta.arg.eventId] = ResourceSessionStatus.COMPLETED;
            })
            .addCase(createPrescriptionDrugThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg.eventId];
            })
            .addCase(updatePrescriptionDrugThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.referenceId] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(updatePrescriptionDrugThunk.fulfilled, (draft, { payload, meta }) => {
                // Find the index of the prescription family.
                const prescriptionIndex = draft.prescriptions.findIndex(({ id }) => id === meta.arg.familyId);

                // Add created drug to the prescription family.
                if (prescriptionIndex !== -1) {
                    const familyClassIndex = draft.prescriptions[prescriptionIndex].classes.findIndex(
                        ({ id }) => id === meta.arg.familyClassId,
                    );

                    const drugIndex = draft.prescriptions[prescriptionIndex].classes[familyClassIndex].drugs.findIndex(
                        ({ id }) => id === payload.id,
                    );

                    draft.prescriptions[prescriptionIndex].classes[familyClassIndex].drugs[drugIndex] = payload;
                }

                draft.requestEvents[meta.arg.referenceId] = ResourceSessionStatus.COMPLETED;
            })
            .addCase(updatePrescriptionDrugThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg.referenceId];
            })
            .addCase(deletePrescriptionDrugThunk.pending, (draft, { meta }) => {
                draft.requestEvents[meta.arg.referenceId] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(deletePrescriptionDrugThunk.fulfilled, (draft, { meta }) => {
                // Find the index of the prescription family.
                const prescriptionIndex = draft.prescriptions.findIndex(({ id }) => id === meta.arg.familyId);

                // If prescription is found, filter the digital consultation question.
                if (prescriptionIndex !== -1) {
                    const familyClassIndex = draft.prescriptions[prescriptionIndex].classes.findIndex(
                        ({ id }) => id === meta.arg.familyClassId,
                    );
                    const prescription = draft.prescriptions[prescriptionIndex];
                    draft.prescriptions[prescriptionIndex].classes[familyClassIndex].drugs = prescription.classes[
                        familyClassIndex
                    ].drugs.filter(({ id }) => id !== meta.arg.referenceId);
                }
                draft.prescriptions = draft.prescriptions;
                delete draft.requestEvents[meta.arg.referenceId];
            })
            .addCase(deletePrescriptionDrugThunk.rejected, (draft, { meta }) => {
                delete draft.requestEvents[meta.arg.referenceId];
            })
            // Family consultation questions state handlers
            .addCase(updateFamilyConsultationQuestionsStateThunk.pending, (draft, { meta }) => {
                draft.familyConsultationQuestionsEvents[meta.arg] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(updateFamilyConsultationQuestionsStateThunk.fulfilled, (draft, { meta, payload }) => {
                const familyIndex = draft.prescriptions.findIndex(({ id }) => id === meta.arg);
                if (familyIndex !== -1) {
                    draft.prescriptions[familyIndex].apply_default_questions = payload.status;
                }
                delete draft.familyConsultationQuestionsEvents[meta.arg];
            })
            .addCase(updateFamilyConsultationQuestionsStateThunk.rejected, (draft, { meta }) => {
                delete draft.familyConsultationQuestionsEvents[meta.arg];
            });
    },
});

export const prescriptionReducer = prescriptionSlice.reducer;

export const {
    removePrescriptionEventRequest,
    setSelectedPrescriptionFamilyId,
    setSelectedPrescriptionFamilyClassId,
} = prescriptionSlice.actions;
