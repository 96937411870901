/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../utils/runtypes';

export const SUPPORT_MESSAGE_LIMIT = 1000;

export enum SupportQuestionCategories {
    ContentCreation = 'Content Creation',
    Customization = 'Customization or Appearance',
    Integration = 'Integration',
    Analytics = 'Analytics',
    Settings = 'Settings',
    Billing = 'Billing',
    Other = 'Other',
}
export const SupportQuestionCategoriesSchema = runtypeFromEnum(SupportQuestionCategories);

export enum SupportFeedbackCategories {
    Review = 'Review',
    BugReport = 'Bug Report',
    FeatureRequest = 'Feature Request',
    GeneralFeedback = 'General Feedback',
}
export const SupportFeedbackCategoriesSchema = runtypeFromEnum(SupportFeedbackCategories);
