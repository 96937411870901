/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../utils/runtypes';

export const MEDICAL_SUMMARY_LIMIT = 1000;

export const PASSWORD_MIN_CHARACTERS = 8;
export const PASSWORD_MAX_CHARACTERS = 160;

// Code used for account verification.
export const USER_VERIFICATION_CODE_LIMIT = 5;

export const EMERGENCY_CONTACT_RELATIONSHIP_LIMIT = 40;

export enum UserAccountType {
    Doctor = 'Doctor',
    Patient = 'Patient',
}
export const UserAccountTypeSchema = runtypeFromEnum(UserAccountType);

export enum UserAccountAccessLevelType {
    Master = 'Master',
    General = 'General',
}
export const UserAccountAccessLevelTypeSchema = runtypeFromEnum(UserAccountAccessLevelType);

export enum Gender {
    Male = 'Male',
    Female = 'Female',
}
export const GenderSchema = runtypeFromEnum(Gender);

export enum DVACardColor {
    Gold = 'Gold',
    White = 'White',
    Orange = 'Orange',
}
export const DVACardColorSchema = runtypeFromEnum(DVACardColor);

export enum AboriginalTorres {
    No = 'No',
    Torres = 'Yes, Torres Strait Islander',
    Aboriginal = 'Yes, Aboriginal',
    PreferNotToSay = 'Prefer Not To Say',
}
export const AboriginalTorresSchema = runtypeFromEnum(AboriginalTorres);

export enum ChronicConditions {
    acne = 'Acne',
    asthma = 'Asthma',
    anxiety = 'Anxiety',
    blood_clots = 'Blood clots',
    bowel_polyps = 'Bowel Polyps',
    cancer = 'Cancer',
    cataracts = 'Cataracts',
    chronic_fatigue_syndrome = 'Chronic Fatigue Syndrome',
    chronic_pain = 'Chronic Pain',
    crohns_disease = "Crohn's Disease",
    coeliac_disease = 'Coeliac Disease',
    copd_emphysema = 'COPD/Emphysema',
    dementia = 'Dementia',
    depression = 'Depression',
    diabetes = 'Diabetes',
    diverticular_disease = 'Diverticular Disease',
    eczema = 'Eczema',
    endometriosis = 'Endometriosis',
    glaucoma = 'Glaucoma',
    gout = 'Gout',
    heart_disease = 'Heart disease',
    high_blood_pressure = 'High blood pressure',
    high_cholesterol = 'High Cholesterol',
    hiv = 'HIV',
    hyperthyroidism_overactive = 'Hyperthyroidism (overactive thyroid)',
    hyperthyroidism_underactive = 'Hypothyroidism (underactive thyroid)',
    ibs = 'IBS',
    kidney_disease = 'Kidney disease',
    liver_disease = 'Liver disease',
    macular_degeneration = 'Macular degeneration',
    migraines_chronic_headaches = 'Migraines/Chronic headaches',
    multiple_sclerosis = 'Multiple Sclerosis',
    obesity = 'Obesity',
    osteoarthritis = 'Osteoarthritis',
    osteoporosis = 'Osteoporosis',
    parkinson = "Parkinson's",
    psoriasis = 'Psoriasis',
    polycystic_ovary_syndrome = 'Polycystic ovary syndrome',
    reflux_or_heartburn = 'Reflux/Heartburn',
    rheumatoid_arthritis = 'Rheumatoid arthritis',
    scc_or_bcc_skin_cancers = 'SCC/BCC skin cancers',
    stroke = 'Stroke',
    ulcerative_colitis = 'Ulcerative Colitis',
}
export const ChronicConditionsSchema = runtypeFromEnum(ChronicConditions);

export enum DoctorPractitioner {
    GP = 'GP',
    Psychologist = 'Psychologist',
}
export const DoctorPractitionerSchema = runtypeFromEnum(DoctorPractitioner);
