/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    GetInfirmaryPendingRequest,
    GetInfirmaryRecordsResponse,
    InfirmaryRequest,
    RejectConsultation,
} from '../../../../api/types/infirmary';
import { ConsultationRoutes } from '../../../../api/routes/consultation';
import { MedicalRequestFlag, MedicalRequestNote } from '../../../../api/types/medical/request';
import { COMMON_RESTFUL_ERROR_MESSAGE } from '../../../../api/constants';
import { PrescriptionDrug } from '../../../../api/types/prescription/escript';
import { SnackbarTypes } from '../../../../common/types';
import {
    approvalMedicalRequestAppScript,
    checkPendingInfirmaryConsultation,
    createInfirmaryMedicalRequestFlag,
    exportERxPadPatientProfiles,
    getInfirmaryHistoryRequests,
    getInfirmaryMedicalRequests,
    getInfirmaryMedicationRequests,
    rejectMedicalRequestConsultation,
    resendMedicalRequestAppScriptLetter,
} from '../../fetch/infirmary';
import { handleError, FetchError } from '../../tools/fetch';
import { HttpStatus } from '../../../../api/utils/https';
import { setSnackbarMessage } from '../snackbar';
import { triggerDownloadFile } from '../../tools/util';
import { format } from 'date-fns';

export const getInfirmaryRequestsThunk = createAsyncThunk<GetInfirmaryRecordsResponse, { isRefreshing: boolean }>(
    'infirmary/records',
    async ({ isRefreshing }, { dispatch, rejectWithValue }) => {
        try {
            const records = await getInfirmaryMedicalRequests();

            // Show notification if this was a refresh request.
            if (isRefreshing) {
                dispatch(
                    setSnackbarMessage({
                        message: `Patients waiting room has been refreshed.`,
                        type: SnackbarTypes.SUCCESS,
                    }),
                );
            }

            return records;
        } catch (error) {
            const response = await handleError(error as FetchError);

            dispatch(
                setSnackbarMessage({
                    message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                    type: SnackbarTypes.ERROR,
                }),
            );

            return rejectWithValue(response?.reason);
        }
    },
);

export const getInfirmaryHistoryThunk = createAsyncThunk<InfirmaryRequest[], { isRefreshing: boolean }>(
    'infirmary/history',
    async ({ isRefreshing }, { dispatch, rejectWithValue }) => {
        try {
            const records = await getInfirmaryHistoryRequests();

            // Show notification if this was a refresh request.
            if (isRefreshing) {
                dispatch(
                    setSnackbarMessage({
                        message: `Your consultation history has been refreshed.`,
                        type: SnackbarTypes.SUCCESS,
                    }),
                );
            }

            return records;
        } catch (error) {
            const response = await handleError(error as FetchError);

            dispatch(
                setSnackbarMessage({
                    message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                    type: SnackbarTypes.ERROR,
                }),
            );

            return rejectWithValue(response?.reason);
        }
    },
);

export const getInfirmaryMedicationThunk = createAsyncThunk<PrescriptionDrug[] | undefined, void>(
    'infirmary/medication',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const records = await getInfirmaryMedicationRequests();
            return records;
        } catch (error) {
            const response = await handleError(error as FetchError);

            dispatch(
                setSnackbarMessage({
                    message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                    type: SnackbarTypes.ERROR,
                }),
            );

            return rejectWithValue(response?.reason);
        }
    },
);

export const createInfirmaryRequestFlagThunk = createAsyncThunk<
    InfirmaryRequest,
    { body: MedicalRequestFlag; id: string }
>('infirmary/flag', async ({ body, id }, { dispatch, rejectWithValue }) => {
    try {
        return await createInfirmaryMedicalRequestFlag(body, id);
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                type: SnackbarTypes.ERROR,
            }),
        );

        return rejectWithValue(response?.reason);
    }
});

export const approvalMedicalRequestAppScriptThunk = createAsyncThunk<
    void,
    { requestId: string; patientName: string; scriptType: string; note: MedicalRequestNote }
>(
    'infirmary/consultation/approve/script',
    async ({ requestId, patientName, scriptType, note }, { dispatch, rejectWithValue }) => {
        try {
            await approvalMedicalRequestAppScript(requestId, note);
            dispatch(
                setSnackbarMessage({
                    message: `You have successfully approved ${patientName}'s medical request for ${scriptType.toLowerCase()}`,
                    type: SnackbarTypes.SUCCESS,
                }),
            );
            dispatch(getInfirmaryRequestsThunk({ isRefreshing: false }));
        } catch (error) {
            const response = await handleError(error as FetchError);

            if (response?.status === HttpStatus.NOT_ACCEPTABLE) {
                dispatch(getInfirmaryRequestsThunk({ isRefreshing: false }));
            }

            dispatch(
                setSnackbarMessage({
                    message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                    type: SnackbarTypes.ERROR,
                }),
            );

            return rejectWithValue(response?.reason);
        }
    },
);

export const resendMedicalRequestAppScriptLetterThunk = createAsyncThunk<
    void,
    { requestId: string; patientName: string; scriptType: string }
>(
    'infirmary/consultation/resend/script/letter',
    async ({ requestId, patientName, scriptType }, { dispatch, rejectWithValue }) => {
        try {
            await resendMedicalRequestAppScriptLetter(requestId);
            dispatch(
                setSnackbarMessage({
                    message: `You have successfully resent ${patientName}'s ${scriptType.toLowerCase()} letter`,
                    type: SnackbarTypes.SUCCESS,
                }),
            );
            dispatch(getInfirmaryRequestsThunk({ isRefreshing: false }));
        } catch (error) {
            const response = await handleError(error as FetchError);

            if (response?.status === HttpStatus.NOT_ACCEPTABLE) {
                dispatch(getInfirmaryRequestsThunk({ isRefreshing: false }));
            }

            dispatch(
                setSnackbarMessage({
                    message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                    type: SnackbarTypes.ERROR,
                }),
            );

            return rejectWithValue(response?.reason);
        }
    },
);

export const rejectMedicalRequestConsultationThunk = createAsyncThunk<
    void,
    {
        requestId: string;
        patientName: string;
        scriptType: string;
        body: RejectConsultation;
        redirectToWaitingRoom: boolean;
    }
>(
    'infirmary/consultation/reject',
    async ({ requestId, patientName, scriptType, body, redirectToWaitingRoom }, { dispatch, rejectWithValue }) => {
        try {
            await rejectMedicalRequestConsultation(requestId, body);
            dispatch(
                setSnackbarMessage({
                    message: `You have successfully rejected ${patientName}'s medical request consultation for ${scriptType.toLowerCase()}`,
                    type: SnackbarTypes.SUCCESS,
                }),
            );
            dispatch(getInfirmaryRequestsThunk({ isRefreshing: false }));

            if (redirectToWaitingRoom) {
                window.location.href = `/${ConsultationRoutes.Patient}`;
            }
        } catch (error) {
            const response = await handleError(error as FetchError);

            if (response?.status === HttpStatus.NOT_ACCEPTABLE) {
                dispatch(getInfirmaryRequestsThunk({ isRefreshing: false }));
            }

            dispatch(
                setSnackbarMessage({
                    message: response?.reason ?? COMMON_RESTFUL_ERROR_MESSAGE,
                    type: SnackbarTypes.ERROR,
                }),
            );

            return rejectWithValue(response?.reason);
        }
    },
);

export const getPendingConsultationRequestThunk = createAsyncThunk<GetInfirmaryPendingRequest, void>(
    'infirmary/get/pending/request',
    async (_, { rejectWithValue }) => {
        try {
            return (await checkPendingInfirmaryConsultation()) ?? undefined;
        } catch (error) {
            const response = await handleError(error as FetchError);
            return rejectWithValue(response?.reason);
        }
    },
);

export const exportPatientERxPadProfilesThunk = createAsyncThunk<void, string[] | undefined>(
    'infirmary/eRxPad/export/profile',
    async (patients, { rejectWithValue, dispatch }) => {
        try {
            const response = await exportERxPadPatientProfiles(patients ?? []);

            const reader = response.body.getReader();
            const stream = new ReadableStream({
                start(controller) {
                    return pump();
                    function pump() {
                        return reader.read().then(({ done, value }: any) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            return pump();
                        });
                    }
                },
            });

            const blob = await new Response(stream).blob();

            // Generate download filename.
            const formattedDate = format(new Date(), 'dd-MM-yyyy');
            const filename = `eRxPad_aussie_scripts_${formattedDate}.xlsx`;

            // Create a download link
            const url = window.URL.createObjectURL(blob);
            triggerDownloadFile(url, filename);

            dispatch(
                setSnackbarMessage({
                    message: `You have successfully exported patients for eRxPad. Check your downloads for the file ${filename}. Remember to delete this file after importing it into eRxPad.`,
                    type: SnackbarTypes.SUCCESS,
                }),
            );
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message:
                        response?.reason ?? `Exporting eRxPad patient records failed. ${COMMON_RESTFUL_ERROR_MESSAGE}`,
                    type: SnackbarTypes.ERROR,
                }),
            );

            return rejectWithValue(response?.reason);
        }
    },
);
