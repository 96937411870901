/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import {
    RecoverAccountRequestSchema,
    LoginRequestSchema,
    PatientRegistrationSchema,
    VerifyAccountRequestSchema,
    ResetPasswordSchema,
    VerifyRecoveryTokenResponseSchema,
    DoctorRegistrationSchema,
} from '../types/auth';
import { DoctorResourceSchema } from '../types/user/doctor';
import { PatientResourceSchema } from '../types/user/patient';
import { EndpointSync, HttpMethod } from '../utils/endpointSync';

export const PatientRegistrationEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/register',
    requestValidator: PatientRegistrationSchema,
    responseValidator: PatientResourceSchema,
});

export const DoctorRegistrationEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/infirmary/register',
    requestValidator: DoctorRegistrationSchema,
});

export const PatientLoginEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/login',
    requestValidator: LoginRequestSchema,
    responseValidator: PatientResourceSchema,
});

export const DoctorLoginEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/infirmary/login',
    requestValidator: LoginRequestSchema,
    responseValidator: DoctorResourceSchema,
});

export const LogoutEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/logout',
});

export const PatientVerifySessionEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/verify/session',
    responseValidator: PatientResourceSchema,
});

export const DoctorVerifySessionEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/infirmary/verify/session',
    responseValidator: DoctorResourceSchema,
});

export const RecoverAccountRequestEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/account/recovery',
    requestValidator: RecoverAccountRequestSchema,
});

export const VerifyAccountEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/verify/account',
    requestValidator: VerifyAccountRequestSchema,
});

export const ResendVerificationCodeEndpoint = new EndpointSync({
    method: HttpMethod.PATCH,
    path: '/request/verification-code',
});

export const VerifyRecoveryTokenEndpoint = new EndpointSync({
    method: HttpMethod.GET,
    path: '/account/recovery/:token',
    responseValidator: VerifyRecoveryTokenResponseSchema,
});

export const ResetAccountPasswordEndpoint = new EndpointSync({
    method: HttpMethod.POST,
    path: '/account/password/reset/:token',
    requestValidator: ResetPasswordSchema,
});
