/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { ConsultationMessageSchema, DigitalConsultationSchema, PhoneNumberSchema } from '.';
import { ServiceRequestType } from '../constants/checkout';
import { TelehealthServiceTitleSchema } from '../constants';

const TelehealthConsultationDetailsSchema = r.Record({
    phone: PhoneNumberSchema,
    medicalHistory: ConsultationMessageSchema,
    currentMedication: ConsultationMessageSchema,
});

export const TelehealthRequestSchema = r.Record({
    title: TelehealthServiceTitleSchema,
    consultations: r.Array(DigitalConsultationSchema),
    category: r.Literal(ServiceRequestType.Telehealth),
    consultationDetails: TelehealthConsultationDetailsSchema,
});
export type TelehealthRequest = r.Static<typeof TelehealthRequestSchema>;
