/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ResourceSessionStatus } from '../../../../common/types';
import {
    approvalMedicalRequestAppScriptThunk,
    createInfirmaryRequestFlagThunk,
    rejectMedicalRequestConsultationThunk,
    resendMedicalRequestAppScriptLetterThunk,
} from '../infirmary/thunk';

interface IEventState {
    requests: Record<string, ResourceSessionStatus>;
}

const initialState: IEventState = {
    requests: {},
};

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setEventRequest(draft, { payload }: PayloadAction<{ eventId: string; status: ResourceSessionStatus }>) {
            draft.requests[payload.eventId] = payload.status;
        },
        removeEventRequest(draft, { payload }: PayloadAction<string>) {
            delete draft.requests[payload];
        },
    },
    extraReducers: (reducers) => {
        reducers
            .addCase(approvalMedicalRequestAppScriptThunk.pending, (draft, { meta }) => {
                draft.requests[meta.arg.requestId] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(approvalMedicalRequestAppScriptThunk.fulfilled, (draft, { meta }) => {
                draft.requests[meta.arg.requestId] = ResourceSessionStatus.COMPLETED;
            })
            .addCase(approvalMedicalRequestAppScriptThunk.rejected, (draft, { meta }) => {
                draft.requests[meta.arg.requestId] = ResourceSessionStatus.PENDING;
            })
            .addCase(resendMedicalRequestAppScriptLetterThunk.pending, (draft, { meta }) => {
                draft.requests[meta.arg.requestId] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(resendMedicalRequestAppScriptLetterThunk.fulfilled, (draft, { meta }) => {
                draft.requests[meta.arg.requestId] = ResourceSessionStatus.COMPLETED;
            })
            .addCase(resendMedicalRequestAppScriptLetterThunk.rejected, (draft, { meta }) => {
                draft.requests[meta.arg.requestId] = ResourceSessionStatus.PENDING;
            })
            .addCase(rejectMedicalRequestConsultationThunk.pending, (draft, { meta }) => {
                draft.requests[meta.arg.requestId] = ResourceSessionStatus.IN_PROGRESS;
            })
            .addCase(rejectMedicalRequestConsultationThunk.fulfilled, (draft, { meta }) => {
                draft.requests[meta.arg.requestId] = ResourceSessionStatus.COMPLETED;
            })
            .addCase(rejectMedicalRequestConsultationThunk.rejected, (draft, { meta }) => {
                draft.requests[meta.arg.requestId] = ResourceSessionStatus.PENDING;
            })
            // TODO: Resolve this, it cause cyclic dependency. Consider moving this infirmary state.
            .addCase(createInfirmaryRequestFlagThunk.fulfilled, (draft, { payload }) => {
                if (draft.requests[payload.id] != null) {
                    draft.requests[payload.id] = ResourceSessionStatus.COMPLETED;
                }
            })
            .addCase(createInfirmaryRequestFlagThunk.rejected, (draft, { meta }) => {
                draft.requests[meta.arg.id] = ResourceSessionStatus.PENDING;
            });
    },
});

export const eventReducer = eventSlice.reducer;

export const { removeEventRequest, setEventRequest } = eventSlice.actions;
