/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { runtypeFromEnum } from '../utils/runtypes';

export const CONSULTATION_MESSAGE_LIMIT = 2000;

export enum GeneralConsultations {
    GeneralConsultation = 'General Consultation',
    FollowUpConsultation = 'Follow-Up Consultation',
}
export const GeneralConsultationsSchema = runtypeFromEnum(GeneralConsultations);

export enum AdditionalConsultations {
    CovidOralAntiviralTreatments = 'Covid-19 Oral Antiviral Treatments',
    HairLoss = 'Hair Loss',
    SmokingCessation = 'Smoking Cessation',
    FertilityTreatmentReferral = 'Fertility Treatment Referral',
    ErectileDysfunctionTreatments = 'Erectile Dysfunction Treatments',
    UTITreatments = 'UTI Treatments',
}
export const AdditionalConsultationsSchema = runtypeFromEnum(AdditionalConsultations);
export interface TelehealthConsultationType {
    urlSlug: string;
    priceMeta?: string;
    description: string;
    eScriptAvailable: boolean;
    deliveryAvailable: boolean;
    title: GeneralConsultations | AdditionalConsultations;
}

export type GeneralTelehealthType = {
    [key in GeneralConsultations]: TelehealthConsultationType;
};

export type AdditionalTelehealthType = {
    [key in AdditionalConsultations]: TelehealthConsultationType;
};

export const TelehealthServiceTitleSchema = GeneralConsultationsSchema.Or(AdditionalConsultationsSchema);
export type TelehealthServiceTitle = r.Static<typeof TelehealthServiceTitleSchema>;

export interface TelehealthServiceEntry {
    price: number;
    urlSlug: string;
    description: string;
    title: TelehealthServiceTitle;
}
