/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../utils/runtypes';

export const PERSONAL_NAME_MAX_CHARACTERS = 60;

export const EMAIL_CHARACTER_LIMIT = 320;
export const PHONE_NUMBER_MAX_CHARACTERS = 20;

export const PASSWORD_MIN_LIMIT = 8;
export const PASSWORD_MAX_LIMIT = 100;

export const DISCOUNT_COUNT_LIMIT = 8;

export const CONSULTATION_REQUEST_REASON_LIMIT = 1000;

export const CONSULTATION_WAITING_TIME = 20;

export const VERIFICATION_CODE_LENGTH = 14;

export const AUTHENTICATION_FAILED_MESSAGE =
    'Login failed. Invalid email or password, please check your credentials and try again.';

export const COMMON_RESTFUL_ERROR_MESSAGE = 'We could not complete your request, please try again.';

export enum DigitalQuestionAnswerType {
    Accepted = 'Accepted',
    Denied = 'Denied',
    Pending = 'Pending',
}
export const DigitalQuestionAnswerTypeSchema = runtypeFromEnum(DigitalQuestionAnswerType);

export enum ResourceSessionStatus {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN PROGRESS',
}

export type TooltipPosition =
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';

export enum CustomerSupportQuestionType {
    General = 'General',
    HelpWithUsage = 'Help With Usage',
    ReportError = 'Report An Error',
    Other = 'Other',
}
export const CustomerSupportQuestionTypeSchema = runtypeFromEnum(CustomerSupportQuestionType);

export enum PrescriptionCategoryType {
    Consultations = 'Consultations',
    Drugs = 'Drugs',
}

export enum InputFieldType {
    Tel = 'tel',
    Text = 'text',
    Email = 'email',
    Number = 'number',
    Password = 'password',
}
export const InputFieldTypeSchema = runtypeFromEnum(InputFieldType);
