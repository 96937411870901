/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { ResidentialAddressSchema } from '../address';
import { PrescriptionDrugResourceSchema, PrescriptionDrugSchema } from './escript';
import { IdEntitySchema, TimestampSchema } from '..';
import { DigitalConsultationRecordSchema, DigitalConsultationResourceSchema } from '../consultation';
import { maybe } from '../../utils/runtypes';

export const PrescriptionFamilyDatabaseSchema = r
    .Record({
        name: r.String,
        description: r.String,
        reference_id: r.String,
        avatar: maybe(r.String),
        avatar_key: maybe(r.String),
        apply_default_questions: r.Boolean,
    })
    .extend(TimestampSchema.fields);
export type PrescriptionFamilyDatabase = r.Static<typeof PrescriptionFamilyDatabaseSchema>;

export const PrescriptionConsultationOrderSchema = r.Array(r.String);

export const EScriptRequestSchema = r.Record({
    send_electronic_script: r.Boolean,
    prescription: PrescriptionDrugSchema,
    physical_address: ResidentialAddressSchema,
});
export type EScriptRequest = r.Static<typeof EScriptRequestSchema>;

export const GetEScriptMedicationSchema = r.Array(PrescriptionDrugSchema);

export const PrescriptionFamilyClassSchema = r
    .Record({
        name: r.String,
        order: r.Array(r.String),
        default_class: r.Boolean,
        drugs: r.Array(PrescriptionDrugResourceSchema),
        digital_consultations: r.Array(DigitalConsultationResourceSchema),
    })
    .extend(IdEntitySchema.fields);
export type PrescriptionFamilyClass = r.Static<typeof PrescriptionFamilyClassSchema>;

export const PrescriptionRecordSchema = r
    .Record({
        classes: r.Array(PrescriptionFamilyClassSchema),
    })
    .extend(PrescriptionFamilyDatabaseSchema.omit('created_at', 'updated_at').fields);
export type PrescriptionRecord = r.Static<typeof PrescriptionRecordSchema>;

export const InfirmaryPrescriptionRecordsSchema = r.Array(PrescriptionRecordSchema);

export const CreatePrescriptionFamilySchema = r.Record({
    name: r.String,
});
export type CreatePrescriptionFamily = r.Static<typeof CreatePrescriptionFamilySchema>;

export const CreatePrescriptionFamilyClassSchema = r.Record({
    name: r.String,
});
export type CreatePrescriptionFamilyClass = r.Static<typeof CreatePrescriptionFamilyClassSchema>;

export const FamilyConsultationQuestionsStateSchema = r.Record({
    status: r.Boolean,
});
export type FamilyConsultationQuestionsState = r.Static<typeof FamilyConsultationQuestionsStateSchema>;

// Consultation prescription types.
const PrescriptionConsultFamilySchema = PrescriptionFamilyDatabaseSchema.pick(
    'name',
    'avatar',
    'description',
    'apply_default_questions',
).extend(IdEntitySchema.fields);
export type PrescriptionConsultFamilyResource = r.Static<typeof PrescriptionConsultFamilySchema>;

export const ConsultPrescriptionFamiliesSchema = r.Array(PrescriptionConsultFamilySchema);

const PrescriptionConsultFamilyClassSchema = r
    .Record({
        family_id: r.String,
    })
    .extend(PrescriptionFamilyClassSchema.fields);
export type PrescriptionConsultFamilyClass = r.Static<typeof PrescriptionConsultFamilyClassSchema>;

export const PrescriptionConsultFamilyClassesSchema = r.Array(PrescriptionConsultFamilyClassSchema);

const PrescriptionConsultConsultationSchema = r
    .Record({
        class_id: r.String,
        family_id: r.String,
    })
    .extend(DigitalConsultationResourceSchema.fields);
export type PrescriptionConsultConsultation = r.Static<typeof PrescriptionConsultConsultationSchema>;

export const ConsultPrescriptionConsultationsSchema = r.Array(PrescriptionConsultConsultationSchema);

const PrescriptionConsultDrugSchema = r
    .Record({
        class_id: r.String,
        family_id: r.String,
    })
    .extend(PrescriptionDrugResourceSchema.fields);
export type PrescriptionConsultDrug = r.Static<typeof PrescriptionConsultDrugSchema>;

export const ConsultPrescriptionDrugsSchema = r.Array(PrescriptionConsultDrugSchema);

export const MedicineConsultationSchema = r.Record({
    medicine: PrescriptionConsultDrugSchema,
    digital_consultations: r.Array(DigitalConsultationRecordSchema),
});
export type MedicineConsultation = r.Static<typeof MedicineConsultationSchema>;
