/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import {
    CreatedAtTimestampSchema,
    DateSchema,
    EmailAddressSchema,
    IdEntitySchema,
    PersonalNameSchema,
    TimestampSchema,
    URLSchema,
} from '..';
import { maybe } from '../../utils/runtypes';
import { UserAccountTypeSchema } from '../../constants';
import { MedicalProfileDatabaseRecordSchema } from '../medical/profile';

const PatientSchema = r.Record({
    verified: r.Boolean,
    avatar: maybe(URLSchema),
    email: EmailAddressSchema,
    last_name: PersonalNameSchema,
    first_name: PersonalNameSchema,
    completed_onboarding: r.Boolean,
});
export type Patient = r.Static<typeof PatientSchema>;

export const PatientResourceSchema = PatientSchema.extend(IdEntitySchema.fields);
export type PatientResource = r.Static<typeof PatientResourceSchema>;

export const PatientDatabaseRecordSchema = r
    .Record({
        id: r.Number,
        active: r.Boolean,
        password: r.String,
        verified: r.Boolean,
        reference_id: r.String,
        password_salt: r.String,
        recovery_token: r.String,
        avatar_key: maybe(r.String),
        storage_directory: r.String,
        verification_token: r.String,
        completed_onboarding: r.Boolean,
        verification_code: maybe(r.String),
        account_type: UserAccountTypeSchema,
        recovery_token_expiration: maybe(DateSchema),
    })
    .extend(PatientSchema.fields)
    .extend(TimestampSchema.fields);
export type PatientDatabaseRecord = r.Static<typeof PatientDatabaseRecordSchema>;

const PatientSearchRecordSchema = MedicalProfileDatabaseRecordSchema.pick(
    'first_name',
    'last_name',
    'gender',
    'date_of_birth',
    'medicare_card',
    'individual_healthcare_identifier',
)
    .extend(IdEntitySchema.fields)
    .extend(CreatedAtTimestampSchema.fields);
export type PatientSearchRecord = r.Static<typeof PatientSearchRecordSchema>;
