/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { runtypeFromEnum } from '../utils/runtypes';

export const PATHOLOGY_RADIOLOGY_EXPIRY_DAYS = 14;

export enum PopularBloodTests {
    GeneralHealth = 'General Health Blood Test',
    STI = 'STI Blood Test',
    Vegan = 'Vegan Blood Test',
}
export const PopularBloodTestsSchema = runtypeFromEnum(PopularBloodTests);

export enum GeneralBloodTests {
    RecurrentIllness = 'Recurrent Illness? Blood Test',
    AlwaysFatigued = 'Always Fatigued? Blood Test',
    IrritableBowel = 'Irritable Bowel Test',
    FoodIntoleranceBreath = 'Food Intolerance Breath Test',
    CovidPCR = 'Covid PCR',
}
export const GeneralBloodTestsSchema = runtypeFromEnum(GeneralBloodTests);

export enum WomenBloodTests {
    PolycysticOvarianSyndrome = 'Polycystic Ovarian Syndrome (PCOS) Risk Blood Test',
    Fertility = 'Women Fertility Blood Test',
    Pregnancy = 'Pregnancy Blood Test',
    PregnancyPlanning = 'Pregnancy Planning Blood Test',
}
export const WomenBloodTestsSchema = runtypeFromEnum(WomenBloodTests);

export enum MenBloodTests {
    Naturopathy = 'Naturopathy Blood Test',
    ErectileDysfunction = 'Erectile Dysfunction Blood Test',
}
export const MenBloodTestsSchema = runtypeFromEnum(MenBloodTests);

export const PathologyServiceTitleSchema = PopularBloodTestsSchema.Or(GeneralBloodTestsSchema)
    .Or(WomenBloodTestsSchema)
    .Or(MenBloodTestsSchema);
export type PathologyServiceTitle = r.Static<typeof PathologyServiceTitleSchema>;

export interface PathologyServiceEntry {
    price: number;
    urlSlug: string;
    description: string;
    title: PathologyServiceTitle;
}

export type PopularBloodTestsType = {
    [key in PopularBloodTests]: PathologyServiceEntry;
};

export type GeneralBloodTestsType = {
    [key in GeneralBloodTests]: PathologyServiceEntry;
};

export type WomenBloodTestsType = {
    [key in WomenBloodTests]: PathologyServiceEntry;
};

export type MenBloodTestsType = {
    [key in MenBloodTests]: PathologyServiceEntry;
};
