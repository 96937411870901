/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { FetchError, handleError } from '../../tools/fetch';
import { SnackbarTypes } from '../../../../common/types';
import { setSnackbarMessage } from '../snackbar';
import {
    RescheduleSkincareAppointment,
    SkincareAppointment,
    SkincareAppointmentPaymentRequest,
} from '../../../../api/types/skincare';
import { MedicalRequestNote, MedicalRequestNoteResource } from '../../../../api/types/medical/request';
import {
    cancelSkincareAppointment,
    createSkincareAppointmentNote,
    finalizeSkincareAppointment,
    getSkincareAppointments,
    rescheduleSkincareAppointment,
    sendSkincareAppointmentPaymentRequest,
} from '../../fetch/skincare';

export const getSkincareAppointmentsThunk = createAsyncThunk<SkincareAppointment[], void>(
    'skincare/get/appointments',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            const appointments = await getSkincareAppointments();
            return appointments;
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message:
                        response?.reason ??
                        'Something went wrong. We could not fetch skincare appointments. Please try again later.',
                    type: SnackbarTypes.ERROR,
                }),
            );
            return rejectWithValue(response);
        }
    },
);

export const finalizeSkincareAppointmentThunk = createAsyncThunk<void, string>(
    'skincare/finalize/appointment',
    async (appointmentId, { rejectWithValue, dispatch }) => {
        try {
            await finalizeSkincareAppointment(appointmentId);
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message:
                        response?.reason ??
                        'Something went wrong. We could not complete your request to finalize the skincare appointment. Please try again later',
                    type: SnackbarTypes.ERROR,
                }),
            );
            return rejectWithValue(response?.reason);
        }
    },
);

export const rescheduleSkincareAppointmentThunk = createAsyncThunk<SkincareAppointment, RescheduleSkincareAppointment>(
    'skincare/reschedule/appointment',
    async (body, { rejectWithValue, dispatch }) => {
        try {
            const record = await rescheduleSkincareAppointment(body);
            return record;
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message:
                        response?.reason ??
                        'Something went wrong. We could not complete your request to reschedule skincare appointment. Please try again later.',
                    type: SnackbarTypes.ERROR,
                }),
            );
            return rejectWithValue(response?.reason);
        }
    },
);

export const cancelSkincareAppointmentThunk = createAsyncThunk<void, string>(
    'skincare/cancel/appointment',
    async (appointmentId, { rejectWithValue, dispatch }) => {
        try {
            await cancelSkincareAppointment(appointmentId);
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message:
                        response?.reason ??
                        'Something went wrong. We could not complete your request to cancel skincare appointment. Please try again later',
                    type: SnackbarTypes.ERROR,
                }),
            );
            return rejectWithValue(response?.reason);
        }
    },
);

export const createSkincareAppointmentNoteThunk = createAsyncThunk<
    MedicalRequestNoteResource,
    { appointmentId: string; body: MedicalRequestNote }
>('skincare/appointment/create/note', async ({ body, appointmentId }, { rejectWithValue, dispatch }) => {
    try {
        const medicalRequestNote = await createSkincareAppointmentNote(appointmentId, body);
        return medicalRequestNote;
    } catch (error) {
        const response = await handleError(error as FetchError);
        dispatch(
            setSnackbarMessage({
                message:
                    response?.reason ??
                    'Something went wrong. We could not complete your request to create prescription family class. Please try again later',
                type: SnackbarTypes.ERROR,
            }),
        );
        return rejectWithValue(response?.reason);
    }
});

export const sendSkincareAppointmentPaymentThunk = createAsyncThunk<SkincareAppointmentPaymentRequest, string>(
    'skincare/appointment/create/note',
    async (appointmentId, { rejectWithValue, dispatch }) => {
        try {
            const paymentRequest = await sendSkincareAppointmentPaymentRequest(appointmentId);
            return paymentRequest;
        } catch (error) {
            const response = await handleError(error as FetchError);
            dispatch(
                setSnackbarMessage({
                    message:
                        response?.reason ??
                        'Something went wrong. We could not complete your request to send appointment payment request to patient. Please try again later',
                    type: SnackbarTypes.ERROR,
                }),
            );
            return rejectWithValue(response?.reason);
        }
    },
);
