/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { PathologyRequestSchema } from './pathology';
import { IdEntitySchema } from '.';
import { TelehealthRequestSchema } from './telehealth';
import { DigitalQuestionAnswerType } from '../constants';
import { maybe } from '../utils/runtypes';

export const ConsultationRequestSchema = PathologyRequestSchema.Or(TelehealthRequestSchema);
export type ConsultationRequest = r.Static<typeof ConsultationRequestSchema>;

export const ConsultationRequestRecordResourceSchema = r
    .Record({
        request: ConsultationRequestSchema,
        request_token: r.String,
    })
    .extend(IdEntitySchema.fields);
export type ConsultationRequestRecordResource = r.Static<typeof ConsultationRequestRecordResourceSchema>;

export const DigitalConsultationRecordSchema = r.Record({
    question: r.String,
    deny_label: r.String,
    accept_label: r.String,
    html_content: maybe(r.Boolean),
    points: maybe(r.Array(r.String)),
    field_identification: maybe(r.String),
    fail_state: maybe(r.Literal(DigitalQuestionAnswerType.Accepted).Or(r.Literal(DigitalQuestionAnswerType.Denied))),
});
export type DigitalConsultationRecord = r.Static<typeof DigitalConsultationRecordSchema>;

export const DigitalConsultationsCollectionSchema = r.Array(DigitalConsultationRecordSchema);

export const DigitalConsultationResourceSchema = DigitalConsultationRecordSchema.extend(IdEntitySchema.fields);
export type DigitalConsultationResource = r.Static<typeof DigitalConsultationResourceSchema>;

export interface PathologyConsultationDetails {
    notification?: string;
    pathologyTests: string[];
    consultationQuestions: DigitalConsultationRecord[];
}

export const DigitalChoiceConsultationRecordSchema = r.Record({
    question: r.String,
    options: r.Array(r.String),
});
export type DigitalChoiceConsultationRecord = r.Static<typeof DigitalChoiceConsultationRecordSchema>;
