/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { AUTH_MANAGER_PATH } from '../../../api/endpoints';
import { DoctorLoginEndpoint, DoctorVerifySessionEndpoint, LogoutEndpoint } from '../../../api/endpoints/auth';
import { LoginRequest } from '../../../api/types/auth';
import { endpointFetch } from '../tools/fetch';

export function infirmaryDoctorSignIn(body: LoginRequest) {
    return endpointFetch(DoctorLoginEndpoint, { scope: AUTH_MANAGER_PATH, body, redirect: false });
}

export function infirmaryDoctorSessionVerification() {
    return endpointFetch(DoctorVerifySessionEndpoint, { scope: AUTH_MANAGER_PATH });
}

export function infirmaryDoctorLogout() {
    return endpointFetch(LogoutEndpoint, { scope: AUTH_MANAGER_PATH });
}
