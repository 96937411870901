/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { CreatedAtTimestampSchema, IdEntitySchema, URLSchema } from '.';
import { ConsultationUploadCategorySchema, UploadFileTypeSchema } from '../constants';
import { maybe } from '../utils/runtypes';

export const ReferencedFilesResponseSchema = r.Array(r.String);

export const ConsultationFileResourceSchema = r
    .Record({
        document: URLSchema,
        can_patient_view: r.Boolean,
        document_type: UploadFileTypeSchema,
        category: ConsultationUploadCategorySchema,
    })
    .extend(CreatedAtTimestampSchema.fields)
    .extend(IdEntitySchema.fields);
export type ConsultationResourceFile = r.Static<typeof ConsultationFileResourceSchema>;

export const ConsultationFileResourcesSchema = r.Array(ConsultationFileResourceSchema);

export const UploadConsultationFileSchema = r.Record({
    upload_category: ConsultationUploadCategorySchema,
});

export const MedicalRequestFileDatabaseRecordSchema = r
    .Record({
        id: r.Number,
        reference_id: r.String,
        document: URLSchema,
        document_key: r.String,
        doctor_id: maybe(r.Number),
        can_patient_view: r.Boolean,
        document_type: UploadFileTypeSchema,
        category: ConsultationUploadCategorySchema,
    })
    .extend(CreatedAtTimestampSchema.fields);
export type MedicalRequestFileDatabaseRecord = r.Static<typeof MedicalRequestFileDatabaseRecordSchema>;
