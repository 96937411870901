/*
 * Copyright (C) AUSSIE-SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../../utils/runtypes';

export enum AlternativeTreatmentService {
    MedicalCannabis = 'Medical Cannabis',
}
export const AlternativeTreatmentServiceSchema = runtypeFromEnum(AlternativeTreatmentService);
