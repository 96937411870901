/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

export function formatPrice(price: number) {
    return new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    }).format(price);
}

/**
 * Handles request to download stream file using a temporary Anchor tag.
 *
 * @param fileUrl File
 * @param filename
 */
export const triggerDownloadFile = (fileUrl: string, filename: string) => {
    const element = document.createElement('a');
    element.setAttribute('href', fileUrl);
    /* Passing file name is not require for downloading exports from S3 because each file in the metadata has
      "ContentDisposition" that contains the filename */
    if (filename != null) {
        element.setAttribute('download', filename);
    }

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
};
