/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import * as r from 'runtypes';
import { PrescriptionServiceTitleSchema } from '../../constants';
import { DigitalConsultationSchema } from '..';
import { ServiceRequestType } from '../../constants/checkout';

export const PrescriptionRequestSchema = r.Record({
    medicineIdentification: r.String,
    title: PrescriptionServiceTitleSchema,
    medicineFamilyIdentification: r.String,
    consultations: r.Array(DigitalConsultationSchema),
    category: r.Literal(ServiceRequestType.Prescription),
});
export type PrescriptionRequest = r.Static<typeof PrescriptionRequestSchema>;
