/*
 * Copyright (C) AUSSIE SCRIPTS - All Rights Reserved
 *
 * Authors:
 * Aussie Scripts - https://aussiescripts.com.au
 */

import { runtypeFromEnum } from '../../utils/runtypes';

export enum MedicineAdministration {
    Oral = 'Oral',
    Rectal = 'Rectal',
    Vaginal = 'Vaginal',
    OphthalmicEye = 'Ophthalmic (Eye)',
    OticEar = 'Otic (Ear)',
    Inhalation = 'Inhalation',
    Topical = 'Topical',
    Transdermal = 'Transdermal',
}
export const MedicineAdministrationSchema = runtypeFromEnum(MedicineAdministration);

export enum MedicineForm {
    Capsules = 'Capsules',
    Drops = 'Drops',
    Tablet = 'Tablet',
    Liquid = 'Liquid',
    Inhalers = 'Inhalers',
    Topical = 'Topical',
    Suppositories = 'Suppositories',
    NasalSpray = 'Nasal Spray',
    Powders = 'Powders',
    Lozenges = 'Lozenges',
}
export const MedicineFormSchema = runtypeFromEnum(MedicineForm);

export enum MedicineScheduleNumber {
    ScheduleIntentionallyBlank = 'This Schedule Is Intentionally Blank (1)',
    PharmacyMedicine = 'Pharmacy Medicine (2)',
    PharmacistOnlyMedicine = 'Pharmacist Only Medicine (3)',
    PrescriptionOnlyMedicine = 'Prescription Only Medicine OR Prescription Animal Remedy (4)',
    Caution = 'Caution (5)',
    Poison = 'Poison (6)',
    DangerousPoison = 'Dangerous Poison (7)',
    ControlledDrug = 'Controlled Drug (8)',
    ProhibitedSubstances = 'Prohibited Substance (9)',
    SubstancesDangerToHealth = 'Substances of such danger to health as to warrant prohibition of sale, supply and use (10)',
    Schedule4AppendixBDrugs = 'Schedule 4 Appendix B drugs - Special restricted substances (4B)',
    Schedule4AppendixDDrugs = 'Schedule 4 Appendix D drugs - Prescribed restricted substances (4D)',
}
export const MedicineScheduleNumberSchema = runtypeFromEnum(MedicineScheduleNumber);
